
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable, OnDestroy } from "@angular/core";
import { TaskService } from "../task.service";
import { select, Store } from "@ngrx/store";
import { LoadTasks, LoadTasksSuccess, getIsTasksNextPageLoading, getIsTasksIsMoreTasks, getTasksCurrentPageOffset, NextLoadTasks, NextLoadTasksSuccess, getSelectedFilterOption, getIsSearchMode, getSortBy, LoadTasksFail, NextLoadTasksFail, StoreProjectList, StorePriorityList, StoreMemberList, CreateTask, CreateTaskSuccess, CreateTaskFail, RemoveTasksSuccess, RemoveTasksFail, UpdateTask, UpdateTasksSuccess, UpdateTaskFail, UpdateTaskSuccess, StoreAuthUser, getAuthUser, RemoveTasks, AllowLoadMore, CreateTasksSuccess, SetTaskStatisticsInfo, SetIsLoading, getSelectedFilterType, StoreTagList, getTagList, SetSearchStatisticsInfo, SetTagStatisticsInfo, UpdateReminderTasks, getReminderTasks, StoreFolderList, getFolderList, SetListStatisticsInfo, StoreMyTeamUsers, StoreSaveSearches, StoreLocationList, SetLocationStatisticsInfo, getLocationList, getAssignmentTasks, UpdateAssignmentTasks, getMemberList, SetTaskDetailHighlight, getTasks, SetTaskFilterType, SetTaskFilterOption, StoreSettings, getTasksById, getTaskStatisticsInfo, SetSelectAll, ResetSelectedTaskId, SetDetailView, SetEditTaskView } from "../store";
import { Task } from "../models/task";
import { TaskAttachment } from "../models/task-attachment";
import { TaskComment } from "../models/task-comment";
import { TasksRootState } from "../store/reducers/index";
import { TaskUtils } from "../shared/task-utils";
import { ErrorType, SuccessType, DateFilterType, BulkUpdateIssueType } from "../shared/task-enum";
import { TasksConstants } from "../shared/task-constacts";
import { Observable, defer, forkJoin, of } from "rxjs";
import { SuccessService } from "../../common/providers/success-service";
import { ErrorService } from "../../common/providers/error-service";
import { TranslateService } from "@ngx-translate/core";
import { BulkUpdateArgs, AuthUser, User, FileUpload, TaskStatistics, Project, ICompact, MyUser, PendingOperation } from "../models/index";
import { ConfigService } from "../../common/providers/config.service";
import { Broadcaster } from "../../common/providers/broadcaster.service";
import { MessageTranslatorService } from "../services/message-translator-service";
import { LocalStorageService } from "../services/local-storage.service";
import { Subject, BehaviorSubject } from "rxjs";
import { DatabaseService } from "../services/database.service";
import { FilesStorageService } from "../services/files-storage.service";
import { CableService } from "../services/cable.service";
import { CommonUtil, MediaType, mobileonly } from "../../common/utils/common.utils";
import { AppConstants } from "../../common/utils/app-constants";
import { environment } from "../../../environments/environment";
import { IsDatabaseReady, getIsDeviceReady, getFederatedApps, getUserProfile, getAllContacts } from "../../reducers";
import { TaskNotificationType } from "../components/task-notifications";
import * as _ from "lodash";
import { getOnlineStatus, getLoginStatus } from "../../reducers";
import { Logout } from "../../actions/app";
import { List } from "../models/list";
import { VncLibraryService } from "vnc-library";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "../models/location";
import { taskAdapter } from "../store/reducers/tasks.reducers";
import { takeUntil, distinctUntilChanged, debounceTime, take, filter, tap, delay, catchError, switchMap, finalize, takeWhile } from "rxjs/operators";
import { TaskWatchersComponent } from "../components";
import { DomSanitizer } from "@angular/platform-browser";
import { ElectronService } from "../services/electron.service";
import { ContactInfo } from "src/app/shared/models/contact-info.model";
import { AddVNCContacts, BulkLoadVNCContacts } from "src/app/actions/contact.action";

@Injectable()
export class TaskRepository implements OnDestroy {
  FormattedDateForDB:Date;
  private searchParams: any;
  private msgs: any;
  AllTagListAndLocationTask = [];
  private isAlive$ = new Subject<boolean>();
  private pollingRemindersTimeInterval = 1; // min
  private isPollingRemindersStarted: boolean;
  private isPollingUpdateStarted: boolean;
  private isPollingDeleteStarted: boolean;
  private pollingUpdateTimeInterval = 1; // min
  private pollingDeleteTimeInterval = 1; // min
  private isLoggedIn: boolean = false;
  shouldPullOn: boolean = false;
  isOnline: any;
  isCordovaOrElectron = environment.isCordova || environment.isElectron;
  MediaType = MediaType;
  memberList: User[] = [];
  allUserList$: BehaviorSubject<MyUser[]> = new BehaviorSubject(null);
  allTagList$: BehaviorSubject<ICompact[]> = new BehaviorSubject(null);
  authUser: AuthUser;
  static taskStoreList = [
    "AllTaskStore",
    "NewTaskStore",
    "OpenTaskStore",
    "CompletedTaskStore",
    "AssignedTaskStore",
    "WeekTaskStore",
    "TodayTaskStore",
    "TomorrowTaskStore",
    "CreatedByTaskStore",
    "WatchTaskStore",
    "OverdueTaskStore",
  ]
  static statWithStore = [
    { stat: "all", store: "AllTaskStore" },
    { stat: "new", store: "NewTaskStore" },
    { stat: "open", store: "OpenTaskStore" },
    { stat: "completed", store: "CompletedTaskStore" },
    { stat: "assigned", store: "AssignedTaskStore" },
    { stat: "week", store: "WeekTaskStore" },
    { stat: "today", store: "TodayTaskStore" },
    { stat: "tomorrow", store: "TomorrowTaskStore" },
    { stat: "created", store: "CreatedByTaskStore" },
    { stat: "watch", store: "WatchTaskStore" },
    { stat: "overdue", store: "OverdueTaskStore" },
  ]
  constructor(
    private taskService: TaskService,
    private store: Store<TasksRootState>,
    private errorService: ErrorService,
    private successService: SuccessService,
    private translate: TranslateService,
    private configService: ConfigService,
    private broadcaster: Broadcaster,
    private localStorageService: LocalStorageService,
    private databaseService: DatabaseService,
    private messageTranslatorService: MessageTranslatorService,
    private vncLibaryService: VncLibraryService,
    private filesStorageService: FilesStorageService,
    private cableService: CableService,
    private sanitizer: DomSanitizer,
    private electronService: ElectronService,
    private activated: ActivatedRoute,
    private router: Router) {

    this.messageTranslatorService.translatedMessagesd$.subscribe( res => {
      this.msgs = res;
    });

    document.addEventListener("pause", () => {
      window.appInBackground = true;
      localStorage.setItem("lastTimeInBackground", new Date().getTime().toString());
    });

    document.addEventListener("resume", () => {
      window.appInBackground = false;

      if (!environment.isCordova) {
        this.shouldPullOn = true;

        if (!this.isPollingRemindersStarted) {
          this.startPollingReminders();
        }

        if (!this.isPollingUpdateStarted) {
          this.startPollingTaskUpdate();
        }

        if (!this.isPollingDeleteStarted) {
          this.startPollingTaskDelete();
        }
      }
    });

    this.store.select(getOnlineStatus).pipe(takeUntil(this.isAlive$)).subscribe((isOnline) => {
      // console.log('[task.repository] isOnline: ', isOnline);
      this.isOnline = isOnline;
    });

    this.store.select(getLoginStatus).pipe(distinctUntilChanged(), takeUntil(this.isAlive$)).subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
    });

    this.store.select(getMemberList).pipe(takeUntil(this.isAlive$)).subscribe(members => {
      if (members && members.length > 0) {
        this.memberList = members;
      }
    });

    this.store.select(getAuthUser).pipe(takeUntil(this.isAlive$)).subscribe(user => {
      if (user) {
        this.authUser = user;
      }
    });

    if ((window.location !== window.parent.location) && !this.isCordovaOrElectron) {
      this.cableService.getOnMessage().pipe(takeUntil(this.isAlive$)).subscribe(cableMessage => {
        console.log("handling cable message: ", cableMessage);
        window.parent.postMessage({
          type: "VNCTASK_UPDATE"
        }, "*");
      });
    } else {
      if (!CommonUtil.isOnNativeMobileDevice()) {
        this.cableService.getOnMessage().pipe(debounceTime(300), takeUntil(this.isAlive$)).subscribe(cableMessage => {
          this.reloadApp();
        });
      }
    }


  }

  ngOnDestroy(): void {
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
  }

  public syncTasksWithBackend() {
    const offsetZero = 0;

    let lastSyncTaskDate = localStorage.getItem(AppConstants.OFFLINE_TASK_LAST_SYNC_TIMESTAMP);
    // if sync last time exist - load task since it till now.
    // no last sync time - load all tasks

    console.log("[task.repository] syncTasksWithBackend, lastSyncTaskDate: " + lastSyncTaskDate);

    // get latest updated tasks from server
    this.taskService.getTasks(offsetZero, AppConstants.TASKS_SYNC_LIMIT_SERVER, this.getParams(), lastSyncTaskDate)
        .pipe(take(1))
        .subscribe(tasks => {
          // console.log('[task.repository] syncTasksWithBackend tasks from backend: ', tasks);
          if (tasks.length > 0) {
            // save to DB
            this.store.select(IsDatabaseReady).pipe(filter(ready => !!ready), take(1)).subscribe(ready => {
              this.databaseService.saveOrUpdateTasks(tasks).subscribe((res) => {
                // console.log('[task.repository] syncTasksWithBackend, res: ' + res);
                if (res) {
                  localStorage.setItem(AppConstants.OFFLINE_TASK_LAST_SYNC_TIMESTAMP, new Date().toISOString().split(".")[0] + "Z");
                }
              });
            });
          }
          this.processSyncBackendTasksResult();
    });

    if (lastSyncTaskDate !== null) {
      this.taskService.getDeletedTasks(lastSyncTaskDate).pipe(take(1)).subscribe( deletedTasks => {
        if (deletedTasks.length > 0) {
          console.log("[task.repository] syncTasksWithBackend deleted task from backend: ", deletedTasks);
          let selectedIds = [];
          deletedTasks.forEach( deletedTask => {
            selectedIds.push(deletedTask.task_id);
          });
          this.store.select(IsDatabaseReady).pipe(filter(ready => !!ready), take(1)).subscribe(ready => {
            this.databaseService.removeTasks(selectedIds).subscribe(res => {
              this.store.dispatch(new RemoveTasksSuccess({ ids: selectedIds }));
            });
          });
        }
      });
    }
  }

  private startPollingReminders(): void {
    if (window.appInBackground || !this.isOnline) {
      this.isPollingRemindersStarted = false;
      return;
    }
    this.isPollingRemindersStarted =  true;

    console.log("[task.repository] startPollingReminders:::::", this.shouldPullOn);

    if (!!localStorage.getItem("token") && this.shouldPullOn) {
      this.loadReminderTasks(this.pollingRemindersTimeInterval + 1)
      .pipe(tap(tasks => {
        if (tasks && tasks.length > 0) {
          let reminderTasks = [];
          this.store.select(getReminderTasks).pipe(take(1)).subscribe(data => {
            reminderTasks = data;
          });
          tasks = tasks.filter(t => reminderTasks.findIndex(task => task.id === t.id && t.remind_on === task.remind_on) === -1); // To prevent duplicate tasks
          this.store.dispatch(new UpdateReminderTasks(tasks));
        }
      }), delay(this.pollingRemindersTimeInterval * 1000 * 60))
      .subscribe(() => this.startPollingReminders(), () => setTimeout(() => this.startPollingReminders(), this.pollingRemindersTimeInterval * 1000 * 60));
    } else {
      setTimeout(() => this.startPollingReminders(), this.pollingRemindersTimeInterval * 1000 * 60);
    }
  }

  private loadReminderTasks(time: number = 5): Observable<Task[]> {
    // console.log('[task.repository] loadReminderTasks');
    return this.taskService.getReminderTasks(time).pipe(take(1));
  }

  private startPollingTaskUpdate(): void {
    if (window.appInBackground || !this.isOnline) {
      this.isPollingUpdateStarted = false;
      return;
    }
    this.isPollingUpdateStarted =  true;

    console.log("[task.repository] startPollingTaskUpdate:::::", this.shouldPullOn);

    if (!!localStorage.getItem("token") && this.shouldPullOn) {
      this.loadUpdateTasks()
      .pipe(tap(tasks => {
        if (tasks && tasks.length > 0) {
          // Show notifications
          if (tasks && tasks.length > 0) {
            let TasksCreated = [];
            let TasksWatcher = [];
            if ( this.authUser && this.authUser.no_self_notified === "true") {
              tasks = tasks.filter( task => {
                if (task.journals && task.journals.length > 0) {
                  let comment = task.journals.find( comment => comment.created_on === task.updated_on);
                  if (comment) {
                    if (comment.user.id !== this.authUser.id) {
                      if (comment.details && comment.details.length > 0) {
                        let detail = comment.details.find( detail => detail.name === "assigned_to_id");
                        let watcher = comment.details.find( detail => detail.name === "watcher");
                        if (detail && parseInt(detail.new_value) === this.authUser.id) {
                          TasksCreated.push(task);
                        } else if ( watcher && parseInt(watcher.new_value) === this.authUser.id) {
                          TasksWatcher.push(task);
                        } else {
                          return task;
                        }
                      } else {
                        return task;
                      }
                    }
                  }
                } else {
                  if (task.updated_on  === task.created_on ) {
                    if (task.assigned_to && this.authUser && task.assigned_to.id === this.authUser.id) {
                      TasksCreated.push(task);
                    } else if (task.author && task.author.id !== this.authUser.id) {
                      if (task.watchers && task.watchers.length > 0) {
                        let watcher = task.watchers.find( watcher => watcher.id === this.authUser.id );
                        if (watcher) {
                          TasksWatcher.push(task);
                        } else {
                          return task;
                        }
                      } else {
                        return task;
                      }
                    }
                  } else {
                    return task;
                  }
                }
              });
            } else {
              tasks = tasks.filter( task => {
                if (task.updated_on === task.created_on) {
                  if (task.assigned_to && this.authUser && task.assigned_to.id === this.authUser.id) {
                    TasksCreated.push(task);
                  } else if (task.author && task.author.id !== this.authUser.id) {
                    if (task.watchers && task.watchers.length > 0) {
                      let watcher = task.watchers.find( watcher => watcher.id === this.authUser.id );
                      if (watcher) {
                        TasksWatcher.push(task);
                      } else {
                        return task;
                      }
                    } else {
                      return task;
                    }
                  }
                } else {
                  if (task.journals && task.journals.length > 0) {
                    let comment = task.journals.find( comment => comment.created_on === task.updated_on);
                    if (comment.details && comment.details.length > 0) {
                      let detail = comment.details.find( detail => detail.name === "assigned_to_id");
                      let watcher = comment.details.find( detail => detail.name === "watcher");
                      if (detail && parseInt(detail.new_value) === this.authUser.id) {
                        TasksCreated.push(task);
                      } else if ( watcher && parseInt(watcher.new_value) === this.authUser.id) {
                        TasksWatcher.push(task);
                      } else {
                        return task;
                      }
                    } else {
                      return task;
                    }
                  }
                }
              });
            }
            if (tasks.length > 1) {
              tasks.forEach( task => {
                this.store.select(state => getTasksById(state, task.id)).pipe(take(1)).subscribe( oldTask => {
                  if (oldTask) {
                    this.updateTaskOnDBIfNeededAndFireResult(oldTask, new Task(task));
                  }
               });
              });
            } else if (tasks.length === 1 ) {
              this.store.select(state => getTasksById(state, tasks[0].id)).pipe(take(1)).subscribe( oldTask => {
                 if (oldTask) {
                   this.updateTaskOnDBIfNeededAndFireResult(oldTask, new Task(tasks[0]));
                 }
              });
            }

            if (TasksCreated.length > 1) {
              TasksCreated.forEach(task => {
                this.createTaskOnDBIfNeededAndFireResult(new Task(task));
              });

              this.getTasksStats();
              this.getFolderListWithCounters();
              this.getTagsListWithCounters();
              this.getLocationsWithCounters();

            } else if ((TasksCreated.length === 1)) {
              this.createTaskOnDBIfNeededAndFireResult(new Task(TasksCreated[0]));

              this.getTasksStats();
              this.getFolderListWithCounters();
              this.getTagsListWithCounters();
              this.getLocationsWithCounters();
            }

            if (TasksWatcher.length === 1) {
              this.store.select(state => getTasksById(state, TasksWatcher[0].id)).pipe(take(1)).subscribe( oldTask => {
                if (oldTask) {
                  this.updateTaskOnDBIfNeededAndFireResult(oldTask, new Task(TasksWatcher[0]));
                }
             });
            }
          }
        }
      }), delay(this.pollingUpdateTimeInterval * 1000 * 60))
      .subscribe(() => this.startPollingTaskUpdate(), () => setTimeout(() => this.startPollingTaskUpdate(), this.pollingUpdateTimeInterval * 1000 * 60));
    } else {
      setTimeout(() => this.startPollingTaskUpdate(), this.pollingUpdateTimeInterval * 1000 * 60);
    }
  }

  private startPollingTaskDelete(): void {
    if (window.appInBackground || !this.isOnline) {
      this.isPollingDeleteStarted = false;
      return;
    }
    this.isPollingDeleteStarted =  true;
    if (!!localStorage.getItem("token") && this.shouldPullOn) {
      this.loadDeleteTasks()
      .pipe(tap (deletedTasks => {
        if (deletedTasks.length > 0) {
          console.log("[task.repository] startPollingTaskDelete deleted tasks : ", deletedTasks);
          let selectedIds = [];
          deletedTasks.forEach( deletedTask => {
            selectedIds.push(deletedTask.task_id);
          });
          this.deleteTaskOnDBIfNeededAndFireResult(selectedIds);
        }
      }), delay(this.pollingDeleteTimeInterval * 1000 * 60))
      .subscribe(() => this.startPollingTaskDelete(), () => setTimeout(() => this.startPollingTaskDelete(), this.pollingDeleteTimeInterval * 1000 * 60));
    } else {
      setTimeout(() => this.startPollingTaskDelete(), this.pollingDeleteTimeInterval * 1000 * 60);
    }
  }

  public updateTaskOnDBIfNeededAndFireResult(oldTask: Task, newTask: Task) {
    let removedTagsIds: number[] = [];
    oldTask?.tags?.forEach(oldTag => {
      let tagRemoved = true;
      for (let newTag of newTask?.tags) {
        if (oldTag.id === newTag.id) {
          tagRemoved = false;
          break;
        }
      }
      if (tagRemoved) {
        removedTagsIds.push(oldTag.id);
      }
    });

    if (CommonUtil.isSQLSupported()) {
      this.store.select(IsDatabaseReady).pipe(filter(ready => !!ready), take(1)).subscribe(ready => {
        this.databaseService.updateTask(newTask, removedTagsIds).subscribe((dbRes) => {
          this.updateTaskIntoStore(oldTask, newTask);
          this.broadcaster.broadcast("updateTaskDetail", newTask.id);
        });
      });
    } else {
      this.updateTaskIntoStore(oldTask, newTask);
      this.broadcaster.broadcast("updateTaskDetail", newTask.id);
    }
  }

  private createTaskOnDBIfNeededAndFireResult(task: Task) {
    // add on DB
    if (CommonUtil.isSQLSupported()) {
      this.store.select(IsDatabaseReady).pipe(filter(ready => !!ready), take(1)).subscribe(ready => {
        this.databaseService.addNewTask(task).subscribe(res => {
          this.addTaskInStore(task);
        });
      });
    } else {
      this.addTaskInStore(task);
    }
  }

  private deleteTaskOnDBIfNeededAndFireResult(selectedIds) {
    if (CommonUtil.isSQLSupported()) {
      this.store.select(IsDatabaseReady).pipe(filter(ready => !!ready), take(1)).subscribe(ready => {
        this.databaseService.removeTasks(selectedIds).subscribe(res => {
          this.store.dispatch(new RemoveTasksSuccess({ ids: selectedIds }));
          this.broadcaster.broadcast("deleteTasks");
        });
      });
    } else {
      this.store.dispatch(new RemoveTasksSuccess({ ids: selectedIds }));
      this.broadcaster.broadcast("deleteTasks");
    }
  }

  public stopPullingNotification() {
    if (!environment.isCordova) {
      this.shouldPullOn = false;
    }
  }

  public startPullingNotification() {
    if (!environment.isCordova) {
      this.shouldPullOn = true;
      if (!this.isPollingRemindersStarted) {
        this.startPollingReminders();
      }

      if (!this.isPollingUpdateStarted) {
        this.startPollingTaskUpdate();
      }

      if (!this.isPollingDeleteStarted) {
        this.startPollingTaskDelete();
      }
    }
  }

  private loadUpdateTasks(): Observable<any[]> {
    // console.log('[task.repository] loadReminderTasks');
    let time: Date = new Date();
    time.setMinutes( time.getMinutes() - this.pollingUpdateTimeInterval );
    return this.taskService.getUpdatedTasks(time).pipe(take(1));
  }

  private loadDeleteTasks(): Observable<any[]> {
    // console.log('[task.repository] loadReminderTasks');
    let time: Date = new Date();
    time.setMinutes( time.getMinutes() - this.pollingUpdateTimeInterval );
    let deleteAfter = time.toISOString().split(".")[0] + "Z";
    return this.taskService.getDeletedTasks(deleteAfter).pipe(take(1));
  }

  public initRepo() {

    // sync with server every time we have an internet connection
    this.store.select(getOnlineStatus).pipe(distinctUntilChanged(), takeUntil(this.isAlive$)).subscribe((isOnline) => {
      if (this.isLoggedIn) {
        this.syncAuthUser();

        if (isOnline) {
          // console.log('[task.repository] initRepo online');
          if (CommonUtil.isSQLSupported()) {
            this.store.select(IsDatabaseReady).pipe(filter(ready => !!ready), take(1)).subscribe(ready => {
              this.store.select(getAuthUser).pipe(filter(aUser => !!aUser), take(1)).subscribe(aUser => {
                this.syncTasksWithBackend();
              });
            });
          }

          this.getAllUsers();
          this.getSettings();

          if (!environment.isCordova) {
            this.shouldPullOn = true;

            if (!this.isPollingRemindersStarted) {
              this.startPollingReminders();
            }

            if (!this.isPollingUpdateStarted) {
              this.startPollingTaskUpdate();
            }

            if (!this.isPollingDeleteStarted) {
              this.startPollingTaskDelete();
            }
          }

          this.store.select(IsDatabaseReady).pipe(filter(ready => !!ready), take(1)).subscribe(ready => {
            this.processPendingOperations();
          });
          this.store.dispatch(new AllowLoadMore());
        }

        this.syncProjectsListAndMembers();
        this.getTasksStats();
        this.getPriorityList();
        this.getFolderListWithCounters();
        this.getTagsListWithCounters();
        this.getSaveSearches();
        this.getLocationsWithCounters();
      }
    });
  }
  addAllTaskToIndexedDb() {
    const AllStatusRoutes = [
      {
        routingType : "status",
        routingOption: "all"
      },
      {
        routingType: "status",
        routingOption: "open"
      },
      {
        routingType: "status",
        routingOption: "completed"
      },
      {
        routingType: "status",
        routingOption: "new"
      },
      {
        routingType: "status",
        routingOption: "assigned"
      },
      {
        routingType: "status",
        routingOption: "created"
      },
      {
        routingType: "status",
        routingOption: "tomorrow"
      },
      {
        routingType: "status",
        routingOption: "today"
      },
      {
        routingType: "status",
        routingOption: "week"
      },
      {
        routingType: "status",
        routingOption: "watch"
      },
      {
        routingType: "status",
        routingOption: "overdue"
      }
    ]
    for (let taskRoute of AllStatusRoutes) {
      this.getTasks(taskRoute);
    }
  }

  getContactInfo(task: any[]) {

    let currentUser;
    try {
      currentUser = JSON.parse(localStorage.getItem("profileUser"));
    } catch (error) {

    }
    const emailList = [...new Set(task?.map(item => item?.assigned_to?.jid))];
    emailList.forEach((item: string) => {
      if (item == currentUser?.email) {
        this.taskService.getLoggedInUserContactInfo(currentUser.email).subscribe(data => {
          this.databaseService.addContactInfo(data);
          CommonUtil.getBase6ImageFromUrl(data?.avatar).subscribe(baseImg => {
            const avatar = {
              id: data.user_id,
              data: baseImg
            }
            this.databaseService.addAvatar(avatar);
          });
        })
      }
      else {
        this.taskService.getContactInfo(item).subscribe(res => {
          this.databaseService.addContactInfo(res);
          CommonUtil.getBase6ImageFromUrl(res?.avatar).subscribe(baseImg => {
            const avatar = {
              id: res.user_id,
              data: baseImg
            }
            this.databaseService.addAvatar(avatar);
          })
        });
      }
    });
  }

  addTagListAndLocationTaskToIndexedDb() {
    this.AllTagListAndLocationTask = this.AllTagListAndLocationTask.reduce((acc, curr) => acc.concat(curr), []);
    if (this.AllTagListAndLocationTask?.length) {
      for (let taskRoute of this.AllTagListAndLocationTask) {
        this.getTasks(taskRoute);
      }
    }
  }

  // Initial tasks load (on start app/search/..)
  public getTasks(defaultTaskParams?) {
    let params = this.getParams(defaultTaskParams);
    const offsetZero = 0;
    let isSearchMode;
    this.store.select(getIsSearchMode).pipe(take(1)).subscribe(o => isSearchMode = o);
    if (params) {
      this.store.dispatch(new LoadTasks());
      // load tasks from SQLite for the mobile client
      if (CommonUtil.isSQLSupported()) {
        if (isSearchMode && this.isOnline) {
          this.taskService.getTasks(offsetZero, AppConstants.TASKS_LIMIT, params).pipe(take(1))
          .subscribe(tasks => {
            this.processGetTasksResult(tasks);
          }, err => {
            this.store.dispatch(new LoadTasksFail());
            this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
          });
        } else {
          this.databaseService
          .getTasks(offsetZero, AppConstants.TASKS_LIMIT, params)
          .subscribe(tasks => {
              if (this.isOnline && tasks.length < AppConstants.TASKS_LIMIT) {
                this.processGetTasksResult(tasks);
                this.maybeLoadRemainingTasksFromServer(params);
              } else {
                this.processGetTasksResult(tasks);
              }
          }, err => {
            this.store.dispatch(new LoadTasksFail());
            this.vncLibaryService.openSnackBar(err, "","", "", 3000, "bottom", "left").subscribe(res => {});
          });
        }
      // load tasks from Server
      } else {
        this.taskService.getTasks(offsetZero, AppConstants.TASKS_LIMIT, params).pipe(take(1))
          .subscribe(tasks => {
            if (defaultTaskParams) this.processGetAllTasksResultToDataBase(tasks, defaultTaskParams)
            else this.processGetTasksResult(tasks);
          }, err => {
            this.store.dispatch(new LoadTasksFail());
            this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
          }
        );
      }
    }
  }

  // method is used when load tasks from DB and there is < AppConstants.TASKS_LIMIT,
  // but more tasks exist on backend side
  private maybeLoadRemainingTasksFromServer(params){
    console.log("[task.repository] maybeLoadRemainingTasksFromServer");
    this.store.dispatch(new LoadTasks());
    const offsetZero = 0;

    this.taskService.getTasks(offsetZero, AppConstants.TASKS_LIMIT, params).pipe(take(1))
      .subscribe(tasks => {
        if (tasks.length > 0) {
          // save to DB
          this.databaseService.saveOrUpdateTasks(tasks).subscribe((res) => {
            console.log("[task.repository] maybeLoadRemainingTasksFromServer. tasks count:", tasks.length);
            this.processGetTasksResult(tasks);
          });
        } else {
          this.processGetTasksResult(tasks);
        }
      }, err => {
        this.store.dispatch(new LoadTasksFail());
        this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
      }
    );
  }

  private processSyncBackendTasksResult(){
    this.getTasks();
  }

  private processGetTasksResult(tasks: Task[]) {
    console.log("[task.repository] processGetTasksResult. count", tasks.length);

    let offset = tasks.length;
    let isMoreTasks = false;
    if (tasks.length >= AppConstants.TASKS_LIMIT) {
      isMoreTasks = true;
    } else {
      offset = 0;
      isMoreTasks = false;
    }

    if (tasks.length > 0) {
      this.poppulateAssigneeAvatar(tasks).subscribe((success) => {
        if (success) {
          this.loadTaskToDb(tasks).subscribe(taskFromDB => {
            this.store.dispatch(new LoadTasksSuccess({ currOffset: offset, isMoreTasks: isMoreTasks, task: taskFromDB }));
          })
        }
      });
    } else {
      this.store.dispatch(new LoadTasksSuccess({ currOffset: offset, isMoreTasks: isMoreTasks, task: tasks }));
    }
  }
  private processGetAllTasksResultToDataBase(tasks: Task[], defaultFilter?) {
    let offset = tasks.length;
    let isMoreTasks = false;
    if (tasks.length >= AppConstants.TASKS_LIMIT) {
      isMoreTasks = true;
    } else {
      offset = 0;
      isMoreTasks = false;
    }

    if (tasks.length > 0) {
      this.poppulateAssigneeAvatar(tasks).subscribe((success) => {
        if (success) {
          this.loadTaskToDb(tasks, defaultFilter).subscribe(taskFromDB => {
            if (defaultFilter.routingOption === "overdue") {
              this.addTagListAndLocationTaskToIndexedDb();
              setTimeout(() => {
                this.store.dispatch(new SetIsLoading(false));
              }, 500);
            }
          })
        }
      });
    }
    else {
      this.loadTaskToDb(tasks, defaultFilter).subscribe(taskFromDB => {
        setTimeout(() => {
          this.store.dispatch(new SetIsLoading(false));
        }, 500);
      })
    }
  }

  private getParams(defaultTaskParams?) {
    let params;

    let routingType;
    let routingOption;
    let isSearchMode;
    let sortBy;
    this.store.select(getSelectedFilterType).pipe(take(1)).subscribe(o => routingType = o);
    this.store.select(getSelectedFilterOption).pipe(take(1)).subscribe(o => routingOption = o);
    this.store.select(getIsSearchMode).pipe(take(1)).subscribe(o => isSearchMode = o);
    this.store.select(getSortBy).pipe(take(1)).subscribe(o => sortBy = o);

    if (defaultTaskParams) {
      routingType = defaultTaskParams.routingType,
      routingOption = defaultTaskParams.routingOption
    }

    if (isSearchMode) {
      params = this.getSearchQuery();
    } else {
      if (routingType === TasksConstants.ROUTE_TYPE_STATUS) {
        params = TaskUtils.getParamsForFilters(routingOption, sortBy);
      } else if (routingType === TasksConstants.ROUTE_TYPE_TAG){
        this.store.select(getTagList).pipe(take(1)).subscribe(tags => {
          if (tags.length > 0) {
            let tag = tags.find( tag => tag.name === routingOption);
            if (tag) {
              params = TaskUtils.getParamsForTags(tag.id, sortBy);
            }
          }
        });
      } else if (routingType === TasksConstants.ROUTE_TYPE_LIST) {
        this.store.select(getFolderList).pipe(take(1)).subscribe(lists => {
          if (lists.length > 0) {
            let list = lists.find( list => list.name === routingOption);
            if (list) {
              params = TaskUtils.getParamsForLists(list.id, sortBy);
            }
          }
        });
      } else {
        this.store.select(getLocationList).pipe(take(1)).subscribe(locations => {
          if (locations.length > 0) {
            let location = locations.find( location => location.name === routingOption);
            if (location) {
              params = TaskUtils.getParamsForLocations(location.id, sortBy);
            }
          }
        });
      }
    }
    return params;
  }

  public getSearchQuery() {
    let query: any = Object.assign({}, this.searchParams, {});

    // author or assigned
    let key = "author_or_assigned";
    query[`op[${key}]`] = "=";

    if (!query["f[]"]) query["f[]"] = [];
    query["f[]"].push(key);

    if (!query[`v[${key}][]`]) query[`v[${key}][]`] = "me";

    key = "project_id";
    if (!query["op[project_id]"]) {
      if (!query["f[]"]) query["f[]"] = [];
      query["f[]"].push(key);
      query[`op[${key}]`] = "=";
      if (!query[`v[${key}][]`]) query[`v[${key}][]`] = "mine";
    }

    let sortBy;
    this.store.select(getSortBy).pipe(take(1)).subscribe(o => sortBy = o);

    let order = sortBy === TasksConstants.PRIORITY_SORTING_KEY ? TasksConstants.DESC : TasksConstants.ASC;
    query["sort"] = sortBy + ":" + order;

    return Object.keys(query).length === 1 ? {} : query;
  }

  public loadMoreTasks() {
    let nextPageLoading: boolean;
    this.store.select(getIsTasksNextPageLoading).pipe(take(1)).subscribe(o => nextPageLoading = o);

    if (nextPageLoading) {
      return;
    }
    let currentOffset = 0;
    let isMoreTasks = false;
    this.store.select(getTasksCurrentPageOffset).pipe(take(1)).subscribe(o => currentOffset = o);
    this.store.select(getIsTasksIsMoreTasks).pipe(take(1)).subscribe(l => isMoreTasks = l);

    console.log("[task.repository] loadMoreTasks. isMoreTasks: " + isMoreTasks + ", currentOffset: " + currentOffset);

    if (!isMoreTasks) {
      return;
    }

          // of all tasks are loaded in offline -> let a user try to load more when online

    const params = this.getParams();
    if (params) {
      console.log("[task.repository] loadMoreTasks start loading");
      this.store.dispatch(new NextLoadTasks());

      // load more tasks from SQLite for the mobile client
      if (CommonUtil.isSQLSupported()) {
        this.databaseService.getTasks(currentOffset, AppConstants.TASKS_LIMIT, params)
          .subscribe(tasks => {
          if (this.isOnline && tasks.length < AppConstants.TASKS_LIMIT) {
            this.maybeLoadRemainingLoadMoreTasksFromServer(params, currentOffset);
          } else {
            this.processLoadMoreTasksResult(tasks, currentOffset);
          }
        }, err => {
          this.store.dispatch(new LoadTasksFail());
          this.vncLibaryService.openSnackBar(err, "","", "", 3000, "bottom", "left").subscribe(res => {});
        });

      // load more tasks from Server
      } else {
        if (this.isOnline) {
          this.taskService.getTasks(currentOffset, AppConstants.TASKS_LIMIT, params).pipe(take(1))
            .subscribe(tasks => {
              this.processLoadMoreTasksResult(tasks, currentOffset);
            }, err => {
              this.store.dispatch(new NextLoadTasksFail());
              this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
            });
        }
        else {
          let taskFilter = "";
          this.store.select(getSelectedFilterOption).subscribe(res => {
            taskFilter = res;
          })
          this.getMoreDataFromDatabase(taskFilter, currentOffset);
        }
      }
    }
  }

  // method is used when load more tasks from DB and there is < AppConstants.TASKS_LIMIT,
  // but more tasks exist on backend side
  private maybeLoadRemainingLoadMoreTasksFromServer(params, currentOffset: number){
    console.log("[task.repository] maybeLoadRemainingLoadMoreTasksFromServer. loadedFromDBTasks");

    this.taskService.getTasks(currentOffset, AppConstants.TASKS_LIMIT, params).pipe(take(1))
      .subscribe(tasks => {
        if (tasks.length > 0) {
          // save to DB
          this.databaseService.saveOrUpdateTasks(tasks).subscribe((res) => {
            console.log("[task.repository] maybeLoadRemainingLoadMoreTasksFromServer. mergedTasks count:", tasks.length);
            this.processLoadMoreTasksResult(tasks, currentOffset);
          });
        } else {
          this.processLoadMoreTasksResult(tasks, currentOffset);
        }
      }, err => {
        this.store.dispatch(new LoadTasksFail());
        this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
      }
    );
  }

  private processLoadMoreTasksResult(tasks: Task[], offset){
    let isMoreTasks;
    let newOffset = offset + tasks.length;
    if (tasks.length >= AppConstants.TASKS_LIMIT) {
      isMoreTasks = true;
    } else {
      newOffset = 0;
      isMoreTasks = false;
    }

    console.log("[task.repository] processLoadMoreTasksResult. isMoreTasks: " + isMoreTasks + ". newOffset: " + newOffset + ". tasks count: " + tasks.length);

    this.poppulateAssigneeAvatar(tasks).subscribe((success) => {
      if (success) {
        this.loadTaskToDb(tasks).subscribe(taskFromDB => {
          // this.store.dispatch(new LoadTasksSuccess({ currOffset: offset, isMoreTasks: isMoreTasks, task: taskFromDB }));
          this.store.dispatch(new NextLoadTasksSuccess({currOffset: newOffset, isMoreTasks: isMoreTasks, task: tasks as Task[]}));
        })
      }
    });
  }

  public poppulateAssigneeAvatar(tasks: Task[]): Observable<boolean> {
    console.log("[TaskRepository][poppulateAssigneeAvatar], tasks: ", tasks.length);

    const response = new BehaviorSubject(null);

    if (!tasks || tasks.length === 0) {
      response.next(true);
    } else {
      if (CommonUtil.isSQLSupported()) {
        this.performAvatarsAssignement(tasks, false, "userAvatar", 2).subscribe(done => {
          console.log("[TaskRepository][poppulateAssigneeAvatar] DONE");
          if (environment.isElectron) {
            tasks.forEach(task => {
              if (task.userAvatar) {
                if (CommonUtil.isFileSystemUrl(task.userAvatar.toString())) {
                  task.userAvatar = this.sanitizer.bypassSecurityTrustUrl(task.userAvatar);
                }
              }
            });
          }
          response.next(true);
        });
      } else {
        const currentTimeStamp = new Date().getTime();
        tasks.map(task => {
          if (task.assigned_to && this.authUser && task.assigned_to.id === this.authUser.id) {
            task.userAvatar = this.buildAvatarUrl(this.authUser.jid, currentTimeStamp);
          }
        });
        console.log("[TaskRepository][poppulateAssigneeAvatar] DONE");
        response.next(true);
      }
    }

    return response.asObservable().pipe(take(2));
  }

  public poppulateCommentersAvatar(task: Task): Observable<boolean> {
    const response = new BehaviorSubject(null);

    if (!task.comments || task.comments.length === 0) {
      console.log("[TaskRepository][poppulateCommentersAvatar] DONE with zero");
      response.next(true);
    } else {
      if (CommonUtil.isSQLSupported()) {
        this.performAvatarsAssignement(task.comments, false, "userAvatar", 3).subscribe(done => {
          console.log("[TaskRepository][poppulateCommentersAvatar] DONE");
          if (environment.isElectron) {
            task.comments.forEach(comment => {
              if (comment.userAvatar) {
                if (CommonUtil.isFileSystemUrl(comment.userAvatar.toString())) {
                  comment.userAvatar = this.sanitizer.bypassSecurityTrustUrl(comment.userAvatar);
                }
              }
            });
          }
          response.next(true);
        });
      } else {
        const currentTimeStamp = new Date().getTime();
        task.comments.map(comment => {
          if (comment.user && this.authUser && comment.user.id === this.authUser.id) {
            comment.userAvatar = this.buildAvatarUrl(this.authUser.jid, currentTimeStamp);
          }
        });
        console.log("[TaskRepository][poppulateCommentersAvatar] DONE");
        response.next(true);
      }
    }

    return response.asObservable().pipe(take(2));
  }

  public poppulateWatchersAvatar(task: Task): Observable<boolean> {
    const response = new BehaviorSubject(null);

    if (!task.watchers || task.watchers.length === 0) {
      console.log("[TaskRepository][poppulateWatchersAvatar] DONE with zero");
      response.next(true);
    } else {
      if (CommonUtil.isSQLSupported()) {
        this.performAvatarsAssignement(task.watchers, false, "avatar", 1).subscribe(done => {
          console.log("[TaskRepository][poppulateWatchersAvatar] DONE");
          if (environment.isElectron) {
            task.watchers.forEach(watcher => {
              if (watcher.avatar) {
                if (CommonUtil.isFileSystemUrl(watcher.avatar.toString())) {
                  watcher.avatar = this.sanitizer.bypassSecurityTrustUrl(watcher.avatar);
                }
              }
            });
          }
          response.next(true);
        });
      } else {
        const currentTimeStamp = new Date().getTime();
        task.watchers.map(watcher => {
          if (watcher && this.authUser && watcher.id === this.authUser.id) {
            watcher.avatar = this.buildAvatarUrl(this.authUser.jid, currentTimeStamp);
          }
        });
        response.next(true);
      }
    }

    return response.asObservable().pipe(take(2));
  }

  public poppulateAllUserAvatar(user: AuthUser) {
    const currentTimeStamp = new Date().getTime();
    this.allUserList$.pipe(take(1)).subscribe(allUsers => {
      allUsers.forEach(user => {
          if (user && this.authUser && user.id === this.authUser.id) {
            user.avatar = this.buildAvatarUrl(this.authUser.email, currentTimeStamp);
          }
      });
      this.allUserList$.next(allUsers);
    });
  }

  public poppulateAuthUserAvatar(user: AuthUser, forceResync: boolean): Observable<boolean> {
    console.log("[TaskRepository][poppulateAuthUserAvatar] user: ", user);

    const response = new BehaviorSubject(null);

    if (CommonUtil.isSQLSupported()) {
      this.performAvatarsAssignement([user], forceResync, "userAvatar", 1).subscribe(done => {
        console.log("[TaskRepository][poppulateAuthUserAvatar] DONE");
        response.next(true);
      });
    } else {
      if (forceResync) {
        let timestamp = new Date().getTime();
        user.userAvatar = this.buildAvatarUrl(user.jid, timestamp);
      }
      response.next(true);
    }

    return response.asObservable().pipe(take(2));
  }

  public poppulateMembersAvatar(members: Array<User>, forceResync: boolean): Observable<boolean> {
    console.log("[TaskRepository][poppulateMembersAvatar]", members.length);

    const response = new BehaviorSubject(null);

    if (!members || members.length === 0) {
      console.log("[TaskRepository][poppulateMembersAvatar], DONE with zero");
      response.next(true);
    } else {
      if (CommonUtil.isSQLSupported()) {
        this.performAvatarsAssignement(members, forceResync, "avatar", 1).subscribe(done => {
          console.log("[TaskRepository][poppulateMembersAvatar] DONE");
          response.next(true);
        });
      } else {
        if (forceResync) {
          let timestamp = new Date().getTime();
          members.forEach(member => {
            if ( member && this.authUser && member.id === this.authUser.id ) {
              member.avatar = this.buildAvatarUrl(this.authUser.jid, timestamp);
            }
          });
        }
        response.next(true);
      }
    }

    return response.asObservable().pipe(take(2));
  }

  private performAvatarsAssignement(objects: any[], forceResync: boolean, avatarPropertyName: string, type: number): Observable<boolean> {
    console.log("[TaskRepository][performAvatarsAssignement]", objects.length);

    const response = new Subject<boolean>();

    const currentTimeStamp = new Date().getTime();

    const serverAvatarsUrls = [];
    const uniqueUsersIds = [];
    const userObjectsIndexes = {};

    objects.forEach((obj, idx) => {
      const uid = this.avatarObjectId(obj, type);

      if (uid > 0) {
        // collect unique users
        if (uniqueUsersIds.indexOf(uid) === -1) {
          uniqueUsersIds.push(uid);

          // collect server avatar urls
          const serverUrlAvatar = this.avatarObjectUrl(obj, type, forceResync, currentTimeStamp);
          serverAvatarsUrls.push(serverUrlAvatar);
        }

        // group users and they objects
        const grouppedObjects = userObjectsIndexes[uid];
        if (grouppedObjects) {
          grouppedObjects.push(idx);
        } else {
          userObjectsIndexes[uid] = [idx];
        }
      }
    });

    this.store.select(IsDatabaseReady).pipe(filter(ready => !!ready), take(1)).subscribe(ready => {
      this.databaseService.assignAvatars(uniqueUsersIds, serverAvatarsUrls, forceResync, avatarPropertyName).subscribe(resultsHash => {
        // result <userId: avatarUrl>
        uniqueUsersIds.forEach(uid => {
          const avatarLocalUrl = resultsHash[uid];

          const objectsIndexes = userObjectsIndexes[uid];
          objectsIndexes.forEach(idx => {
            objects[idx][avatarPropertyName] = avatarLocalUrl;
          });
        });

        console.log("[TaskRepository][performAvatarsAssignement] DONE");
        response.next(true);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  private avatarObjectId(objectToAssignAvatar: any, type: number): any {
    // user
    if (type === 1){
      return objectToAssignAvatar.id;
    // task
    } else  if (type === 2){
      return objectToAssignAvatar.assigned_to ? objectToAssignAvatar.assigned_to.id : 0;
    // comment
    } else  if (type === 3){
      return objectToAssignAvatar.user.id;
    }
  }

  private avatarObjectUrl(objectToAssignAvatar: any, type: number, forceResync: boolean, currentTimeStamp: number): any {
    // user
    if (type === 1){
      if (forceResync && this.authUser && objectToAssignAvatar.id === this.authUser.id) {
        return this.buildAvatarUrl(this.authUser.jid, currentTimeStamp);
      }
      if (this.authUser && objectToAssignAvatar.id === this.authUser.id) {
        return this.authUser.userAvatar;
      }
      if (objectToAssignAvatar.avatar) {
        return objectToAssignAvatar.avatar;
      } else if (objectToAssignAvatar.userAvatar) {
        return objectToAssignAvatar.userAvatar;
      } else {
        return null;
      }
    // task
    } else  if (type === 2){
      if (forceResync && this.authUser && objectToAssignAvatar.assigned_to && objectToAssignAvatar.assigned_to.id === this.authUser.id) {
        return this.buildAvatarUrl(this.authUser.jid, currentTimeStamp);
      }
      if (this.authUser && objectToAssignAvatar.assigned_to && objectToAssignAvatar.assigned_to.id === this.authUser.id) {
        return this.authUser.userAvatar;
      }
      return objectToAssignAvatar.userAvatar;
    // comment
    } else  if (type === 3){
      if (forceResync && this.authUser && objectToAssignAvatar.user && objectToAssignAvatar.user.id === this.authUser.id) {
        return this.buildAvatarUrl(this.authUser.jid, currentTimeStamp);
      }
      if (this.authUser && objectToAssignAvatar.user && objectToAssignAvatar.user.id === this.authUser.id) {
        return this.authUser.userAvatar;
      }
      return objectToAssignAvatar.userAvatar;
    }
  }

  public serverUrlAvatar(userId: number, currentTimeStamp: number): string {
    let url;
    if (this.isCordovaOrElectron) {
      url = this.taskService.getAvatarURL(userId) + "&timestamp=" + currentTimeStamp;
    } else {
      url = this.taskService.getAvatarURL(userId) + "?timestamp=" + currentTimeStamp;
    }
    return url;
  }

  public setSearchParams(searchParams: any) {
    this.searchParams = searchParams;
  }

  public getSearchParams() {
    return this.searchParams;
  }

  public getTasksStats() {
    console.log("[TaskRepository] getTasksStats");

    let isSearchMode;
    this.store.select(getIsSearchMode).pipe(take(1)).subscribe(o => isSearchMode = o);

    if (isSearchMode) {
      let searchParams = this.getSearchQuery();
      let countParams = TaskUtils.getParamsForStatistics();

      // mobile
      if (CommonUtil.isSQLSupported() && !this.isOnline) {
        this.databaseService.getTaskStatisticsForSearch(searchParams).subscribe( res => {
          this.store.dispatch(new SetSearchStatisticsInfo({ total_count: res.total_count, due_tomorrow_count: res.due_tomorrow_count, overdue_count: res.overdue_count, completed_count: res.completed_count }));
        }, err => {
          // console.log('[OFFLINE] getTaskStatisticsForSearch error...');
          this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
        });
      // web
      } else {
        this.taskService.getSearchStatisticCount(countParams, searchParams).pipe(take(1)).subscribe(res => {
          this.store.dispatch(new SetSearchStatisticsInfo({ total_count: res.total_count, due_tomorrow_count: res.due_tomorrow_count, overdue_count: res.overdue_count, completed_count: res.completed_count }));
        }, err => {
          this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
        });
      }
    } else {
      let routingType;
      this.store.select(getSelectedFilterType).pipe(take(1)).subscribe(o => routingType = o);
      let routingOption;
      this.store.select(getSelectedFilterOption).pipe(take(1)).subscribe(o => routingOption = o);

      // mobile
      if (CommonUtil.isSQLSupported() && !this.isOnline) {
        this.store.select(IsDatabaseReady).pipe(filter(ready => !!ready), take(1)).subscribe(ready => {
          if ( routingType === TasksConstants.ROUTE_TYPE_STATUS ) {
            this.databaseService.getTaskStatistics(this.getParams()).subscribe( res => {
              let taskStatistics: TaskStatistics[] = this.generateTaskStatistics(res);
              this.databaseService.getTaskStatisticsForTaskFilter(this.getParams()).subscribe( res => {
                taskStatistics.forEach( taskStatistic => {
                  if (taskStatistic.type === routingOption ) {
                    taskStatistic.info = {
                      total_count: res.total_count,
                      due_tomorrow_count: res.due_tomorrow_count,
                      overdue_count: res.overdue_count,
                      completed_count: res.completed_count
                    };
                  }
                });
                this.store.dispatch(new SetTaskStatisticsInfo(taskStatistics));
              });
            }, err => {
              // console.log('[OFFLINE] getTaskStatistics error...');
              this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
            });
          } else {
            this.databaseService.getTaskStatisticsForSearch(this.getParams()).subscribe( res => {
              if ( routingType === TasksConstants.ROUTE_TYPE_LIST) {
                this.store.dispatch(new SetListStatisticsInfo({ total_count: res.total_count, due_tomorrow_count: res.due_tomorrow_count, overdue_count: res.overdue_count, completed_count: res.completed_count }));
              } else if ( routingType === TasksConstants.ROUTE_TYPE_TAG) {
                this.store.dispatch(new SetTagStatisticsInfo({ total_count: res.total_count, due_tomorrow_count: res.due_tomorrow_count, overdue_count: res.overdue_count, completed_count: res.completed_count }));
              } else {
                this.store.dispatch(new SetLocationStatisticsInfo({ total_count: res.total_count, due_tomorrow_count: res.due_tomorrow_count, overdue_count: res.overdue_count, completed_count: res.completed_count }));
              }
            }, err => {
              this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
            });
          }
        });

      // web
      } else {
        if ( routingType === TasksConstants.ROUTE_TYPE_TAG ) {
          this.store.select(getTagList).pipe(switchMap( tags => this.getTagStatisitcsInfo()), take(1)).subscribe();
        } else if ( routingType === TasksConstants.ROUTE_TYPE_LIST ) {
          this.store.select(getFolderList).pipe(switchMap( lists => this.getListStatisitcsInfo()), take(1)).subscribe();
        } else if ( routingType === TasksConstants.ROUTE_TYPE_LOCATION ) {
          this.store.select(getLocationList).pipe(switchMap( locations => this.getLocationStatisitcsInfo()), take(1)).subscribe();
        }
        if (this.isOnline) {
          this.taskService.getTaskStatisticsCount().pipe(take(1)).subscribe( res => {
            let taskStatistics: TaskStatistics[] = this.generateTaskStatistics(res);
            this.databaseService.addTaskStats(taskStatistics).subscribe(res => {
              this.store.dispatch(new SetTaskStatisticsInfo(taskStatistics));
            })
          }, err => {
            this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
          });
        } else {
          this.databaseService.getAllStats().subscribe(s => {
            this.store.dispatch(new SetTaskStatisticsInfo(s));
          })
        }
      }
    }
  }

  private getTagStatisitcsInfo(): Observable<{} | any> {
    let tagParams = this.getParams();
    if (tagParams) {
      let countParams = TaskUtils.getParamsForStatistics();
      return this.taskService.getSearchStatisticCount(countParams, tagParams).pipe(take(1),
      tap(res => {
        this.store.dispatch(new SetTagStatisticsInfo({ total_count: res.total_count, due_tomorrow_count: res.due_tomorrow_count, overdue_count: res.overdue_count, completed_count: res.completed_count }));
      }), catchError( err => {
        this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
        return err;
      }));
    }
    return of();
  }

  public getListStatisitcsInfo(): Observable<{} | any> {
    let listParams = this.getParams();
    if (listParams) {
      let countParams = TaskUtils.getParamsForStatistics();
      return this.taskService.getSearchStatisticCount(countParams, listParams).pipe(take(1),
      tap(res => {
        this.store.dispatch(new SetListStatisticsInfo({ total_count: res.total_count, due_tomorrow_count: res.due_tomorrow_count, overdue_count: res.overdue_count, completed_count: res.completed_count }));
      }), catchError( err => {
        this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
        return err;
      }));
    }
    return of();
  }

  public getLocationStatisitcsInfo(): Observable<{} | any> {
    let locationParams = this.getParams();
    if (locationParams) {
      let countParams = TaskUtils.getParamsForStatistics();
      return this.taskService.getSearchStatisticCount(countParams, locationParams).pipe(take(1),
      tap(res => {
        this.store.dispatch(new SetLocationStatisticsInfo({ total_count: res.total_count, due_tomorrow_count: res.due_tomorrow_count, overdue_count: res.overdue_count, completed_count: res.completed_count }));
      }), catchError( err => {
        this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
        return err;
      }));
    }
    return of();
  }

  public generateTaskStatistics(res: any): TaskStatistics[] {
    let taskStatistics: TaskStatistics[] = [];
    let taskStatistic: TaskStatistics;
    taskStatistic = {
      type: TasksConstants.ROUTE_ALL_TASK,
      info: {
        total_count: res.all_tasks.total_count,
        due_tomorrow_count: res.all_tasks.due_tomorrow_count,
        overdue_count: res.all_tasks.overdue_count,
        completed_count: res.all_tasks.completed_count
      }
    };
    taskStatistics.push(taskStatistic);
    taskStatistic = {
      type: TasksConstants.ROUTE_OPEN,
      info: {
        total_count: res.open.total_count,
        due_tomorrow_count: res.open.due_tomorrow_count,
        overdue_count: res.open.overdue_count,
        completed_count: res.open.completed_count
      }
    };
    taskStatistics.push(taskStatistic);
    taskStatistic = {
      type: TasksConstants.ROUTE_NEW,
      info: {
        total_count: res.new.total_count,
        due_tomorrow_count: res.new.due_tomorrow_count,
        overdue_count: res.new.overdue_count,
        completed_count: res.new.completed_count
      }
    };
    taskStatistics.push(taskStatistic);
    taskStatistic = {
      type: TasksConstants.ROUTE_COMPLETED,
      info: {
        total_count: res.completed.total_count,
        due_tomorrow_count: res.completed.due_tomorrow_count,
        overdue_count: res.completed.overdue_count,
        completed_count: res.completed.completed_count
      }
    };
    taskStatistics.push(taskStatistic);
    taskStatistic = {
      type: TasksConstants.ROUTE_ASSIGNEDTOME,
      info: {
        total_count: res.assigned_to_me.total_count,
        due_tomorrow_count: res.assigned_to_me.due_tomorrow_count,
        overdue_count: res.assigned_to_me.overdue_count,
        completed_count: res.assigned_to_me.completed_count
      }
    };
    taskStatistics.push(taskStatistic);
    taskStatistic = {
      type: TasksConstants.ROUTE_TODAY_DUE,
      info: {
        total_count: res.due_today.total_count,
        due_tomorrow_count: res.due_today.due_tomorrow_count,
        overdue_count: res.due_today.overdue_count,
        completed_count: res.due_today.completed_count
      }
    };
    taskStatistics.push(taskStatistic);
    taskStatistic = {
      type: TasksConstants.ROUTE_TOMORROW_DUE,
      info: {
        total_count: res.due_tomorrow.total_count,
        due_tomorrow_count: res.due_tomorrow.due_tomorrow_count,
        overdue_count: res.due_tomorrow.overdue_count,
        completed_count: res.due_tomorrow.completed_count
      }
    };
    taskStatistics.push(taskStatistic);
    taskStatistic = {
      type: TasksConstants.ROUTE_THIS_WEEK_DUE,
      info: {
        total_count: res.due_this_week_count.total_count,
        due_tomorrow_count: res.due_this_week_count.due_tomorrow_count,
        overdue_count: res.due_this_week_count.overdue_count,
        completed_count: res.due_this_week_count.completed_count
      }
    };
    taskStatistics.push(taskStatistic);
    taskStatistic = {
      type: TasksConstants.ROUTE_CREATEDBYME,
      info: {
        total_count: res.created_by_me.total_count,
        due_tomorrow_count: res.created_by_me.due_tomorrow_count,
        overdue_count: res.created_by_me.overdue_count,
        completed_count: res.created_by_me.completed_count
      }
    };
    taskStatistics.push(taskStatistic);
    taskStatistic = {
      type: TasksConstants.ROUTE_TASKS_I_WATCH,
      info: {
        total_count: res.watched_by_me.total_count,
        due_tomorrow_count: res.watched_by_me.due_tomorrow_count,
        overdue_count: res.watched_by_me.overdue_count,
        completed_count: res.watched_by_me.completed_count
      }
    };
    taskStatistics.push(taskStatistic);
    taskStatistic = {
      type: TasksConstants.ROUTE_MY_OVERDUE_TASKS,
      info: {
        total_count: res.overdue.total_count,
        due_tomorrow_count: res.overdue.due_tomorrow_count,
        overdue_count: res.overdue.overdue_count,
        completed_count: res.overdue.completed_count
      }
    };
    taskStatistics.push(taskStatistic);
    return taskStatistics;
  }

  private syncProjectsListAndMembers() {
    if (this.isOnline) {
      console.log("[task.repository] syncProjectsListAndMembers online");

      // get project list from server
      this.taskService.getProjects().pipe(take(1)).subscribe(projects => {
        // console.log("[task.repository] syncProjectsListAndMembers got projects from server", projects.length);
        this.store.dispatch(new StoreProjectList(projects));
        this.saveProjectsAndMembersToDB(projects).pipe(take(1)).subscribe((success) => {
          // save project to redux
        });
      }, err => {
        this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
      });
    } else {
      console.log("[task.repository] syncProjectsListAndMembers offline");

      this.store.select(getIsDeviceReady).pipe(filter(ready => !!ready), take(1)).subscribe(ready => {
        // pre-load projects to redux in no connection
        if (CommonUtil.isSQLSupported()) {
          this.store.select(IsDatabaseReady).pipe(filter(ready => !!ready), take(1)).subscribe(ready => {
            this.databaseService.getAllProjects().pipe(take(1)).subscribe((projects) => {
              console.log("[task.repository] syncProjectsListAndMembers, selected from DB", projects.length);
              if (projects.length > 0) {
                this.store.dispatch(new StoreProjectList(projects));
              }
            });
          });
        } else {
          const projects = this.localStorageService.getProjects();
          if (projects && projects.length > 0) {
            this.store.dispatch(new StoreProjectList(projects));
          }
        }
      });
    }
  }

  private saveProjectsAndMembersToDB(allProjects: Project[]): Observable<boolean>  {
    console.log("[task.repository][saveProjectsAndMembersToDB]");

    const response = new Subject<boolean>();

    // save project to local storage under Web
    if (!CommonUtil.isSQLSupported()) {
      this.localStorageService.storeProjects(allProjects);
    }

    this.getAllMembersFromServer(allProjects).subscribe(allMembersHash => {
      let allMembersFlatArray = [];
      Object.keys(allMembersHash).forEach(k => {
        allMembersFlatArray.push(...allMembersHash[k]);
      });

      console.log("[task.repository][saveProjectsAndMembersToDB] allMembersFlatArray", allMembersFlatArray);

      this.poppulateMembersAvatar(allMembersFlatArray, false).subscribe((success) => {
        if (success) {
          if (CommonUtil.isSQLSupported()) {
            this.store.select(IsDatabaseReady).pipe(filter(ready => !!ready), take(1)).subscribe(ready => {
              this.databaseService.createOrUpdateProjects(allProjects, allMembersHash).subscribe((success) => {
                response.next(true);
              });
            });
          } else {
            allProjects.forEach(project => {
              this.localStorageService.storeMemberList(project.id, allMembersHash[project.id]);
            });

            response.next(true);
          }
        }
      });
    });

    return response.asObservable().pipe(take(1));
  }

  private getAllMembersFromServer(allProjects: Project[]): Observable<any>  {
    console.log("[task.repository][getAllMembersFromServer] allProjects", allProjects.length);

    const response = new Subject<any>();

    let allMembersHash = {};
    let projectsProcessed: number = 0;

    // retrtieve & collect all members from server
    allProjects.forEach(project => {
      this.taskService.getMembers(project.id).pipe(take(1)).subscribe(members => {
        allMembersHash[project.id] = members;

        ++projectsProcessed;
        if (projectsProcessed === allProjects.length) {
          console.log("[task.repository] getAllMembersFromServer DONE");
          response.next(allMembersHash);
        }
      });
    });

    return response.asObservable().pipe(take(1));
  }

  public getMemberList(projectId: number) {
    console.log("[task.repository] getMemberList projectId", projectId);

    if (CommonUtil.isSQLSupported()) {
      this.store.select(IsDatabaseReady).pipe(filter(ready => !!ready), take(1)).subscribe(ready => {
        this.databaseService.getProjectMembers(projectId).subscribe((members) => {
          if (members && members.length > 0) {
            if (environment.isElectron) {
              members.forEach(member => {
                if (member.avatar) {
                  member.avatar = this.sanitizer.bypassSecurityTrustUrl(member.avatar);
                }
              });
            }
            this.store.dispatch(new StoreMemberList(members));
          }
        });
      });
    } else {
      const members = this.localStorageService.getMemberList(projectId);
      if (members && members.length > 0) {
        this.store.dispatch(new StoreMemberList(members));
      }
    }
  }

  public getTagsListWithCounters() {
    console.log("[task.repository] getTagsListWithCounters");

    if (this.isOnline) {
      this.taskService.getAllTagsWithCount().pipe(take(1)).subscribe(tags => {
        console.log("[task.repository] getTagsListWithCounters tags", tags);
        if (tags?.length) {
          const tagsRoute = tags.map(item => {
            return { routingType: "tags", routingOption: item.name };
          })
          this.AllTagListAndLocationTask.push(tagsRoute);
        }
        this.store.dispatch(new StoreTagList(tags));
      }, err => {
        this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
      });
    } else if (CommonUtil.isSQLSupported()) {
      // get from to DB
      this.store.select(IsDatabaseReady).pipe(filter(ready => !!ready), take(1)).subscribe(ready => {
        this.databaseService.getTagsWithTasksCount().subscribe((tagsCounters) => {
          console.log("[task.repository] getTagsListWithCounters tagsCounters", tagsCounters);
          if (tagsCounters) {
            this.store.dispatch(new StoreTagList(tagsCounters));
          }
        });
      });
    }
  }

  getSearchTags(searchtext: String): Observable<any[]> {
    const response = new Subject<any[]>();

    if (this.isOnline) {
      this.taskService.getSearchTags(searchtext).subscribe(res => {
        response.next(res.tags);
      });
    } else if (CommonUtil.isSQLSupported()) {
      this.databaseService.getSearchTags(searchtext).subscribe(tags => {
        response.next(tags);
      });
    } else {
      response.next(null);
    }

    return response.asObservable().pipe(take(1));
  }

  public getFolderListWithCounters() {
    if (this.isOnline) {
      this.taskService.getLists().pipe(take(1)).subscribe(lists => {
        console.log("[task.repository] getLists lists", lists);
        // save to DB
        if (CommonUtil.isSQLSupported() && lists && lists.length > 0) {
          this.store.select(IsDatabaseReady).pipe(filter(ready => !!ready), take(1)).subscribe(ready => {
            this.databaseService.createOrUpdateLists(lists).subscribe((success) => {
              console.log("[task.repository] lists stored in DB", success);
            });
          });
        }
        if (lists?.length) {
          const listRoute = lists.map(item => {
            return { routingType: "list", routingOption: item.name };
          })
          this.AllTagListAndLocationTask.push(listRoute);
        }
        this.store.dispatch(new StoreFolderList(lists));
      }, err => {
        this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
      });
    } else if (CommonUtil.isSQLSupported()) {
      // get from to DB
      this.store.select(IsDatabaseReady).pipe(filter(ready => !!ready), take(1)).subscribe(ready => {
        this.databaseService.getListsWithTasksCount().subscribe((listCounters) => {
          if (listCounters) {
            this.store.dispatch(new StoreFolderList(listCounters));
          }
        });
      });
    }
  }

  public createList(name: string) {
    // only when Internet is ON
    this.taskService.createList(name).subscribe( res => {
      this.successService.emit({ id: SuccessType.ListCreated, messages: this.msgs.LIST_CREATED });
    }, err => {
      this.errorService.emit({ id: ErrorType.ListError, messages: err });
    });
  }

  public updateList(list: List) {
    // only when Internet is ON
    this.taskService.UpdateList(list).subscribe( res => {
      this.successService.emit({ id: SuccessType.ListUpdated, messages: this.msgs.LIST_UPDATED });
    }, err => {
      this.errorService.emit({ id: ErrorType.LocationError, messages: err });
    });
  }

  public deleteList(list: List) {
    // only when Internet is ON
    this.taskService.deleteList(list).subscribe( res => {
      if (CommonUtil.isSQLSupported()) {
        this.databaseService.deleteList(list.id).subscribe((success) => {
          this.databaseService.removeListFromTasks(list.id).subscribe((success) => {

          });
        });
      }
      this.successService.emit({ id: SuccessType.ListDeleted, messages: this.msgs.LIST_DELETED });
    }, err => {
      this.errorService.emit({ id: ErrorType.ListError, messages: err });
    });
  }

  public getLocationsWithCounters() {
    if (this.isOnline) {
      this.taskService.getLocations().pipe(take(1)).subscribe(locations => {
        console.log("[task.repository] getLocations locations", locations);
        // save to DB
        if (CommonUtil.isSQLSupported() && locations && locations.length > 0) {
          this.store.select(IsDatabaseReady).pipe(filter(ready => !!ready), take(1)).subscribe(ready => {
            this.databaseService.createOrUpdateLocations(locations).subscribe((success) => {
              console.log("[task.repository] locations stored in DB", success);
            });
          });
        }
        if (locations?.length) {
          const locationRoute = locations.map(item => {
            return { routingType: "location", routingOption: item.name };
          })
          this.AllTagListAndLocationTask.push(locationRoute);
        }
        this.store.dispatch(new StoreLocationList(locations));
      }, err => {
        this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
      });
    } else if (CommonUtil.isSQLSupported()) {
      // get from to DB
      this.store.select(IsDatabaseReady).pipe(filter(ready => !!ready), take(1)).subscribe(ready => {
        this.databaseService.getLocationsWithTasksCount().subscribe((locationCounters) => {
          if (locationCounters) {
            this.store.dispatch(new StoreLocationList(locationCounters));
          }
        });
      });
    }
  }

  public createLocation(name: string) {
    // only when Internet is ON
    this.taskService.createLocation(name).subscribe( res => {
      this.successService.emit({ id: SuccessType.LocationCreated, messages: this.msgs.LOCATION_CREATED });
    }, err => {
      this.errorService.emit({ id: ErrorType.LocationError, messages: err });
    });
  }

  public updateLocation(location: Location) {
    // only when Internet is ON
    this.taskService.UpdateLocation(location).subscribe( res => {
      this.successService.emit({ id: SuccessType.LocationUpdated, messages: this.msgs.LOCATION_UPDATED });
    }, err => {
      this.errorService.emit({ id: ErrorType.ListError, messages: err });
    });
  }

  public deleteLocation(location: Location) {
    // only when Internet is ON
    this.taskService.deleteLocation(location).subscribe( res => {
      if (CommonUtil.isSQLSupported()) {
        this.databaseService.deleteLocation(location.id).subscribe((success) => {
          this.databaseService.removeLocationFromTasks(location.id).subscribe((success) => {

          });
        });
      }
      this.successService.emit({ id: SuccessType.LocationDeleted, messages: this.msgs.LOCATION_DELETED });
    }, err => {
      this.errorService.emit({ id: ErrorType.LocationError, messages: err });
    });
  }

  public changeDisplayNamePriorities() {
    this.getPriorityList();
  }

  private getPriorityList() {
    const priorities = this.localStorageService.getPriorties();

    if (priorities && priorities.length > 0) {
      this.parseAndStorePriorityList(priorities);
    }

    if (this.isOnline) {
      this.taskService.getPriorities().pipe(take(1)).subscribe(priorities => {
        priorities.map(i => {
          if  (i?.name == "Niedrig") {
            i.name = "Low";
          }
          return i
        });
        this.parseAndStorePriorityList(priorities);
      }, err => {
        if (!priorities || priorities.length === 0) {
          this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
        }
      });
    }
  }

  private parseAndStorePriorityList(priorities) {
    this.localStorageService.storePriorties(priorities);

    priorities.map(priority => {
      let displayName = this.getPriorityTranslation(priority.name.toUpperCase());
      priority["displayName"] = displayName;
      return priority;
    });

    this.store.dispatch(new StorePriorityList(priorities));
  }

  private getAllUsers() {
    if (this.isOnline) {
      this.taskService.getAllUsers().subscribe(allUsers => {
        this.allUserList$.next(allUsers);
        this.databaseService.addContacts(allUsers).subscribe(data => {
          if (allUsers.length) {
            allUsers.forEach((elem) => {
              CommonUtil.getBase6ImageFromUrl(elem?.avatar).subscribe(baseImg => {
                const avatar = {
                  id: elem?.id,
                  data: baseImg
                }
                this.databaseService.addAvatar(avatar);
              })
            });
          }
          this.store.dispatch(new BulkLoadVNCContacts(allUsers))
        })
      });
    }
    else {
      this.store.select(getAllContacts).subscribe(res => {
        this.allUserList$.next(res);
      })
      this.databaseService.getContacts().subscribe(data => {
        this.allUserList$.next(data);
        this.store.dispatch(new BulkLoadVNCContacts(data))
      })
    }
  }

  private getAllTags() {
    if (this.isOnline) {
      this.taskService.getAllTags().pipe(take(1)).subscribe(allTags => {
        this.allTagList$.next(allTags);
      });
    } else if (CommonUtil.isSQLSupported()) {
      this.store.select(IsDatabaseReady).pipe(filter(ready => !!ready), take(1)).subscribe(ready => {
        this.databaseService.getAllTags().subscribe(allTags => {
          this.allTagList$.next(allTags);
        });
      });
    }
  }

  public syncAuthUser() {
    console.log("[task.repository] syncAuthUser");

    // got user from local storage
    const user = this.localStorageService.getUser();
    if (user) {
      if (environment.isElectron) {
        if (user.userAvatar) {
          if (CommonUtil.isFileSystemUrl(user.userAvatar.toString())) {
            user.userAvatar = this.sanitizer.bypassSecurityTrustUrl(user.userAvatar);
          }
        }
      }
      this.store.dispatch(new StoreAuthUser(user));
    }

    // sync user from server
    if (this.isOnline) {
      this.taskService.getAuthUser().pipe(take(1)).subscribe(authUser => {
        console.log("[task.repository][syncAuthUser] obtained user: ", authUser);
        if (authUser.language !== "en" && authUser.language !== "de") {
          authUser.language = "en";
        }
        this.loadLanguage(authUser.language);
        this.poppulateAuthUserAvatar(authUser, true).subscribe(success => {
          if (success) {
            console.log("[task.repository][syncAuthUser] set avatar for user. Not storing to cache");
            this.localStorageService.storeUser(authUser);
            if (environment.isElectron) {
              if (authUser.userAvatar) {
                if (CommonUtil.isFileSystemUrl(authUser.userAvatar.toString())) {
                  authUser.userAvatar = this.sanitizer.bypassSecurityTrustUrl(authUser.userAvatar);
                }
              }
            }
            this.store.dispatch(new StoreAuthUser(authUser));
          }
        });
      }, err => {
        if (!user) {
          this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
        }
      });
    }
  }

  public loadLanguage(selectedLanguage) {
    if (!selectedLanguage) {
      selectedLanguage = "en";
    }
    this.translate.use(selectedLanguage);
    this.translate.reloadLang(selectedLanguage);
    this.configService.language = selectedLanguage;
  }

  public updateUserAvatar(img: any) {
    this.taskService.updateUserAvatar(img).subscribe(res => {
      this.successService.emit({ id: SuccessType.UserAvatarUpdated, messages: this.msgs.PROFILE_UPDATED_MSG});
    }, err => {
      this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
    });
  }

  public removeUserAvatar() {
    this.taskService.removeUserAvatar().subscribe(res => {
      this.successService.emit({ id: SuccessType.UserAvatarUpdated, messages: this.msgs.PROFILE_REMOVED_MSG});
    }, err => {
      this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
    });
  }

  public addNewTask(payload: any) {
    console.log("[task.repository][addNewTask] payload", payload);

    this.store.dispatch(new CreateTask());

    // add on server
    if (this.isOnline) {
      this.taskService.addNewTask(payload).subscribe(task => {
        this.addNewTaskOnDBIfNeededAndFireResult(task);
      }, err => {
        this.addNewTaskError(err);
      });
    // create task in offline
    } else if (CommonUtil.isSQLSupported()) {
      const taskToCreate = Task.taskToCreateOffline(payload);
      this.databaseService.addNewTask(taskToCreate).subscribe(res => {
        this.databaseService.addPendingTaskCreate(taskToCreate.id).subscribe(res => {

        });
        this.addNewTaskSuccess(taskToCreate);
      });
    }
  }

  private addNewTaskOnDBIfNeededAndFireResult(task: Task) {
    // add on DB
    if (CommonUtil.isSQLSupported()) {
      this.store.select(IsDatabaseReady).pipe(filter(ready => !!ready), take(1)).subscribe(ready => {
        this.databaseService.addNewTask(task).subscribe(res => {
          this.addNewTaskSuccess(task);
        });
      });
    } else {
      this.addNewTaskSuccess(task);
    }
  }

  private addNewTaskSuccess(task: Task){
    console.log("[TaskRepository] addNewTaskSuccess");

    this.poppulateAssigneeAvatar([task]).subscribe((success) => {
      if (success) {
        this.addTaskInStore(task);
        this.pushCreateTaskSuccessMessage(task?.id);
      }
    });
  }

  private addNewTaskError(err){
    console.log("[TaskRepository] addNewTaskError", err);

    this.store.dispatch(new CreateTaskFail());
    this.errorService.emit({ id: ErrorType.TaskCreateFail, messages: err });
  }

  private addTaskInStore(task: Task) {
    let routingOption;
    this.store.select(getSelectedFilterOption).pipe(take(1)).subscribe(o => routingOption = o);
    let routingType;
    this.store.select(getSelectedFilterType).pipe(take(1)).subscribe(o => routingType = o);

    switch (routingType) {
      case TasksConstants.ROUTE_TYPE_STATUS:
      switch (routingOption) {
        case TasksConstants.ROUTE_COMPLETED:
        case TasksConstants.ROUTE_SEARCH:
        case TasksConstants.ROUTE_TASKS_I_WATCH:
          this.store.dispatch(new SetIsLoading(false));
          break;
        case TasksConstants.ROUTE_ASSIGNEDTOME:
          if (task.assigned_to) {
            let authUser;
            this.store.select(getAuthUser).pipe(take(1)).subscribe(o => authUser = o);
            if (authUser.id !== task.assigned_to.id) {
              this.store.dispatch(new CreateTaskSuccess({ task: task }));
            } else {
              this.store.dispatch(new SetIsLoading(false));
            }
          } else {
            this.store.dispatch(new SetIsLoading(false));
          }
          break;
        case TasksConstants.ROUTE_TODAY_DUE:
          if (task.due_date) {
            let taskDate = task.due_date;
            taskDate.setHours(0, 0, 0, 0);
            if (taskDate.getTime() === TaskUtils.getCurrentDate().getTime()) {
              this.store.dispatch(new CreateTaskSuccess({ task: task }));
            } else {
              this.store.dispatch(new SetIsLoading(false));
            }
          } else {
            this.store.dispatch(new SetIsLoading(false));
          }
          break;
        case TasksConstants.ROUTE_TOMORROW_DUE:
          if (task.due_date) {
            let taskDate = task.due_date;
            taskDate.setHours(0, 0, 0, 0);
            if (taskDate.getTime() === TaskUtils.getTomorrowDate().getTime()) {
              this.store.dispatch(new CreateTaskSuccess({ task: task }));
            } else {
              this.store.dispatch(new SetIsLoading(false));
            }
          } else {
            this.store.dispatch(new SetIsLoading(false));
          }
          break;
        case TasksConstants.ROUTE_THIS_WEEK_DUE:
          if (task.due_date) {
            let taskDate = task.due_date;
            taskDate.setHours(0, 0, 0, 0);
            let weekDate = TaskUtils.getstartAndEndOfWeek();
            if (taskDate.getTime() >= weekDate[0].getTime() && taskDate.getTime() <= weekDate[1].getTime()) {
              this.store.dispatch(new CreateTaskSuccess({ task: task }));
            } else {
              this.store.dispatch(new SetIsLoading(false));
            }
          } else {
            this.store.dispatch(new SetIsLoading(false));
          }
          break;
        default:
          this.store.dispatch(new CreateTaskSuccess({ task: task }));
          break;
        }
        break;
      case TasksConstants.ROUTE_TYPE_LIST:
        if (task.list) {
          if (routingOption === task.list.name) {
            this.store.dispatch(new CreateTaskSuccess({ task: task }));
          } else {
            this.store.dispatch(new SetIsLoading(false));
          }
        } else {
          this.store.dispatch(new SetIsLoading(false));
        }
        break;
      case TasksConstants.ROUTE_TYPE_TAG:
        if (task.tags) {
          let tag = task.tags.find( tag => tag.name === routingOption);
          if (tag) {
            this.store.dispatch(new CreateTaskSuccess({ task: task }));
          } else {
            this.store.dispatch(new SetIsLoading(false));
          }
        } else {
          this.store.dispatch(new SetIsLoading(false));
        }
        break;
      case TasksConstants.ROUTE_TYPE_LOCATION:
        if (task.location) {
          if (routingOption === task.location.name) {
            this.store.dispatch(new CreateTaskSuccess({ task: task }));
          } else {
            this.store.dispatch(new SetIsLoading(false));
          }
        } else {
          this.store.dispatch(new SetIsLoading(false));
        }
        break;
      default:
        this.store.dispatch(new CreateTaskSuccess({ task: task }));
        break;
    }
  }

  private pushCreateTaskSuccessMessage(taskId) {
    this.successService.emit({ id: SuccessType.TaskCreated, messages: this.msgs.TASK_CREATED,taskId: taskId });
  }

  removeTask(id: number) {
    this.removeTasksInternal([id], SuccessType.TaskRemoved);
  }

  removeTasks(selectedIds: number[]): Observable<any> {
    this.removeTasksInternal(selectedIds, SuccessType.TasksRemoved);
    return
  }

  private removeTasksInternal(selectedIds: number[], successEvent: SuccessType) {
    console.log("[TaskRepository] removeTasksInternal: ", selectedIds);

    this.store.dispatch(new RemoveTasks());

    // remove on server
    if (this.isOnline) {
      this.taskService.removeTasks(selectedIds).pipe(take(1)).subscribe(res => {
        // remove on DB
        if (CommonUtil.isSQLSupported()) {
          this.databaseService.removeTasks(selectedIds).subscribe(res => {
            this.removeTasksSuccess(selectedIds, successEvent);
          });
        } else {
          this.removeTasksSuccess(selectedIds, successEvent);
        }
      }, err => {
        // 404
        if (err === "Resource Not Found" && CommonUtil.isSQLSupported()) {
          // remove on DB if not founfd on server
          this.databaseService.removeTasks(selectedIds).subscribe(res => {
            this.removeTasksSuccess(selectedIds, successEvent);
          });
        } else {
          this.removeTasksError(err);
        }
      });

    // offline remove
    } else if (CommonUtil.isSQLSupported()) {
      // delete on DB and create a pending operation
      this.databaseService.removeTasks(selectedIds).subscribe(res => {
        // add to pending queue
        selectedIds.forEach(taskId => {
          this.databaseService.addPendingTaskDelete(taskId).subscribe(res => {

          });
        });

        this.removeTasksSuccess(selectedIds, successEvent);
      });
    } else if (!this.isOnline) {
      let taskStats = [];
      this.store.select(getTaskStatisticsInfo).subscribe(info => {
        if (info) {
          taskStats = info;
        }
      });
      const id = Math.floor(Math.random() * 1000);
      const task = {
        id: id,
        body: {
          tasks: selectedIds,
          operationType: "deleteTask",
        }
      }
      this.databaseService.addPendingOperationTask([task]).subscribe(updatedTask => {
        selectedIds.forEach((elem) => {
          TaskRepository.taskStoreList.forEach((store) => {
            this.databaseService.getTaskById(elem, store).subscribe(t => {
              if (t) {
                const currentStoreStat = TaskRepository.statWithStore.find(r => r.store === store);
                this.removeTasksSuccess(selectedIds, successEvent);
                this.databaseService.deleteBulkTasks(elem, store);
                taskStats = taskStats.map(stat => {
                  if (stat.type === currentStoreStat.stat) {
                    stat.info.completed_count = stat.info.completed_count - 1;
                    stat.info.total_count = stat.info.total_count - 1;
                  }
                  return stat;
                })
                this.databaseService.addTaskStats(taskStats).subscribe(done => {
                  this.getTasksStats();
                  let routingOption;
                  this.store.select(getSelectedFilterOption).pipe(take(1)).subscribe(o => {
                    const currentStore = TaskRepository.statWithStore.find(r => r.store === store);
                    if (currentStore?.store == routingOption && routingOption != "search") this.getDataFromDatabase(routingOption);
                  });
                })
              }
            })
          })
        });
      })
    }
  }

  private removeTasksSuccess(selectedIds: number[], successEvent: SuccessType){
    console.log("[TaskRepository] removeTasksSuccess");

    this.store.dispatch(new RemoveTasksSuccess({ ids: selectedIds }));
    this.successService.emit({ id: successEvent, messages: this.msgs.TASK_REMOVED_MSG });
  }

  private removeTasksError(err){
    console.log("[TaskRepository] removeTasksError", err);

    this.store.dispatch(new RemoveTasksFail());
    this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
  }

  completeTask(task: Task, args: BulkUpdateArgs) {
    console.log("[TaskRepository] completeTask: ", task);

    this.store.dispatch(new UpdateTask());

    // complete on server
    if (this.isOnline) {
      this.taskService.updateBulkTasks([task.id], BulkUpdateIssueType.Status, args.id).pipe(take(1)).subscribe(res => {
        task.status = { id: args.id, name: args.name };
        task.done_ratio = 100;
        // complete on DB
        if (CommonUtil.isSQLSupported()) {

          this.databaseService.completeTask(task).subscribe(res => {
            this.completeTaskSuccess(task);
          });
        } else {
          this.completeTaskSuccess(task);
        }
      }, err => {
        this.completeTaskError(err);
      });

    // offline complete
    } else if (CommonUtil.isSQLSupported()) {
      task.status = { id: args.id, name: args.name };
      task.done_ratio = 100;
      this.databaseService.completeTask(task).subscribe(res => {
        // add to pending queue
        this.databaseService.addPendingTaskUpdate(task.id).subscribe(res => {

        });

        this.completeTaskSuccess(task);
      });
    }
  }

  private completeTaskSuccess(task: Task){
    console.log("[TaskRepository] completeTaskSuccess");

    let routingOption;
    this.store.select(getSelectedFilterOption).pipe(take(1)).subscribe(o => routingOption = o);

    if (routingOption === TasksConstants.ROUTE_OPEN || routingOption === TasksConstants.ROUTE_MY_OVERDUE_TASKS) {
      this.store.dispatch(new RemoveTasksSuccess({ ids: [task.id] }));
    } else {
      const changes = { id: task.id, changes: task };
      this.store.dispatch(new UpdateTaskSuccess({ task: changes }));
    }
    this.successService.emit({ id: SuccessType.TaskCompleted, messages: this.msgs.TASK_UPDATED });
    this.broadcaster.broadcast("statusUpdated", task.status);
  }

  private completeTaskError(err){
    console.log("[TaskRepository] completeTaskError", err);

    this.store.dispatch(new UpdateTaskFail());
    this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
  }

  duplicateTasks(tasks: Task[]): Observable<any> {
    const response = new Subject<any>();
    console.log("[TaskRepository] duplicateTasks. tasks: ", tasks);

    const tasksPayloadToCreate: any[] = tasks.map(t => this.getTaskCopy(t));

    this.store.dispatch(new CreateTask());

    console.log("[TaskRepository] duplicateTasks. tasksPayloadToCreate: ", tasksPayloadToCreate);

    if (this.isOnline) {
      // create on server
      this.taskService.bulkCreateTasks(tasksPayloadToCreate).pipe(take(1)).subscribe(createdTasks => {
        // create on DB
        if (CommonUtil.isSQLSupported()) {
          this.databaseService.saveOrUpdateTasks(createdTasks).pipe(take(1)).subscribe((res) => {
            this.duplicateTasksSuccess(createdTasks);
          });
        } else {
          this.duplicateTasksSuccess(createdTasks);
          this.databaseService.addNewTasks(createdTasks);
          this.databaseService.addAllTasks(createdTasks);
          response.next("done");
        }
      }, err => {
        this.duplicateTasksError(err);
        response.next(err);
      });

    // offline diplicate
    } else if (CommonUtil.isSQLSupported()) {
      // copy tasks
      let dupTasks = [];
      tasksPayloadToCreate.forEach(tp => {
        let copyTask: Task = Task.taskToCreateOffline(tp);
        dupTasks.push(copyTask);
      });

      dupTasks.forEach(taskToCreate => {
        this.databaseService.addNewTask(taskToCreate).subscribe(res => {
          this.databaseService.addPendingTaskCreate(taskToCreate.id).subscribe(res => {
            this.duplicateTasksSuccess(dupTasks);
          });
        });
      });
    }
    if (!this.isOnline) {
      let taskStats = [];
      this.store.select(getTaskStatisticsInfo).subscribe(info => {
        if (info) {
          taskStats = info;
        }
      });
      const id = Math.floor(Math.random() * 1000);
      const task = {
        id: id,
        body: {
          tasks: tasks,
          operationType: "duplicateTask" ,
        }
      }
      this.databaseService.addPendingOperationTask([task]).subscribe(res => {
        let required_statuses = this.getStatusItems();
        tasks.forEach((item, index) => {
          item.id = id + index;
          let newStatus;
          newStatus = required_statuses.find(s => {
            if (s.name.toLowerCase() === "new") {
              return { id: s.id, name: s.name }
            }
          });
          item.isChecked = false;
          item.status = {
            id: newStatus.id,
            name: newStatus.name
          }
          this.databaseService.addNewTasks([item]);
          this.databaseService.addAllTasks([item]);
          newStatus = required_statuses.find(s => {
            if (s.name.toLowerCase() === "in progress") {
              return { id: s.id, name: s.name }
            }
          });
          item.status = {
            id: newStatus.id,
            name: newStatus.name
          }
          this.databaseService.addOpenTasks([item]);

          taskStats = taskStats.map(stat => {
            if (stat.type == "new" || stat.type == "open" || stat.type == "all") {
              stat.info.completed_count = stat.info.completed_count + 1;
              stat.info.total_count = stat.info.total_count + 1;
            }
            return stat;
          })
        });
        this.duplicateTasksSuccess(tasks);
        this.store.dispatch(new SetSelectAll(false));
        this.store.dispatch(new ResetSelectedTaskId());
        this.store.dispatch(new SetEditTaskView(false));
        this.databaseService.addTaskStats(taskStats).subscribe(done => {
          this.getTasksStats();
          let routingOption;
          this.store.select(getSelectedFilterOption).pipe(take(1)).subscribe(o => routingOption = o);
          if (routingOption && routingOption != "search") this.getDataFromDatabase(routingOption);
        })
      })
    }
    return response.asObservable();
  }

  duplicateTasksSuccess(createdTasks: Task[]){
    console.log("[TaskRepository] duplicateTasksSuccess");

    let routingOption;
    let sortBy;
    this.store.select(getSortBy).pipe(take(1)).subscribe(o => sortBy = o);
    this.store.select(getSelectedFilterOption).pipe(take(1)).subscribe(o => routingOption = o);

    this.poppulateAssigneeAvatar(createdTasks).subscribe((success) => {
      if (success) {
        if (routingOption !== TasksConstants.ROUTE_COMPLETED &&
            routingOption !== TasksConstants.ROUTE_TODAY_DUE  &&
            routingOption !== TasksConstants.ROUTE_TOMORROW_DUE &&
            routingOption !== TasksConstants.ROUTE_THIS_WEEK_DUE ) {

          this.store.dispatch(new CreateTasksSuccess({ tasks: createdTasks }));
          this.broadcaster.broadcast("sortTasksList");
        } else {
          this.store.dispatch(new SetIsLoading(false));
        }
        this.successService.emit({ id: SuccessType.TaskDuplicated, messages: this.msgs.DUPLICATE_TASK_CREATED });
      }
    });
  }

  private duplicateTasksError(err){
    console.log("[TaskRepository] duplicateTasksError", err);
    this.store.dispatch(new CreateTaskFail());
    this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
  }

  private getTaskCopy(t: any): any {
    let taskObj = {
      assigned_to_id: t.assigned_to !== undefined ? t.assigned_to.id : null,
      assigned_to_name: t.assigned_to !== undefined ? t.assigned_to.name : null,
      priority_id: t.priority !== undefined ? t.priority.id : null,
      priority_name: t.priority !== undefined ? t.priority.name : null,
      project_id: t.project !== undefined ? t.project.id : null,
      project_name: t.project !== undefined ? t.project.name : null,
      start_date: (((t.start_date !== undefined && t.start_date !== null ) && t.start_date.getTime() !== TaskUtils.dueDateOrStartDateNull().getTime()) ? TaskUtils.datePipe.transform(t.start_date, "yyyy-MM-dd") : null),
      subject: t.subject,
      author_id: t.author !== undefined ? t.author.id : null,
      author_name: t.author !== undefined ? t.author.name : null,
      tracker_id: t.tracker !== undefined ? t.tracker.id : null,
      list_id: (t.list ? t.list.id : null),
      external_url: t.external_url !== undefined ? t.external_url : null
    };
    for (let propName in taskObj) {
      if (taskObj[propName] === null || taskObj[propName] === undefined) {
        delete taskObj[propName];
      }
    }
    return taskObj;
  }

  updateBulkTasks(selectedTasks: Task[], fieldToUpdate: BulkUpdateIssueType, args: BulkUpdateArgs): Observable<any>{
    const response = new Subject<any>();
    console.log("[TaskRepository] updateBulkTasks");

    const tasksIds: number[] = selectedTasks.map(t => t.id);

    let valueToUpdate;
    switch (fieldToUpdate) {
      case BulkUpdateIssueType.StartDate:
      case BulkUpdateIssueType.DueDate:
      case BulkUpdateIssueType.InviteTo:
        valueToUpdate = (args.value != null) ? args.value : "none";
        break;
      case BulkUpdateIssueType.Tags:
        valueToUpdate = [args.name];
        break;
      case BulkUpdateIssueType.Watchers:
        let watchers = JSON.parse(args.value);
        valueToUpdate = [];
        watchers.forEach ( member => {
          valueToUpdate.push(member.id);
        });
        break;
      default:
        valueToUpdate = args.id;
    }

    console.log("[TaskRepository] updateBulkTasks. fieldToUpdate: " + fieldToUpdate + ". valuesToUpdate: " + valueToUpdate);

    this.store.dispatch(new UpdateTask());
    selectedTasks.forEach((i) => {
      i.isChecked = false
    });
    if (this.isOnline) {
      // update on server
      this.taskService.updateBulkTasks(tasksIds, fieldToUpdate, valueToUpdate).pipe(take(1)).subscribe(res => {
        this.updateBulkTasksOnDB(selectedTasks, fieldToUpdate, args, valueToUpdate);
        this.syncBulkUpdateToDB(selectedTasks, fieldToUpdate, args, valueToUpdate);
        this.FormattedDateForDB = null;
        response.next("done");
      // server error
      }, err => {
        console.error("[TaskRepository] updateBulkTasks, error from server: ", err);
        let msg = "";
        switch (fieldToUpdate) {
          case BulkUpdateIssueType.StartDate:
            msg = this.msgs.START_DATE_CHANGE_ERROR_MESSAGE;
            break;
          case BulkUpdateIssueType.DueDate:
            msg = this.msgs.TASK_DUE_DATE_UPDATE_FAIL;
            break;
          default:
            msg = err;
        }
        response.error(err);
        this.store.dispatch(new UpdateTaskFail());
        this.errorService.emit({ id: ErrorType.GenericMessage, messages: msg });
      });
    } else if (CommonUtil.isSQLSupported()) {
      this.updateBulkTasksOnDB(selectedTasks, fieldToUpdate, args, valueToUpdate);
    }
    // if (!this.isOnline) this.updateBulkTasksOnDB(selectedTasks, fieldToUpdate, args, valueToUpdate);
    else if (!this.isOnline) {
      const id = Math.floor(Math.random() * 1000);
      const task = {
        id: id,
        body: {
          tasks: selectedTasks,
          fieldToUpdate: fieldToUpdate,
          valueToUpdate: args,
          operationType: BulkUpdateIssueType[fieldToUpdate],
        }
      }
      this.databaseService.addPendingOperationTask([task]).subscribe(updatedTask => {
        // this.store.dispatch(new SetIsLoading(false));
        this.updateBulkTasksOnDB(selectedTasks, fieldToUpdate, args, valueToUpdate);
        this.store.dispatch(new SetSelectAll(false));
        this.store.dispatch(new ResetSelectedTaskId());
        this.store.dispatch(new SetEditTaskView(false));
        this.syncBulkUpdateToDB(selectedTasks, fieldToUpdate, args, valueToUpdate);
        response.next("done");
      }, err => {
        response.error(err);
        this.store.dispatch(new UpdateTaskFail());
        this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
      })
    }
    return response.asObservable();
  }

  private updateBulkTasksOnDB(selectedTasks: Task[], fieldToUpdate: BulkUpdateIssueType, args: BulkUpdateArgs, valueToUpdate: any) {
    let routingOption;
    let routingType;
    let sortBy;
    this.store.select(getSelectedFilterOption).pipe(take(1)).subscribe(o => routingOption = o);
    this.store.select(getSelectedFilterType).pipe(take(1)).subscribe(o => routingType = o);
    this.store.select(getSortBy).pipe(take(1)).subscribe(o => sortBy = o);

    let removeTasksFromSet = false;
    let updateTasksFromSet = false;
    let updateTasks: Task[] = [];
    let tasksIds: number[] = selectedTasks.map(t => t.id);
    let watchers: any = null;

    // updates local tasks objects
    switch (fieldToUpdate) {
      case BulkUpdateIssueType.AssignedTo:
        selectedTasks.forEach(task => {
          task.assigned_to = { id: args.id, name: args.name };
          task.invitee_email = null;
        });
        let authUser;
        this.store.select(getAuthUser).pipe(take(1)).subscribe(o => authUser = o);
        if (routingOption === TasksConstants.ROUTE_ASSIGNEDTOME || routingOption === TasksConstants.ROUTE_MY_OVERDUE_TASKS) {
          if (args.id !== authUser.id) {
            removeTasksFromSet = true;
          }
        } else if (routingOption !== TasksConstants.ROUTE_CREATEDBYME && routingOption !== TasksConstants.ROUTE_TASKS_I_WATCH){
          selectedTasks.forEach ( task => {
            if ((task.assigned_to && task.assigned_to.id !== authUser.id) && ( task.author && task.author.id !== authUser.id)) {
              removeTasksFromSet = true;
              updateTasksFromSet = true;
            } else {
              tasksIds = tasksIds.filter( id => id !== task.id );
              updateTasks.push(task);
            }
          });
        }
        break;

      // assign to external user
      case BulkUpdateIssueType.InviteTo:
        selectedTasks.forEach(task => {
          task.assigned_to = null;
          task.invitee_email = args.value;
          task.userAvatar = null;
        });
        if (routingOption === TasksConstants.ROUTE_ASSIGNEDTOME) {
          removeTasksFromSet = true;
        }
        break;

      case BulkUpdateIssueType.Status:
        if (routingOption === TasksConstants.ROUTE_OPEN || routingOption === TasksConstants.ROUTE_NEW || routingOption === TasksConstants.ROUTE_MY_OVERDUE_TASKS) {
          removeTasksFromSet = true;
        }
        selectedTasks.forEach(task => {
          task.status = { id: args.id, name: args.name };
          task.done_ratio = 100;
        });
        break;

      case BulkUpdateIssueType.Priority:
        selectedTasks.forEach(task => {
          task.priority = { id: args.id, name: args.name };
        });
        break;

      case BulkUpdateIssueType.Project:
        selectedTasks.forEach(task => {
          task.project = { id: args.id, name: args.name };
        });
        break;

      case BulkUpdateIssueType.StartDate:
        selectedTasks.forEach(task => {
          task.start_date = (args.value != null) ? new Date(args.value) : null;
        });
        break;
      case BulkUpdateIssueType.Tags:
        selectedTasks.forEach(task => {
          let hasTag = task.tags.filter(tag =>
            tag.id === args.id
          );
          if (hasTag.length < 1 ) {
            task.tags.push({ id: args.id, name: args.name });
          }
        });
        break;

      case BulkUpdateIssueType.List:
        selectedTasks.forEach(task => {
          task.list = { id: args.id, name: args.name };
        });
        if (routingType === TasksConstants.ROUTE_TYPE_LIST && routingOption !== args.name ) {
          removeTasksFromSet = true;
        }
        break;

      case BulkUpdateIssueType.Watchers:
        selectedTasks.forEach( task => {
          if (!task.watchers) { task.watchers = []; }
          watchers = JSON.parse(args.value);
          watchers.forEach ( member => {
              task.watchers.push(member);
          });
        });
        break;

      case BulkUpdateIssueType.Location:
        selectedTasks.forEach(task => {
          task.location = { id: args.id, name: args.name };
        });
        if (routingType === TasksConstants.ROUTE_TYPE_LOCATION && routingOption !== args.name ) {
          removeTasksFromSet = true;
        }
        break;

      case BulkUpdateIssueType.DueDate:
        selectedTasks.forEach(task => {
          task.due_date = (args.value != null) ? new Date(args.value) : null;
        });
        if (routingOption === TasksConstants.ROUTE_TODAY_DUE ||
          routingOption === TasksConstants.ROUTE_TOMORROW_DUE ||
          routingOption === TasksConstants.ROUTE_THIS_WEEK_DUE ||
          routingOption === TasksConstants.ROUTE_MY_OVERDUE_TASKS) {
          let itemDate = new Date(args.value);
          itemDate.setHours(0, 0, 0, 0);
          switch (routingOption) {
            case TasksConstants.ROUTE_TODAY_DUE:
              if (itemDate.getTime() !== TaskUtils.getCurrentDate().getTime()) {
                removeTasksFromSet = true;
              }
              break;
            case TasksConstants.ROUTE_TOMORROW_DUE:
              if (itemDate.getTime() !== TaskUtils.getTomorrowDate().getTime()) {
                removeTasksFromSet = true;
              }
              break;
            case TasksConstants.ROUTE_THIS_WEEK_DUE:
              let weekDate = TaskUtils.getstartAndEndOfWeek();
              if (itemDate.getTime() < weekDate[0].getTime() || itemDate.getTime() > weekDate[1].getTime()) {
                removeTasksFromSet = true;
              }
              break;
            case TasksConstants.ROUTE_MY_OVERDUE_TASKS:
              if (itemDate.getTime() >= TaskUtils.getCurrentDate().getTime()) {
                removeTasksFromSet = true;
              }
              break;
          }
        }
        break;
    } // end switch

    if (!removeTasksFromSet) {
      // TODO: probably need to update avatars on assignee changed only
      this.poppulateAssigneeAvatar(selectedTasks).subscribe((success) => {
        if (success) {
          const changes = selectedTasks.map(task => {
            return { id: task.id, changes: task };
          });
          this.store.dispatch(new UpdateTasksSuccess({ tasks: changes }));
        }
      });
    } else {
      this.store.dispatch(new RemoveTasksSuccess({ ids: tasksIds }));

      if (updateTasksFromSet) {
        if ( updateTasks && updateTasks.length > 0) {
          this.poppulateAssigneeAvatar(updateTasks).subscribe((success) => {
            if (success) {
              const changes = updateTasks.map(task => {
                return { id: task.id, changes: task };
              });
              this.store.dispatch(new UpdateTasksSuccess({ tasks: changes }));
            }
          });
        }
      }
    }

    // TODO: improve the following DB requests
    // do 1 req instead of for loop

    // here we have updated tasks objects
    // reflect changes to DB
    if (CommonUtil.isSQLSupported()) {
      if (fieldToUpdate === BulkUpdateIssueType.Status) {
        // update on DB
        if (valueToUpdate === TaskUtils.statusCompletedId()) {
          selectedTasks.forEach(task => {
            this.databaseService.completeTask(task).subscribe(res => {

            });
          });
        }
      } else if (fieldToUpdate === BulkUpdateIssueType.Priority) {
        selectedTasks.forEach(task => {
          this.databaseService.updatePriority(task, task.priority.id).subscribe(res => {

          });
        });
      } else if (fieldToUpdate === BulkUpdateIssueType.StartDate) {
        selectedTasks.forEach(task => {
          this.databaseService.updateStartDate(task, task.start_date).subscribe(res => {

          });
        });
      } else if (fieldToUpdate === BulkUpdateIssueType.DueDate) {
        selectedTasks.forEach(task => {
          this.databaseService.updateDueDate(task, task.due_date).subscribe(res => {

          });
        });
      } else if (fieldToUpdate === BulkUpdateIssueType.Project) {
        selectedTasks.forEach(task => {
          this.databaseService.updateProjectId(task, task.project.id).subscribe(res => {

          });
        });
      } else if (fieldToUpdate === BulkUpdateIssueType.Tags) {
        selectedTasks.forEach(task => {
          this.databaseService.updateTags(task, task.tags).subscribe(res => {

          });
        });
      } else if (fieldToUpdate === BulkUpdateIssueType.List) {
        this.databaseService.updateList(selectedTasks, valueToUpdate).subscribe(res => {

        });
      } else if (fieldToUpdate === BulkUpdateIssueType.Location) {
        this.databaseService.updateLocation(selectedTasks, valueToUpdate).subscribe(res => {

        });
      } else if (fieldToUpdate === BulkUpdateIssueType.AssignedTo) {
        selectedTasks.forEach(task => {
          this.databaseService.updateAssignedTo(task, task.assigned_to.id).subscribe(res => {

          });
        });
      } else if (fieldToUpdate === BulkUpdateIssueType.InviteTo) {
        selectedTasks.forEach(task => {
          this.databaseService.updateAssignedTo(task, 0).subscribe(res => {

          });
        });
      } else if (fieldToUpdate === BulkUpdateIssueType.Watchers) {
        selectedTasks.forEach(task => {
          this.databaseService.updateWatchers(task, valueToUpdate).subscribe(res => {

          });
        });
      }

      // add pendings
      if (!this.isOnline) {
        selectedTasks.forEach(task => {
          this.databaseService.addPendingTaskUpdate(task.id, null, null, watchers).subscribe(res => {

          });
        });
      }
    }

    // notify
    this.broadcaster.broadcast("sortTasksList");
    if (fieldToUpdate === BulkUpdateIssueType.Status) {
      if(selectedTasks?.length === 1) {
        const id = selectedTasks[0].id;
        this.successService.emit({ id: SuccessType.TaskBulkStatusUpdated, messages: this.msgs.TASK_UPDATED,taskId: id });
      }
      else this.successService.emit({ id: SuccessType.TaskBulkStatusUpdated, messages: this.msgs.TASK_UPDATED });
    } else if (fieldToUpdate === BulkUpdateIssueType.Watchers) {
      if(selectedTasks?.length === 1) {
        const id = selectedTasks[0].id;
        this.successService.emit({ id: SuccessType.TaskBulkWatcherUpdated, messages: this.msgs.TASK_UPDATED,taskId: id });
      }
      else this.successService.emit({ id: SuccessType.TaskBulkWatcherUpdated, messages: this.msgs.TASK_UPDATED });
    } else {
      if(selectedTasks?.length === 1) {
        const id = selectedTasks[0].id;
        this.successService.emit({ id: SuccessType.TaskBulkUpdated, messages: this.msgs.TASK_UPDATED,taskId: id });
      }
      else this.successService.emit({ id: SuccessType.TaskBulkUpdated, messages: this.msgs.TASK_UPDATED });
    }
  }

  updateBulkTasksRecurring(selectedTasks: Task[], type: BulkUpdateIssueType, item: BulkUpdateArgs) {
    this.store.dispatch(new UpdateTask());
    const selectedIds: number[] = selectedTasks.map(t => t.id);
    if (this.isOnline) {
      this.taskService.updateBulkTasksRecurring(selectedIds, type, item.value).pipe(take(1)).subscribe(res => {
        this.updateBulkTasksRecurringOnDB(selectedTasks, type, item);
      }, err => {
        this.store.dispatch(new UpdateTaskFail());
        this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
      });
    } else if (CommonUtil.isSQLSupported()) {
      this.updateBulkTasksRecurringOnDB(selectedTasks, type, item);
    } else if (!this.isOnline) {
      const id = Math.floor(Math.random() * 1000);
      const task = {
        id: id,
        body: {
          tasks: selectedIds,
          fieldToUpdate: type,
          valueToUpdate: item.value,
          operationType: BulkUpdateIssueType[type],
        }
      }
      this.databaseService.addPendingOperationTask([task]).subscribe(updatedTask => {
        this.updateBulkTasksRecurringOnDB(selectedTasks, type, item);
        this.store.dispatch(new SetEditTaskView(false));
        this.store.dispatch(new SetSelectAll(false));
        this.store.dispatch(new ResetSelectedTaskId());
        selectedTasks.forEach((elem) => {
          elem["repeat"] = item.value;
          elem.isChecked = false
          TaskRepository.taskStoreList.forEach((store) => {
            this.databaseService.getTaskById(elem.id, store).subscribe(t => {
              if (t) {
                this.databaseService.addBulkTasks([elem], store);
              }
            })
          })
        });
      })
    }
  }

  private updateBulkTasksRecurringOnDB(selectedTasks: Task[], type: BulkUpdateIssueType, item: BulkUpdateArgs) {
    selectedTasks.forEach(task => {
      task.repeat = item.value;
    });

    // reflect changes to DB
    if (CommonUtil.isSQLSupported()) {
      selectedTasks.forEach(task => {
        this.databaseService.updateRepeat(task, task.repeat).subscribe(res => {

        });
      });
    }

    const changes = selectedTasks.map(task => {
      return { id: task.id, changes: task };
    });
    this.store.dispatch(new UpdateTasksSuccess({ tasks: changes }));
    if (selectedTasks?.length === 1) this.successService.emit({ id: SuccessType.TaskBulkUpdated, messages: this.msgs.TASK_UPDATED ,taskId: selectedTasks[0].id});
    else this.successService.emit({ id: SuccessType.TaskBulkUpdated, messages: this.msgs.TASK_UPDATED});
  }

  navigateToTaskFromNotification(notifiTaskId, notifiTaskUpdatedOn, notifiType, appInBackground) {
    console.log("[TaskRepository][navigateToTaskFromNotification]", notifiTaskId, notifiTaskUpdatedOn, notifiType, appInBackground);

    let qp: any = { openedFromNotification: 1 };

    if (notifiType === TaskNotificationType.TASK_UPDATE || notifiType === TaskNotificationType.TASK_WATCHER ) {
      qp.taskUpdatedOn = notifiTaskUpdatedOn;
      this.store.dispatch(new SetTaskDetailHighlight(true));
    } else {
      this.store.dispatch(new SetTaskDetailHighlight(false));
    }

    if (notifiType === TaskNotificationType.OVERDUE_TASKS) {
      this.router.navigate(["/task/overdue"], {});
    } else if (notifiType === TaskNotificationType.TODAY_DUE_TASKS) {
      this.router.navigate(["/task/today"], {});
    } else {
      this.router.navigate(["/task/all/detail/" + notifiTaskId], { queryParams: qp });
    }
  }

  public getTaskDetails(id: number, taskUpdatedOn: Date = null): Observable<Task> {
    console.log("TaskRepository getTaskDetails: ", id, taskUpdatedOn);

    const response = new Subject<Task>();

    // Mobile app
    if (CommonUtil.isSQLSupported()) {
      this.store.select(IsDatabaseReady).pipe(filter(ready => !!ready), take(1)).subscribe(ready => {
        this.databaseService.getTask(id).subscribe(task => {
          if (task) {
            if (!taskUpdatedOn || task.updated_on.getTime() === taskUpdatedOn.getTime()) {
              response.next(task);
            } else {
              // this could happen when we click on push notification about task updated, but have old task data in SQLite
              // so need to sync with backend first
              if (this.isOnline) {
                this.taskService.getTaskDetails(id).subscribe(task => {
                  // need to clone task object,
                  // because it's mutable object and it made some issues with start/due date's fields in task details view
                  const clonedTask = JSON.parse(JSON.stringify(task));
                  this.databaseService.saveOrUpdateTasks([clonedTask]).subscribe(dbRes => {
                    response.next(task);
                  });
                }, err => {
                  response.next(null);
                });
              } else {
                response.next(task);
              }
            }
          } else {
            // this could happen when we click on push notification about new task assignment
            // so need to sync with backend first
            this.taskService.getTaskDetails(id).subscribe(task => {
              // need to clone task object,
              // because it's mutable object and it made some issues with start/due date's fields in task details view
              const clonedTask = JSON.parse(JSON.stringify(task));
              this.databaseService.addNewTask(clonedTask).subscribe(dbRes => {
                response.next(task);
              });
            }, err => {
              response.next(null);
            });
          }
        });
      });
    // Web client
    } else {
      if (this.isOnline) {
        this.taskService.getTaskDetails(id).subscribe(res => {
          response.next(res);
        }, err => {
          response.next(null);
        });
      }
      else {
        this.databaseService.getTaskDetailsById(id).subscribe(res => {
          response.next(res);
        }, err => {
          response.next(null);
        });
      }
    }

    return response.asObservable().pipe(take(1));
  }

  updateTaskDetail(oldTask: Task, newTask: Task, fileUploads: FileUpload[], watcherMembers: User[]) {
    console.log("TaskRepository updateTaskDetail: ", oldTask, newTask, fileUploads);

    let attachments = oldTask.attachments.filter( item =>
      !newTask.attachments.some( other => item.id === other.id)
    );
    this.deleteBulkAttachments(oldTask.id, attachments);

    this.updateTask(oldTask, newTask, fileUploads, watcherMembers);
  }

  private deleteBulkAttachments(taskId: number, attachments: TaskAttachment[]) {
    if (attachments.length === 0) {
      return;
    }

    let ids = [];
    attachments.forEach(attachment => {
      ids.push(attachment.id);
    });

    if (this.isOnline) {
      this.taskService.deleteBulkAttachments(ids).pipe(take(1)).subscribe( res => {
      }, err => {
        this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
      });
    } else if (CommonUtil.isSQLSupported()) {
      this.databaseService.addPendingAttachmentsBulkDelete(taskId, ids).subscribe(res => {

      });
    }
  }

  updateTask(oldTask: Task, newTask: Task, fileUploads: FileUpload[], watcherMembers: User[]): Observable<any> {
    this.store.dispatch(new UpdateTask());
    const response = new Subject<any>();
    let removedTagsIds: number[] = [];
    oldTask.tags.forEach(oldTag => {
      let tagRemoved = true;
      for (let newTag of newTask.tags) {
        if (oldTag.id === newTag.id) {
          tagRemoved = false;
          break;
        }
      }
      if (tagRemoved) {
        removedTagsIds.push(oldTag.id);
      }
    });

    // update on server
    if (this.isOnline) {
      this.taskService.updateTask(oldTask, newTask, fileUploads, watcherMembers).pipe(take(1)).subscribe(serverRes => {

        if (CommonUtil.isSQLSupported()) {
          if (fileUploads && fileUploads.length > 0) {
            // get the updated task details form server to get proper & complete attachment parameters
            this.taskService.getTaskDetails(oldTask.id).subscribe(updatedTaskFromServer => {
              // Set watcher members
              // updatedTaskFromServer.watchers = watcherMembers;
              // update on DB
              this.databaseService.updateTask(updatedTaskFromServer, removedTagsIds).subscribe((dbRes) => {
                this.updateTaskSuccess(oldTask, updatedTaskFromServer);
              });
            });
          } else {
            // Set watcher members
            // newTask.watchers = watcherMembers;
            // update on DB
            this.databaseService.updateTask(serverRes, removedTagsIds).subscribe((dbRes) => {
              this.updateTaskSuccess(oldTask, serverRes);
            });
          }
        } else {
          response.next("done");
          this.updateTaskSuccess(oldTask, serverRes);
          for (let store of TaskRepository.taskStoreList) {
            this.databaseService.getTaskById(oldTask.id, store).subscribe(t => {
              if (t) {
                newTask.isChecked = false;
                this.databaseService.addBulkTasks([newTask], store);
              }
            })
          }
        }
      }, err => {
        response.next("done");
        this.updateTaskError(oldTask, err);
      });

    // offline task update
    } else if (CommonUtil.isSQLSupported()) {
      // update on DB
      if (watcherMembers && watcherMembers.length > 0) {
        newTask.watchers = watcherMembers;
      }
      this.databaseService.updateTask(newTask, removedTagsIds).subscribe((dbRes) => {
        const fileUploadsValue = fileUploads && fileUploads.length > 0 ? fileUploads : null;
        const repeatValue = newTask.repeat !== "" ? newTask.repeat : null;
        const watcherMembersValue = watcherMembers && watcherMembers.length > 0 ? watcherMembers : null;
        this.databaseService.addPendingTaskUpdate(newTask.id, fileUploadsValue, repeatValue, watcherMembersValue).subscribe(res => {
          this.updateTaskSuccess(oldTask, newTask);
        });
      });
    }
    if (!this.isOnline) {
      newTask.isChecked = false;
      const task = [
        {
          id: oldTask.id,
          body: {
            oldTask: oldTask,
            newTask: newTask,
            fileUploads: fileUploads,
            watcherMembers: watcherMembers,
            operationType: "nonBulkUpdate"
          }
        }
      ]
      this.databaseService.addPendingOperationTask(task).subscribe(r => {
        let tasksStore;
        this.store.select(getTasks).subscribe(tasksFromStore => {
          tasksStore = tasksFromStore;
        })
        response.next("done");
        const index = tasksStore.findIndex(item => item.id === oldTask.id);
        tasksStore[index] = r;
        this.databaseService.addAllTasks([newTask]).subscribe(res => {
          this.loadTaskToDb(tasksStore).subscribe(taskFromDB => {
            this.updateTaskSuccess(oldTask, r);
            this.store.dispatch(new SetEditTaskView(false));
            this.store.dispatch(new SetSelectAll(false));
            this.store.dispatch(new ResetSelectedTaskId());
            if (oldTask?.tags?.length) {
              const tagList = oldTask?.tags.map(item => item.name);
              for (let tag of tagList) {
                this.databaseService.getTagsTasks(tag).subscribe(res => {
                  if (res) {
                    const taskIndex = res.findIndex(item => item.id === oldTask.id);
                    res[taskIndex] = newTask;
                    if (taskIndex > -1) {
                      this.databaseService.addTagsTasks(tag, res).subscribe(r => {
                      })
                    }
                  }
                })
              }
            }
            if (oldTask?.location) {
              const locationName = oldTask?.location?.name;
              if (locationName) {
                this.databaseService.getLocationTasks(locationName).subscribe(res => {
                  if (res) {
                    const taskIndex = res.findIndex(item => item.id === oldTask.id);
                    res[taskIndex] = newTask;
                    this.databaseService.addLocationTasks(locationName, res).subscribe(r => {
                    })
                  }
                })
              }
            }
            if (oldTask?.list) {
              const listName = oldTask?.list?.name;
              if (listName) {
                this.databaseService.getListTasks(listName).subscribe(res => {
                  if (res) {
                    const taskIndex = res.findIndex(item => item.id === oldTask.id);
                    if (taskIndex > -1) {
                      res[taskIndex] = newTask;
                      this.databaseService.addListTasks(listName, res).subscribe(r => {
                      })
                    }
                  }
                })
              }
            }
            let taskStats = [];
            this.store.select(getTaskStatisticsInfo).subscribe(info => {
              if (info) {
                taskStats = info;
              }
            });
            for (let store of TaskRepository.taskStoreList) {
              this.databaseService.getTaskById(oldTask.id, store).subscribe(t => {
                if (t) {
                  this.databaseService.addBulkTasks([newTask], store).subscribe(done => {
                    if (newTask.status.name !== oldTask.status.name) {
                      if (newTask.status.name.toLowerCase() == "completed" && store === "OpenTaskStore") {
                        this.databaseService.deleteBulkTasks(oldTask.id, store);
                        this.databaseService.addBulkTasks([newTask], "CompletedTaskStore");
                        for (let item of taskStats) {
                          if (item.type == "completed") {
                            item.info.completed_count = item.info.completed_count + 1;
                            item.info.total_count = item.info.total_count + 1;
                          }
                          if (item.type == "open") {
                            item.info.total_count = item.info.total_count - 1;
                          }
                        }
                        this.databaseService.addTaskStats(taskStats).subscribe(done => {
                          this.getTasksStats();
                        })
                      }
                      if (newTask.status.name.toLowerCase() == "in progress" && store === "CompletedTaskStore") {
                        this.databaseService.deleteBulkTasks(oldTask.id, store);
                        this.databaseService.addBulkTasks([newTask], "OpenTaskStore");
                        for (let item of taskStats) {
                          if (item.type == "completed") {
                            item.info.completed_count = item.info.completed_count - 1;
                            item.info.total_count = item.info.total_count - 1;
                          }
                          if (item.type == "open") {
                            item.info.total_count = item.info.total_count + 1;
                          }
                        }
                        this.databaseService.addTaskStats(taskStats).subscribe(done => {
                          this.getTasksStats();
                        })
                      }
                    }
                  })
                }
              });
            }
          })
        })
      })
    }
    return response.asObservable();
  }

  updateTaskSuccess(oldTask: Task, newTask: Task) {
    console.log("TaskRepository updateTaskSuccess");
    this.updateTaskIntoStore(oldTask, newTask);
    this.pushUpdateTaskSuccessMessage(oldTask?.id);
  }

  private updateTaskIntoStore(oldTask: Task, newTask: Task) {
    let routingOption;
    let routingType;
    let sortBy;
    let authUser: AuthUser = null;
    this.store.select(getAuthUser).pipe(take(1)).subscribe( user => authUser = user );
    this.store.select(getSortBy).pipe(take(1)).subscribe(o => sortBy = o);
    this.store.select(getSelectedFilterType).pipe(take(1)).subscribe(o => routingType = o);
    this.store.select(getSelectedFilterOption).pipe(take(1)).subscribe(o => routingOption = o);

    let newTasks = [newTask];

    this.poppulateAssigneeAvatar(newTasks).subscribe((success) => {
      if (success) {
        if (( authUser  && authUser.id !== newTask.author.id ) && ( newTask.assigned_to && authUser.id !== newTask.assigned_to.id) && ( routingOption !== TasksConstants.ROUTE_TASKS_I_WATCH)) {
          this.store.dispatch(new RemoveTasksSuccess({ ids: [newTasks[0].id] }));
        } else {
          if (routingType === TasksConstants.ROUTE_TYPE_STATUS) {
            switch (routingOption) {
              case TasksConstants.ROUTE_COMPLETED:
                if (newTask.status.id !== TaskUtils.statusCompletedId()) {
                  this.store.dispatch(new RemoveTasksSuccess({ ids: [newTasks[0].id] }));
                } else {
                  this.store.dispatch(new UpdateTaskSuccess({ task: { id: newTasks[0].id, changes: newTasks[0] } }));
                }
                break;
              case TasksConstants.ROUTE_OPEN:
                if (newTask.status.id === TaskUtils.statusCompletedId()) {
                  this.store.dispatch(new RemoveTasksSuccess({ ids: [newTasks[0].id] }));
                } else {
                  this.store.dispatch(new UpdateTaskSuccess({ task: { id: newTasks[0].id, changes: newTasks[0] } }));
                }
                break;
              case TasksConstants.ROUTE_NEW:
                if (newTask.status.id !== TaskUtils.statusNewId()) {
                  this.store.dispatch(new RemoveTasksSuccess({ ids: [newTasks[0].id] }));
                } else {
                  this.store.dispatch(new UpdateTaskSuccess({ task: { id: newTasks[0].id, changes: newTasks[0] } }));
                }
                break;
              case TasksConstants.ROUTE_SEARCH:
                let searchParams = this.getSearchQuery();
                if (searchParams["v[subject][]"] && newTask.subject.toLowerCase().indexOf(searchParams["v[subject][]"][0].toLowerCase()) === -1) {
                  this.store.dispatch(new RemoveTasksSuccess({ ids: [newTasks[0].id] }));
                } else {
                  this.store.dispatch(new UpdateTaskSuccess({ task: { id: newTasks[0].id, changes: newTasks[0] } }));
                }
                break;
              case TasksConstants.ROUTE_ASSIGNEDTOME:
                if (oldTask.assigned_to.id !== newTask.assigned_to.id || ( authUser  && authUser.id === newTask.author.id )) {
                  this.store.dispatch(new RemoveTasksSuccess({ ids: [newTasks[0].id] }));
                } else {
                  this.store.dispatch(new UpdateTaskSuccess({ task: { id: newTasks[0].id, changes: newTasks[0] } }));
                }
                break;
              case TasksConstants.ROUTE_TODAY_DUE:
                if (newTask.due_date) {
                  let taskDate = newTask.due_date;
                  taskDate.setHours(0, 0, 0, 0);
                  if (taskDate.getTime() === TaskUtils.getCurrentDate().getTime()) {
                    this.store.dispatch(new UpdateTaskSuccess({ task: { id: newTasks[0].id, changes: newTasks[0] } }));
                  } else {
                    this.store.dispatch(new RemoveTasksSuccess({ ids: [newTasks[0].id] }));
                  }
                } else {
                  this.store.dispatch(new RemoveTasksSuccess({ ids: [newTasks[0].id] }));
                }
                break;
              case TasksConstants.ROUTE_TOMORROW_DUE:
                if (newTask.due_date) {
                  let taskDate = newTask.due_date;
                  taskDate.setHours(0, 0, 0, 0);
                  if (taskDate.getTime() === TaskUtils.getTomorrowDate().getTime()) {
                    this.store.dispatch(new UpdateTaskSuccess({ task: { id: newTasks[0].id, changes: newTasks[0] } }));
                  } else {
                    this.store.dispatch(new RemoveTasksSuccess({ ids: [newTasks[0].id] }));
                  }
                } else {
                  this.store.dispatch(new RemoveTasksSuccess({ ids: [newTasks[0].id] }));
                }
                break;
              case TasksConstants.ROUTE_THIS_WEEK_DUE:
                if (newTask.due_date) {
                  let taskDate = newTask.due_date;
                  taskDate.setHours(0, 0, 0, 0);
                  let weekDate = TaskUtils.getstartAndEndOfWeek();
                  if (taskDate.getTime() >= weekDate[0].getTime() && taskDate.getTime() <= weekDate[1].getTime()) {
                    this.store.dispatch(new UpdateTaskSuccess({ task: { id: newTasks[0].id, changes: newTasks[0] } }));
                  } else {
                    this.store.dispatch(new RemoveTasksSuccess({ ids: [newTasks[0].id] }));
                  }
                } else {
                  this.store.dispatch(new RemoveTasksSuccess({ ids: [newTasks[0].id] }));
                }
                break;
              case TasksConstants.ROUTE_TASKS_I_WATCH:
                if (newTask.watchers) {
                  let watcher = newTask.watchers.find( watcher => watcher.id === authUser.id );
                  if (watcher) {
                    this.store.dispatch(new UpdateTaskSuccess({ task: { id: newTasks[0].id, changes: newTasks[0] } }));
                  } else {
                    this.store.dispatch(new RemoveTasksSuccess({ ids: [newTasks[0].id] }));
                  }
                } else {
                  this.store.dispatch(new RemoveTasksSuccess({ ids: [newTasks[0].id] }));
                }
                break;
              case TasksConstants.ROUTE_MY_OVERDUE_TASKS:
                if (newTask.due_date) {
                  let taskDate = newTask.due_date;
                  taskDate.setHours(0, 0, 0, 0);
                  if ((taskDate.getTime() < TaskUtils.getCurrentDate().getTime()) && ( newTask.status.id !== TaskUtils.statusCompletedId())) {
                    this.store.dispatch(new UpdateTaskSuccess({ task: { id: newTasks[0].id, changes: newTasks[0] } }));
                  } else {
                    this.store.dispatch(new RemoveTasksSuccess({ ids: [newTasks[0].id] }));
                  }
                } else {
                  this.store.dispatch(new RemoveTasksSuccess({ ids: [newTasks[0].id] }));
                }
                break;
              default:
                this.store.dispatch(new UpdateTaskSuccess({ task: { id: newTasks[0].id, changes: newTasks[0] } }));
                break;
            } // end switch

            if (!_.isEqual(newTask.tags, oldTask.tags)) {
              this.getTagsListWithCounters();
            }
            if (!_.isEqual(newTask.list, oldTask.list)) {
              this.getFolderListWithCounters();
            }
            if (!_.isEqual(newTask.location, oldTask.location)) {
              this.getLocationsWithCounters();
            }

          } else if (routingType === TasksConstants.ROUTE_TYPE_TAG) {
            if (newTask.tags) {
              if (_.isEqual(newTask.tags, oldTask.tags)) {
                this.store.dispatch(new UpdateTaskSuccess({ task: { id: newTasks[0].id, changes: newTasks[0] } }));
              } else {
                let tag = newTask.tags.find( tag => tag.name === routingOption);
                if (tag) {
                  this.store.dispatch(new UpdateTaskSuccess({ task: { id: newTasks[0].id, changes: newTasks[0] } }));
                } else {
                  this.store.dispatch(new RemoveTasksSuccess({ ids: [newTasks[0].id] }));
                }
                this.getTagsListWithCounters();
              }
            } else {
              this.store.dispatch(new RemoveTasksSuccess({ ids: [newTasks[0].id] }));
            }
          } else if ( routingType === TasksConstants.ROUTE_TYPE_LIST) {
            if (newTask.list && newTask.list.id === oldTask.list.id ) {
              this.store.dispatch(new UpdateTaskSuccess({ task: { id: newTasks[0].id, changes: newTasks[0] } }));
            } else {
              this.store.dispatch(new RemoveTasksSuccess({ ids: [newTasks[0].id] }));
            }
          } else if ( routingType === TasksConstants.ROUTE_TYPE_LOCATION) {
            if (newTask.location && newTask.location.id === oldTask.location.id ) {
              this.store.dispatch(new UpdateTaskSuccess({ task: { id: newTasks[0].id, changes: newTasks[0] } }));
            } else {
              this.store.dispatch(new RemoveTasksSuccess({ ids: [newTasks[0].id] }));
            }
          }
        }
      }
    });
  }

  private pushUpdateTaskSuccessMessage(taskId?) {
    this.successService.emit({ id: SuccessType.TaskUpdated, messages: this.msgs.TASK_UPDATED ,taskId:taskId});
  }

  private updateTaskError(oldTask, err) {
    console.log("TaskRepository updateTaskError", err);

    this.store.dispatch(new UpdateTaskFail());
    if ( err && err.includes(TasksConstants.FOUND_UPDATE_TASK_CONFLICT) ) {
      this.errorService.emit({ id: ErrorType.TaskUpdateFail, messages: this.msgs.FOUND_UPDATE_TASK_CONFLICT_ERROR });
    } else if ( err && err.includes(this.msgs.RESOURCE_NOT_FOUND)) {
      this.store.dispatch(new RemoveTasksSuccess({ ids: [oldTask.id] }));
      this.errorService.emit({ id: ErrorType.TaskUpdateFail, messages: this.msgs.TASK_HAS_BEEN_DELETED });
    } else {
      this.errorService.emit({ id: ErrorType.TaskUpdateFail, messages: err });
    }
  }

  addComment(task: Task, notes: string): Observable<any> {
    console.log("[TaskRepository] addComment. task & notes: ", task, notes);

    const response = new Subject<any>();

    this.store.dispatch(new SetIsLoading(true));

    if (!this.isOnline && CommonUtil.isSQLSupported()) {
      let taskComment = TaskComment.commentCreateoffline(notes, { id: this.authUser.id, name: this.authUser.fullname });
      this.databaseService.addPendingTaskCommentCreate(task.id, taskComment).pipe(finalize(() =>
        this.store.dispatch(new SetIsLoading(false))
      )).subscribe( res => {
        taskComment.notes = taskComment.notes.replace(/<br>/g, "\n");
        taskComment.notes = taskComment.notes.replace(/(?:\r\n|\r|\n)/g, "<br>");
        taskComment.notes = this.renderMentionUsers(taskComment.notes, this.memberList);
        taskComment.notes = CommonUtil.linkify(taskComment.notes);
        task.comments.push(taskComment);
        // add on DB
        if (CommonUtil.isSQLSupported()) {
          this.databaseService.updateComments(task).subscribe(res => {
            response.next(res);
          });
        } else {
          response.next(res);
        }
      });
    } else {
      // add on server
      this.taskService.addCommentFor(task.id, notes).pipe(finalize(() =>
        this.store.dispatch(new SetIsLoading(false))
      )).subscribe(res => {
        console.log("[TaskRepository] addComment res success", res);

        let comment = new TaskComment({
          id: res.id,
          notes: res.notes,
          user: res.user,
          created_on: new Date(res.created_on)
        });
        comment.notes = comment.notes.replace(/<br>/g, "\n");
        comment.notes = comment.notes.replace(/(?:\r\n|\r|\n)/g, "<br>");
        comment.notes = this.renderMentionUsers(comment.notes, this.memberList);
        comment.notes = CommonUtil.linkify(comment.notes);
        task.comments.push(comment);
        task.lock_version = res.lock_version;
        // add on DB
        if (CommonUtil.isSQLSupported()) {
          this.databaseService.updateComments(task).subscribe(res => {
            response.next(res);
          });
        } else {
          response.next(res);
        }
      }, err => {
        console.log("[TaskRepository] addComment error", err);

        this.store.dispatch(new UpdateTaskFail());
        if ( err && err.includes(TasksConstants.MUST_BE_GREATER_THEN) ) {
          this.errorService.emit({ id: ErrorType.GenericMessage, messages: this.msgs.START_DATE_CHANGE_ERROR_MESSAGE });
        } else {
          this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
        }
        response.next(false);
      });
    }
    return response.asObservable().pipe(take(1));
  }

  editComment(task: Task, currentComment: TaskComment, notes: string): Observable<any> {
    console.log("[TaskRepository] editComment. task & currentComment & notes: ", task, currentComment, notes);

    const response = new Subject<any>();

    this.store.dispatch(new SetIsLoading(true));

    let newComment = new TaskComment({ id: currentComment.id, user: currentComment.user, notes: notes, created_on: currentComment.created_on });

    if (!this.isOnline && CommonUtil.isSQLSupported()) {
      this.databaseService.addPendingTaskCommentUpdate(task.id, newComment).pipe(finalize(() =>
        this.store.dispatch(new SetIsLoading(false))
      )).subscribe( res => {
        currentComment.notes = newComment.notes.replace(/<br>/g, "\n");
        currentComment.notes = currentComment.notes.replace(/(?:\r\n|\r|\n)/g, "<br>");
        currentComment.notes = this.renderMentionUsers(currentComment.notes, this.memberList);
        currentComment.notes = CommonUtil.linkify(currentComment.notes);
        currentComment.isEditMode = false;
        currentComment.isEdited = true;
        // add on DB
        if (CommonUtil.isSQLSupported()) {
          this.databaseService.updateComments(task).subscribe(res => {
            response.next(res);
          });
        } else {
          response.next(res);
        }
      });
    } else {
      this.taskService.editCommentFor(newComment).pipe(finalize(() =>
        this.store.dispatch(new SetIsLoading(false))
      )).subscribe(res => {
        console.log("[TaskRepository] editComment res success", res);
        currentComment.notes = newComment.notes.replace(/<br>/g, "\n");
        currentComment.notes = currentComment.notes.replace(/(?:\r\n|\r|\n)/g, "<br>");
        currentComment.notes = this.renderMentionUsers(currentComment.notes, this.memberList);
        currentComment.notes = CommonUtil.linkify(currentComment.notes);
        currentComment.isEditMode = false;
        currentComment.isEdited = true;

        // add on DB
        if (CommonUtil.isSQLSupported()) {
          this.databaseService.updateComments(task).subscribe(res => {
            response.next(res);
          });
        } else {
          response.next(res);
        }
      }, err => {
        console.log("[TaskRepository] editComment error", err);
        this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
        response.next(false);
      });
    }
    return response.asObservable().pipe(take(1));
  }

  downloadFile(attachment: TaskAttachment): void{
    console.log("[TaskRepository] downloadFile");

    if (environment.isCordova) {
      const message = this.messageTranslatorService.getMessage(TasksConstants.DOWNLOADING_STARTED);
      this.vncLibaryService.openSnackBar(message, "","", "", 3000, "bottom", "left").subscribe(res => {});
    }
    this.taskService.downloadFileAsBlob(attachment).subscribe(blob => {
      this.downloadFileFromBlob(blob, attachment.filename);
    }, err => {
      console.log("[TaskRepository] downloadFile err", err);
    });
  }

  downloadFileFromBlob(blob: Blob, filename: string) {
    if (CommonUtil.isOnNativeMobileDevice()) {
      this.downloadFileFromCordova(blob, filename)
        .subscribe(res => {
          /* cordova.plugins.notification.local.schedule({
            id: new Date().getTime(),
            title: filename,
            text: this.messageTranslatorService.getMessage(TasksConstants.DOWNLOAD_SUCCESS)
          }); */
        });
    } else {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.setAttribute("style", "display: none");
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }
  }

  private downloadFileFromCordova(blob: Blob, fileName: string): any {
    const response = new Subject<string>();

    if (CommonUtil.isOnIOS()) {
      // save in hidden cache
      this.filesStorageService.saveBlobToDisc(blob, fileName, false).subscribe((localFileUrl)  => {
        // for iOS we need to additionaly save to Camera Roll
        if (CommonUtil.getMediaType(fileName) === this.MediaType.IMAGE) {
          cordova.plugins.imagesaver.saveImageToGallery(localFileUrl, () => {
            response.next(localFileUrl);
          }, (err) => {
            console.log("[TaskRepository] downloadImageFileFromCordova err", err);
          });
        }
        if (CommonUtil.getMediaType(fileName) === this.MediaType.VIDEOS) {
          cordova.plugins.saveVideoToGallery(localFileUrl, () => {
            response.next(localFileUrl);
          }, (err) => {
            console.log("[TaskRepository] downloadVideoFileFromCordova err3", err);
          });
        }
      }, err => {
        response.error(err);
      });
    } else {
      // save in hidden cache
      this.filesStorageService.saveBlobToDisc(blob, fileName).subscribe((localFileUrl)  => {
        // now save into Download folder
        this.filesStorageService.saveBlobToAndroidDownloadFolder(blob, fileName).subscribe((localFileUrl)  => {
          response.next(localFileUrl);
        }, err => {
          response.error(err);
        });
      }, err => {
        response.error(err);
      });
    }

    return response.asObservable().pipe(take(1));
  }

  getAttachmentContent(attachment): Observable<any> {
    return this.taskService.getAttachmentContent(attachment);
  }

  public reloadApp() {
    if (this.isOnline && CommonUtil.isSQLSupported()) {
      this.syncTasksWithBackend();
    } else {
      this.getTasks();
    }
    this.getTasksStats();
    this.getTagsListWithCounters();
    this.getFolderListWithCounters();
    this.getLocationsWithCounters();
  }

  private processPendingOperations() {
    let pendingOperationsFinishedNumber = 0;
    let pendingCommentOperationItr = 0;
    this.databaseService.getAllPendingOperations().pipe(take(1)).subscribe((ops) => {
      ops.forEach(op => {
        // pending CREATE
        if (op.type === PendingOperation.PENDING_OPERATION_TYPE_TASK_CREATE) {
          console.log("[TaskRepository] pending CREATE", op.task_id);

          this.databaseService.getTask(op.task_id).subscribe((taskToCreate) => {
            if (taskToCreate) {
              console.log("[TaskRepository] pending task found (CREATE)", taskToCreate);

              let fileUploads, repeat;
              if (op.additionalData) {
                fileUploads = op.additionalData["fileUploads"];
                repeat = op.additionalData["repeat"];
                if (repeat) {
                  taskToCreate.repeat = repeat;
                }
              }

              const payload = taskToCreate.toCreatePayload();
              this.taskService.addNewTask(payload).subscribe(taskFromServer => {

                // e.g if we created a task in offline and then completed it
                const isStatusChanged = taskToCreate.status.id !== TaskUtils.statusNewId();
                if (isStatusChanged) {
                  taskFromServer.status = taskToCreate.status;

                  this.taskService.updateTask(null, taskFromServer, null, null).pipe(take(1)).subscribe(serverRes => {
                    console.log("[TaskRepository] pending CREATE, updateTask status done");
                  }, err => {
                    console.error("[TaskRepository] pending CREATE, updateTask status error", err);
                  });
                }

                // a notification to replace a task id in redux
                this.broadcaster.broadcast("replaceTaskId", {"old_id": op.task_id, "new_id": taskFromServer.id});
                this.store.dispatch(new RemoveTasksSuccess({ ids : [op.task_id] }));

                // this.store.dispatch(new CreateTaskSuccess({ task: taskFromServer }));

                // all is good at server side, now replace at DB because we have new ID and cleanup pendings
                this.databaseService.replaceTask(op.task_id, taskFromServer).subscribe(res => {

                  this.databaseService.deletePendingOperation(op.task_id).subscribe(success => {
                    if (success) {
                      console.log("[TaskRepository] pending task cleanup (CREATE) success");
                      ++pendingOperationsFinishedNumber;
                      if (pendingOperationsFinishedNumber === ops.length){
                        this.doAfterPendingFinished();
                      }
                    } else {
                      console.error("[TaskRepository] pending task cleanup (CREATE) error");
                    }
                  });
                });
              }, err => {
                console.error("[TaskRepository] pending addNewTask error", err);
              });
            } else {
              // not found
              this.databaseService.deletePendingOperation(op.task_id).subscribe(success => {
                if (success) {
                  console.log("[TaskRepository] pending task cleanup (CREATE) success");
                  ++pendingOperationsFinishedNumber;
                  if (pendingOperationsFinishedNumber === ops.length){
                    this.doAfterPendingFinished();
                  }
                } else {
                  console.error("[TaskRepository] pending task cleanup (CREATE) error");
                }
              });
            }
          });

        // pending DELETE
        } else if (op.type === PendingOperation.PENDING_OPERATION_TYPE_TASK_DELETE) {
          console.log("[TaskRepository] pending DELETE", op.task_id);

          this.taskService.removeTasks([op.task_id]).pipe(take(1)).subscribe(res => {
            this.databaseService.deletePendingOperation(op.task_id).pipe(take(1)).subscribe(success => {
              if (success) {
                ++pendingOperationsFinishedNumber;
                if (pendingOperationsFinishedNumber === ops.length){
                  this.doAfterPendingFinished();
                }
                console.log("[TaskRepository] pending task cleanup (DELETE) success");
              } else {
                console.error("[TaskRepository] pending task cleanup (DELETE) error");
              }
            });
          }, err => {
            if (err === "Resource Not Found") {
              this.databaseService.deletePendingOperation(op.task_id).pipe(take(1)).subscribe(success => {
                if (success) {
                  ++pendingOperationsFinishedNumber;
                  if (pendingOperationsFinishedNumber === ops.length){
                    this.doAfterPendingFinished();
                  }
                  console.log("[TaskRepository] pending task cleanup (DELETE) success");
                } else {
                  console.error("[TaskRepository] pending task cleanup (DELETE) error");
                }
              });
            } else {
              console.error("[TaskRepository] processPendingOperations, removeTasks error", err);
            }
          });

        // pending DELETE BulkAttachments
        } else if (op.type === PendingOperation.PENDING_OPERATION_TYPE_ATTACHMENTS_BULK_DELETE) {
          console.log("[TaskRepository] pending ATTACHMENTS_BULK_DELETE", op.task_id);

          const attachmentsIds = op.additionalData["attachmentsIds"];

          this.taskService.deleteBulkAttachments(attachmentsIds).pipe(take(1)).subscribe( res => {
            this.databaseService.deletePendingOperation(op.task_id).pipe(take(1)).subscribe(success => {
              if (success) {
                ++pendingOperationsFinishedNumber;
                if (pendingOperationsFinishedNumber === ops.length){
                  this.doAfterPendingFinished();
                }
                console.log("[TaskRepository] pending task cleanup (ATTACHMENTS_BULK_DELETE) success");
              } else {
                console.error("[TaskRepository] pending task cleanup (ATTACHMENTS_BULK_DELETE) error");
              }
            });
          }, err => {
            console.error("[TaskRepository] processPendingOperations, deleteBulkAttachments error", err);
          });

        // pending UPDATE
        } else if (op.type === PendingOperation.PENDING_OPERATION_TYPE_TASK_UPDATE) {
          console.log("[TaskRepository] pending UPDATE", op.task_id);

          this.databaseService.getTask(op.task_id).pipe(take(1)).subscribe((taskToUpdate) => {
            if (taskToUpdate) {
              console.log("[TaskRepository] pending task found (UPDATE)", taskToUpdate);
              let fileUploads, repeat, watcherMembers;
              if (op.additionalData) {
                console.log("[TaskRepository] pending task found (UPDATE) additionalData ", op.additionalData);
                fileUploads = op.additionalData["fileUploads"];
                repeat = op.additionalData["repeat"];
                watcherMembers = op.additionalData["watcherMembers"];
              }

              // const tempTagsIds = taskToUpdate.tags.map(t => t.id > Tag.tempIdMin());

              this.taskService.updateTask(null, taskToUpdate, fileUploads, watcherMembers).pipe(take(1)).subscribe(serverRes => {

                // if (fileUploads && fileUploads.length > 0) {
                  // get the updated task details form server to get proper & complete attachment parameters
                  this.taskService.getTaskDetails(op.task_id).pipe(take(1)).subscribe(updatedTaskFromServer => {
                    // update on DB
                    this.databaseService.saveOrUpdateTasks([updatedTaskFromServer]).pipe(take(1)).subscribe((dbRes) => {
                      this.updateTaskSuccess(taskToUpdate, updatedTaskFromServer);
                    });
                  });
                // }

                this.databaseService.deletePendingOperation(op.task_id).pipe(take(1)).subscribe(success => {
                  if (success) {
                    ++pendingOperationsFinishedNumber;
                    if (pendingOperationsFinishedNumber === ops.length){
                      this.doAfterPendingFinished();
                    }
                    console.log("[TaskRepository] pending task cleanup (UPDATE) success");
                  } else {
                    console.error("[TaskRepository] pending task cleanup (UPDATE) error");
                  }
                });

              }, err => {
                console.error("[TaskRepository] pending updateTask error", err);
              });
            } else {
              console.error("[TaskRepository] pending task not found", op.task_id);
            }
          });
        // add pending comment
        } else if (op.type === PendingOperation.PENDING_OPERATION_TYPE_COMMENT_ADD) {
          console.log("[TaskRepository] pending COMMENT ADD", op.task_id);
          pendingCommentOperationItr += 1;

          this.databaseService.getTask(op.additionalData.task_id).subscribe((taskToUpdate) => {
            if (taskToUpdate) {
              console.log("[TaskRepository] pending task found (UPDATE)", taskToUpdate);
              let taskComment: TaskComment;
              if (op.additionalData) {
                taskComment = new TaskComment(op.additionalData);
              }

              setTimeout(() => {
                this.taskService.addCommentFor(op.additionalData.task_id, taskComment.notes).pipe(take(1)).subscribe( res => {

                  taskToUpdate.comments.filter( comment => comment.id === taskComment.id).map( comment => {
                    comment.id = res.id;
                    comment.user = res.user;
                    comment.created_on = new Date(res.created_on);
                  });
                  taskToUpdate.lock_version = res.lock_version;

                  this.databaseService.saveOrUpdateTasks([taskToUpdate]).pipe(take(1)).subscribe((dbRes) => {
                  });
                },
                err => {
                  console.error("[TaskRepository] pending add comment error", err);
                });

                this.databaseService.deletePendingOperation(op.task_id).subscribe(success => {
                  if (success) {
                    ++pendingOperationsFinishedNumber;
                    if (pendingOperationsFinishedNumber === ops.length){
                      this.doAfterPendingFinished();
                    }
                    console.log("[TaskRepository] pending task cleanup (COMMENT) success");
                  } else {
                    console.error("[TaskRepository] pending task cleanup (COMMENT) error");
                  }
                });
              }, (pendingCommentOperationItr - 1) * 300);
            }
          });
        // update pending comment
        } else if (op.type === PendingOperation.PENDING_OPERATION_TYPE_COMMENT_EDIT) {
          console.log("[TaskRepository] pending COMMENT UPDATE", op.task_id);

          this.databaseService.getTask(op.additionalData.task_id).subscribe((taskToUpdate) => {
            if (taskToUpdate) {
              console.log("[TaskRepository] pending task found (UPDATE)", taskToUpdate);
              let taskComment: TaskComment;
              if (op.additionalData) {
                taskComment = new TaskComment(op.additionalData);
              }

              this.taskService.editCommentFor(taskComment).pipe(take(1)).subscribe( res => {
              }, err => {
                console.error("[TaskRepository] pending update comment error", err);
              });

              this.databaseService.deletePendingOperation(op.task_id).subscribe(success => {
                if (success) {
                  ++pendingOperationsFinishedNumber;
                  if (pendingOperationsFinishedNumber === ops.length){
                    this.doAfterPendingFinished();
                  }
                  console.log("[TaskRepository] pending task cleanup (COMMENT) success");
                } else {
                  console.error("[TaskRepository] pending task cleanup (COMMENT) error");
                }
              });
            }
          });
        }
      });
    });
  }

  private doAfterPendingFinished() {
    console.log("[TaskRepository] doAfterPendingFinished");
    this.getTasksStats();
    this.getFolderListWithCounters();
    this.getLocationsWithCounters();
    this.getTagsListWithCounters();
  }

  public userRegistration(body: any) {
    this.taskService.userRegisration(body).subscribe( res => {
      this.successService.emit({ id: SuccessType.UserRegistered, messages: "" });
    }, err => {
      if ( err && (err === "Erlaubnis abgelehnt" || err === "Permission declined")) {
        this.errorService.emit({ id: ErrorType.UserRegisteredError, messages: this.msgs.NOT_ALLOWED_REGISTER });
      } else {
        this.errorService.emit({ id: ErrorType.UserRegisteredError, messages: this.msgs.NEW_REGISTER_ERROR_MESSAGE });
      }
    });
  }

  public resendActivationLink(mail: string) {
    this.taskService.resendActivationLink(mail).subscribe( res => {
      this.successService.emit({ id: SuccessType.ResendActivationLinkSuccess, messages: this.msgs.ACTIVATION_LINK_SEND_SUCCESS });
    }, err => {
      this.errorService.emit({ id: ErrorType.ResendActivationLinkError, messages: err });
    });
  }

  public resetPassword(token: string, password: string, confirmPassword: string) {
    this.taskService.resetPassword(token, password, confirmPassword).subscribe( res => {
      this.successService.emit({ id: SuccessType.ResetPasswordSuccess, messages: this.msgs.RESET_PASSWORD_SUCCESS });
    }, err => {
      this.errorService.emit({ id: ErrorType.ResetPasswordError, messages: this.msgs.RESET_PASSWORD_ERROR });
    });
  }

  public forgotPassword(mail: string) {
    this.taskService.forgotPassword(mail).subscribe( res => {
      if ( res && res.includes(TasksConstants.UNKNOWN)) {
        this.errorService.emit({ id: ErrorType.ForgotPasswordError, messages: this.msgs.ACCOUNT_NOT_FOUND_WITH_EMAIL });
      } else {
        this.successService.emit({ id: SuccessType.ForgotPasswordSuccess, messages: this.msgs.FORGOT_PASSWORD_SEND_SUCCESS });
      }
    }, err => {
      if ( err && err.includes(TasksConstants.HAVE_NOT_ACTIVATED) ) {
        this.errorService.emit({ id: ErrorType.ForgotPasswordError, messages: this.msgs.HAVE_NOT_ACTIVATED_MSG });
      } else if ( err && err.includes(TasksConstants.LOCKED) ) {
        this.errorService.emit({ id: ErrorType.ForgotPasswordError, messages: this.msgs.ACCOUNT_LOCKED_MSG });
      } else {
        this.errorService.emit({ id: ErrorType.ForgotPasswordError, messages: this.msgs.ACCOUNT_NOT_FOUND_WITH_EMAIL });
      }
    });
  }

  public changePassword(password: string, new_password: string, new_password_confirmation: string) {
    this.taskService.changePassword(password, new_password, new_password_confirmation).subscribe( res => {
      this.successService.emit({ id: SuccessType.ChangePasswordSuccess, messages: this.msgs.CHANGE_PASSWORD_SUCCESS });
    }, err => {
      if (err && err.includes("Wrong current password")) {
        this.errorService.emit({ id: ErrorType.ChangePasswordError, messages: this.msgs.WRONG_CURRENT_PWD_ERROR });
      } else {
        this.errorService.emit({ id: ErrorType.ChangePasswordError, messages: this.msgs.CHANGE_PASSWORD_ERROR });
      }
    });
  }

  public deleteAccount(feedback: string, password: string) {
    this.taskService.deleteAccount(feedback, password).subscribe( res => {
      this.successService.emit({ id: SuccessType.DeleteAccountSuccess, messages: this.msgs.DEACTIVATE_ACCOUNT_SUCCESS });
    }, err => {
      if (err && err.includes("Wrong password")) {
        this.errorService.emit({ id: ErrorType.DeleteAccountError, messages: this.msgs.WRONG_CURRENT_PWD_ERROR });
      } else {
        this.errorService.emit({ id: ErrorType.DeleteAccountError, messages: err });
      }
    });
  }

  public removeLoginIframe() {
    if (document.querySelector("#loginIframe") !== null) {
      document.querySelector("#loginIframe").remove();
    }
    if (document.querySelector("#logoutIframe") !== null) {
      document.querySelector("#logoutIframe").remove();
    }
  }

  public errorSubjectRequired() {
    this.errorService.emit({ id: ErrorType.GenericMessage, messages: this.msgs.TASK_SUBJECT_REQUIRED });
  }

  public subjectNotAllowed() {
    this.errorService.emit({ id: ErrorType.GenericMessage, messages: this.msgs.TASK_SUBJECT_NOT_ALLOWED });
  }

  public subjectTooLong() {
    this.errorService.emit({ id: ErrorType.GenericMessage, messages: this.msgs.SUBJECT_IS_TOO_LONG });
  }

  public errorCommentRequired() {
    this.errorService.emit({ id: ErrorType.GenericMessage, messages: this.msgs.ENTER_A_COMMENT });
  }

  public errorProjectLinkTaskRequired() {
    this.errorService.emit({ id: ErrorType.GenericMessage, messages: this.msgs.PROJECT_LINKED_TASK_REQUIRED });
  }

  public errorEstimateIsInvalid() {
    this.errorService.emit({ id: ErrorType.GenericMessage, messages: this.msgs.TASK_ESTIMATE_IS_INVALID });
  }

  public errorEstimateMinuteIsInvalid() {
    this.errorService.emit({ id: ErrorType.GenericMessage, messages: this.msgs.TASK_ESTIMATE_MINUTE_IS_INVALID });
  }

  public errorProjectRequired() {
    this.errorService.emit({ id: ErrorType.GenericMessage, messages: this.msgs.PROJECT_REQUIRED });
  }

  public errorDueDateInvalid() {
    this.errorService.emit({ id: ErrorType.GenericMessage, messages: this.msgs.TASK_DUEDATE_IS_INVALID });
  }

  public getDateFilterItems(type: DateFilterType): any {
    let msg_no_date;
    switch (type) {
      case DateFilterType.StartDate:
        msg_no_date = this.msgs.NO_STARTDATE;
        break;
      case DateFilterType.DueDate:
        msg_no_date = this.msgs.NO_DUEDATE;
        break;
    }
    return [
      {
        date: TaskUtils.getDateAfterOffset(0),
        name: this.msgs.TODAY_DUE
      },
      {
        date: TaskUtils.getDateAfterOffset(1),
        name: this.msgs.TOMORROW_DUE
      },
      {
        date: TaskUtils.getDateAfterOffset(2),
        name: this.msgs[TaskUtils.getWeekDay(TaskUtils.getDateAfterOffset(2).getDay())]
      },
      {
        date: TaskUtils.getDateAfterOffset(3),
        name: this.msgs[TaskUtils.getWeekDay(TaskUtils.getDateAfterOffset(3).getDay())]
      },
      {
        date: null,
        name: msg_no_date
      }
    ];
  }

  private getPriorityTranslation(option: string): string {
    let translation;
    switch (option) {
      case TasksConstants.LOW:
        translation = this.msgs.LOW;
        break;
      case TasksConstants.NORMAL:
        translation = this.msgs.NORMAL;
        break;
      case TasksConstants.HIGH:
        translation = this.msgs.HIGH;
        break;
      case TasksConstants.URGENT:
        translation = this.msgs.URGENT;
        break;
      case TasksConstants.IMMEDIATE:
        translation = this.msgs.IMMEDIATE;
        break;
      default:
        translation = option;
        break;
    }
    return translation;
  }

  public getRepeatItems(): any {
    return [
      {
        value: "d",
        name: this.msgs.TASK_EVERY_DAY
      },
      {
        value: "w",
        name: this.msgs.TASK_EVERY_WEEK
      },
      {
        value: "m",
        name: this.msgs.TASK_EVERY_MONTH
      },
      {
        value: "y",
        name: this.msgs.TASK_EVERY_YEAR
      },
      {
        value: "n",
        name: this.msgs.TASK_NO_REPEAT
      }
    ];
  }

  public getStatusItems(): any {
    return [
      { id: TaskUtils.statusNewId(), name: TaskUtils.statusNewName(), displayName: this.msgs.STATUS_NEW },
      { id: TaskUtils.statusInProgressId(), name: TaskUtils.statusInProgressName(), displayName: this.msgs.STATUS_INPROGRESS },
      { id: TaskUtils.statusCompletedId(), name: TaskUtils.statusCompletedName(), displayName: this.msgs.STATUS_COMPLETED, is_closed: true }
    ];
  }

  public syncMyTeamUsers() {
    console.log("[task.repository] syncMyTeamUser");

    // got users from local storage
    const users = this.localStorageService.getMyTeamUsers();
    if (users) {
      this.store.dispatch(new StoreMyTeamUsers(users));
    }

    // sync users from server
    if (this.isOnline) {
      this.taskService.getMyTeamUsers().subscribe( users => {
        this.localStorageService.storeMyTeamUsers(users);
        this.store.dispatch(new StoreMyTeamUsers(users));
        this.successService.emit({ id: SuccessType.TeamUserListSuccess, messages: "" });
      }, err => {
        this.errorService.emit({ id: ErrorType.TeamUserListError, messages: err });
      });
    }
  }

  public archiveUsers(teamUserIds: number[]) {
    this.taskService.archiveUsers(teamUserIds).subscribe( res => {
      this.successService.emit({ id: SuccessType.ArchiveUserSuccess, messages: this.msgs.USER_ARCHIVED });
    }, err => {
      this.errorService.emit({ id: ErrorType.ArchiveUserError, messages: err });
    });
  }

  public unArchiveUsers(teamUserIds: number[]) {
    this.taskService.unArchiveUsers(teamUserIds).subscribe( res => {
      this.successService.emit({ id: SuccessType.UnArchiveUserSuccess, messages: this.msgs.USER_UNARCHIVED });
    }, err => {
      this.errorService.emit({ id: ErrorType.UnArchiveUserError, messages: err });
    });
  }

  public inviteTeamUsers(emailAddresses: string[]) {
    this.taskService.inviteTeamUsers(emailAddresses).subscribe( res => {
      this.successService.emit({ id: SuccessType.InviteTeamUsersSuccess, messages: this.msgs.INVITATION_SENT });
    }, err => {
      this.errorService.emit({ id: ErrorType.InviteTeamUsersError, messages: err });
    });
  }

  public logout() {
    this.removeApiKey();
    this.cleanCacheData();
    this.broadcaster.broadcast("closeAllMdlDialogs");
    this.store.dispatch(new Logout());
    this.broadcaster.broadcast("USER_LOGGED_OUT", true);
    if (this.isCordovaOrElectron) {
      this.taskService.cordovaLogout().subscribe();
      localStorage.removeItem("token");
      this.router.navigate(["/task", TasksConstants.ROUTE_OPEN]);
      this.configService.loginIframe();
    } else {
      localStorage.removeItem("token");
      this.taskService.logout();
    }
  }

  public createTableIfNotExists() {
    if (CommonUtil.isSQLSupported()){
      this.databaseService.createTableIfNotExist();
    }
  }

  public cleanCacheData() {
    this.configService.clearCache();
    this.taskService.clearHeaders();
    // drop db for mobile
    if (CommonUtil.isSQLSupported()){
      this.databaseService.cleanAllData();
    }
    this.localStorageService.cleanLocalStorage();
  }

  public updateMembersAvatars(authUser: AuthUser) {
    if (CommonUtil.isSQLSupported()) {
      this.store.select(IsDatabaseReady).pipe(filter(ready => !!ready), take(1)).subscribe(ready => {
        this.databaseService.getAllProjects().subscribe((projects) => {
          if (projects && projects.length > 0) {
            let allMembersHash = {};
            projects.forEach( project => {
              this.databaseService.getProjectMembers(project.id).subscribe((members) => {
                if (members && members.length > 0) {
                  members.forEach( member => {
                    if (member.id === authUser.id) {
                      member.avatar = authUser.userAvatar;
                    }
                  });
                  allMembersHash[project.id] = members;
                }
              });
            });
            this.databaseService.createOrUpdateProjects(projects, allMembersHash).subscribe((success) => {
              console.log("[task.repository] updateMembersAvatars", success);
            });
          }
        });
      });
    } else {
      // Change members avatars
      let projects = this.localStorageService.getProjects();
      if ( projects && projects.length > 0) {
        projects.forEach( project => {
          let members = this.localStorageService.getMemberList(project.id);
          if ( members && members.length > 0) {
              members.forEach( member => {
                if (member.id === authUser.id) {
                  member.avatar = authUser.userAvatar;
                }
              });
              this.localStorageService.storeMemberList(project.id, members);
          }
        });
      }
    }
  }

  public getSaveSearches() {
    if (this.isOnline) {
      this.taskService.getSaveSearches().pipe(take(1)).subscribe(saveSearches => {
        console.log("[task.repository] getSaveSearches lists", saveSearches);
        // save to DB
        if (CommonUtil.isSQLSupported() && saveSearches && saveSearches.length > 0 ) {
          this.store.select(IsDatabaseReady).pipe(filter(ready => !!ready), take(1)).subscribe(ready => {
            this.databaseService.createOrUpdateSaveQuery(saveSearches).subscribe((success) => {
              console.log("[task.repository] save search stored in DB", success);
            });
          });
        }
        this.store.dispatch(new StoreSaveSearches(saveSearches));
      }, err => {
        this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
      });
    } else {
      this.store.select(IsDatabaseReady).pipe(filter(ready => !!ready), take(1)).subscribe(ready => {
        if (CommonUtil.isSQLSupported()) {
          this.databaseService.getSaveSearches().subscribe((saveSearches) => {
            if (saveSearches) {
              this.store.dispatch(new StoreSaveSearches(saveSearches));
            }
          });
        } else {
          const saveSearches = this.localStorageService.getSaveSearches();
          if (saveSearches && saveSearches.length > 0) {
            this.store.dispatch(new StoreSaveSearches(saveSearches));
          }
        }
      });
    }
  }

  public saveSettings(user: AuthUser) {
    this.taskService.saveSettings(user).subscribe( authUser => {
      this.poppulateAuthUserAvatar(authUser, false).subscribe(success => {
        if (success) {
          console.log("[task.repository][syncAuthUser] set avatar for user. Not storing to cache");
          this.localStorageService.storeUser(authUser);
          if (environment.isElectron) {
            if (authUser.userAvatar) {
              if (CommonUtil.isFileSystemUrl(authUser.userAvatar.toString())) {
                authUser.userAvatar = this.sanitizer.bypassSecurityTrustUrl(authUser.userAvatar);
              }
            }
          }
          this.store.dispatch(new StoreAuthUser(authUser));
        }
      });
      this.successService.emit({ id: SuccessType.SaveSettingSuccess, messages: this.msgs.SETTINGS_SAVE_SUCCESS });
    }, err => {
      this.errorService.emit({ id: ErrorType.SaveSettingError, messages: err });
    });
  }

  public updateTaskInStoreAndDB(task: Task) {
    let tasks = [ task ];
    if (CommonUtil.isSQLSupported()) {
      this.databaseService.updateTask(task, []).subscribe((dbRes) => {
        this.store.dispatch(new UpdateTaskSuccess({ task: { id: tasks[0].id, changes: tasks[0] } }));
      });
    } else {
      this.store.dispatch(new UpdateTaskSuccess({ task: { id: tasks[0].id, changes: tasks[0] } }));
    }
  }

  public saveApiKey() {
    if (environment.isCordova) {
      const apiKey = localStorage.getItem("token");
      console.log("[task.repository][saveApiKey] apiKey", apiKey);
      if (!apiKey) {
        return;
      }
      plugins.appPreferences.store(() => {
        console.log("[task.repository][saveApiKey] success");
      }, () => {
        console.error("[task.repository][saveApiKey] failure");
      }, "redmine-api-key", apiKey);
    }
  }

  public removeApiKey(){
    if (environment.isCordova) {
      plugins.appPreferences.remove(() => {
        console.log("[task.repository][removeApiKey] success");
      }, () => {
        console.log("[task.repository][removeApiKey] failure");
      }, "redmine-api-key");
    }
  }

  public saveApiUrl() {
    if (environment.isCordova) {
      const apiUrl = this.configService.API_URL + "/api/task";
      console.log("[task.repository][saveApiUrl] apiUrl", apiUrl);
      plugins.appPreferences.store(() => {
        console.log("[task.repository][saveApiUrl] success");
      }, () => {
        console.log("[task.repository][saveApiUrl] failure");
      }, "apiUrl", apiUrl);
    }
  }

  public removeApiUrl() {
    if (environment.isCordova) {
      plugins.appPreferences.remove(() => {
        console.log("[task.repository][removeApiUrl] success");
      }, () => {
        console.log("[task.repository][removeApiUrl] failure");
      }, "apiUrl");
    }
  }

  public renderMentionUsers(content: string, members: User[]) {
    let mentions = CommonUtil.parseMentions(content);
    if (mentions.length > 0) {
      mentions.forEach(mention => {
        if (members && members.length > 0) {
          members.forEach( member => {
            if (member.username === mention) {
              content = content.replace(new RegExp("@" + mention, "gim"), `<span class="mentioned-user">@${mention}</span>`);
            }
          });
        }
      });
    }
    return content;
  }

  public openTaskExternal(taskId) {
    if (taskId && localStorage.getItem("REDMINE_URL")) {
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.setAttribute("class", "open-new-window");
      a.setAttribute("style", "display: none");
      if (taskId.includes("meta_roadmaps")) {
        a.href = localStorage.getItem("REDMINE_URL") + "/" + taskId;
      } else {
        a.href = localStorage.getItem("REDMINE_URL") + "/issues/" + taskId;
      }
      a.click();
      a.remove();
    }
  }

  public getSettings() {
    // get setting details
    if (this.isOnline) {
      this.taskService.getSettings().pipe(take(1)).subscribe(settings => {
        console.log("[task.repository][getSettings] settings : ", settings);
        this.store.dispatch(new StoreSettings(settings));
      }, err => {
          this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
      });
    }
  }

  public buildAvatarUrl(jid: string, timestamp: number) {
    let avatarName = CommonUtil.buildTargetHash(jid);
    let avatarVersion = "?ver=" + timestamp;
    const avUrl = this.configService.avatarServiceUrl + "/" + avatarName + ".jpg" + avatarVersion;
    return avUrl;
  }

  public syncTaskInStore(notification) {
    if (notification) {
      if (notification.type === TaskNotificationType.ASSIGNMENT) {
        if (notification.task_id) {
          this.taskService.getTaskDetails(notification.task_id).pipe(take(1)).subscribe(task => {
            if (task) {
              this.createTaskOnDBIfNeededAndFireResult(task);

              this.getTasksStats();
              this.getFolderListWithCounters();
              this.getTagsListWithCounters();
              this.getLocationsWithCounters();
            }
          });
        }
      }
      if (notification.type === TaskNotificationType.TASK_UPDATE
        || notification.type === TaskNotificationType.ASSIGNMENT_REMOVED
        || notification.type === TaskNotificationType.TASK_WATCHER
        || notification.type === TaskNotificationType.MENTIONING) {
        if (notification.task_id) {
          this.store.select(state => getTasksById(state, notification.task_id)).pipe(take(1)).subscribe( oldTask => {
            if (oldTask) {
              this.taskService.getTaskDetails(notification.task_id).pipe(take(1)).subscribe(newTask => {
                if (newTask) {
                  this.updateTaskOnDBIfNeededAndFireResult(oldTask, newTask);
                }
              });
            }
         });
        }
      }
      if (notification.type === TaskNotificationType.DELETION) {
        if (notification.task_id) {
          this.deleteTaskOnDBIfNeededAndFireResult([notification.task_id]);
        }
      }
    }
  }

  makeTalkAudioChatVideoOperation(email, action: string, groupAction: string): void {
      const emails: any[] = [];
      emails.push(email);
      this.talkCallAudioChatVideoOperation(action, emails.toString());
  }

  talkCallAudioChatVideoOperation(action: string, target: string, groupName?: string) {
    let talkAppURL: string = "";
    this.store.select(getFederatedApps).pipe(take(1)).subscribe(apps => {
      talkAppURL = apps.filter( ap => ap.name.toLowerCase() === "vnctalk")[0]?.options?.url;
    });
    talkAppURL = this.getURL(talkAppURL);
    if (this.electronService.isElectron) {
      try {
        let nativeHandler = this.electronService.app.getApplicationNameForProtocol("vnctalk://main");
        console.log("appswitcher nativeHandler: ", nativeHandler);
        if (nativeHandler && nativeHandler !== "") {
          talkAppURL = "vnctalk://main/";
        }
      } catch (e) {
        console.error("[common-repository] error: ", e);
      }
    }

    let sendURL;
    if (talkAppURL.endsWith("/")) {
      sendURL = talkAppURL + "talk/trigger?action=" + action + "&target=" + target;
    } else {
      sendURL = talkAppURL + "/talk/trigger?action=" + action + "&target=" + target;
    }
    if (groupName) {
      sendURL += "&groupName=" + groupName;
    }
    if (environment.isCordova) {
      if (device.platform === "iOS") {
        window.open(sendURL, "_system");
      } else if (device.platform === "Android") {
        navigator.app.loadUrl(sendURL, {
          openExternal: true
        });
      }
    } else if (environment.isElectron) {
      this.electronService.openExternalUrl(sendURL);
    } else {
      window.open(sendURL, "_blank");
    }
  }

  getURL(url: string): string {
      let sendURL: string = "";
      if (url.endsWith("/")) {
          sendURL = url;
      } else {
          sendURL = url + "/";
      }
      return sendURL;
  }

  sendEmail(email): void {
    const emails: any[] = [];
    emails.push(email);
    let mailAppURL: string = "";
    this.store.select(getFederatedApps).pipe(take(1)).subscribe(apps => {
        mailAppURL = apps.filter( ap => ap.name.toLowerCase() === "vncmail")[0]?.options.url;
    });
    mailAppURL = this.getURL(mailAppURL);
    const sendURL = mailAppURL + "mail/compose?to=" + emails.toString();
    if (environment.isCordova) {
        if (device.platform === "iOS") {
            window.open(sendURL, "_system");
        } else if (device.platform === "Android") {
            navigator.app.loadUrl(sendURL, {
                openExternal: true
            });
        }
    } else if (environment.isElectron) {
        this.router.navigate(["/mail/compose"], { queryParams: { to: emails.toString() } });
    } else {
        window.open(sendURL, "_blank");
    }
  }

  createTicket(email): void {
    let projectAppURL: string = "";
    this.store.select(getFederatedApps).pipe(take(1)).subscribe(apps => {
      projectAppURL = apps.filter( ap => ap.name.toLowerCase() === "vncproject")[0]?.options?.url;
    });
    projectAppURL = this.getURL(projectAppURL);
    const sendURL = projectAppURL + "issues/new?assignee_username=" + email;
    if (environment.isCordova) {
      if (device.platform === "iOS") {
        window.open(sendURL, "_system");
      } else if (device.platform === "Android") {
        navigator.app.loadUrl(sendURL, {
          openExternal: true
        });
      }
    } else if (environment.isElectron) {
      this.electronService.openExternalUrl(sendURL);
    } else {
      window.open(sendURL, "_blank");
    }
  }

  openMail(folderId: any, mailId: any): void {
    // this.store.pipe(select(state => getFolderById(state, folderId)), take(1)).subscribe(f => {
    //   console.log("openMail folder f:", f);
    //   let newLocationPath = "/mail" + f.absFolderPath.toLowerCase() + "/detail/m/" + mailId;
    //   this.router.navigate([newLocationPath]);
    // });
  }

  openTask(taskId: string): void {
    let taskAppURL: string = "";
    this.store.select(getFederatedApps).pipe(take(1)).subscribe(apps => {
      taskAppURL = apps.filter( ap => ap.name.toLowerCase() === "vnctask")[0]?.options?.url;
    });
    taskAppURL = this.getURL(taskAppURL);
    const sendURL = taskAppURL + "redirect?action=open-task&id=" + taskId;
    if (environment.isCordova) {
      if (device.platform === "iOS") {
        window.open(sendURL, "_system");
      } else if (device.platform === "Android") {
        navigator.app.loadUrl(sendURL, {
          openExternal: true
        });
      }
    } else if (environment.isElectron) {
      this.electronService.openExternalUrl(sendURL);
    } else {
      window.open(sendURL, "_blank");
    }
  }



  jumpToChat(contact: ContactInfo, talkId: string, createdDt: string): void {
    let talkAppURL: string = "";
    let timestamp = new Date(createdDt).getTime();
    let target = "";
    if ( contact && contact.jid) {
      target = contact.jid;
    } else {
      if ( contact && contact.emails && contact.emails.length > 0) {
        target = contact.emails[0].email;
      } else {
        // this.toastService.show("CONTACT_MUST_HAVE_EMAIL_ADDRESS");
        return;
      }
    }
    this.store.select(getFederatedApps).pipe(take(1)).subscribe(apps => {
      talkAppURL = apps.filter( ap => ap.name.toLowerCase() === "vnctalk")[0]?.options?.url;
    });
    talkAppURL = this.getURL(talkAppURL);
    try {
      if (environment.isElectron) {
        let nativeHandler = this.electronService.app.getApplicationNameForProtocol("vnctalk://main");
        console.log("appswitcher nativeHandler: ", nativeHandler);
        if (nativeHandler && nativeHandler !== "") {
          talkAppURL = "vnctalk://main/";
        }
      }
    } catch (e) {
      console.error("[common-repository] error: ", e);
    }
    let sendURL;
    if (talkAppURL.endsWith("/")) {
      sendURL = talkAppURL + "talk/trigger?action=jump-to-chat&target=" + target + "&chat_id=" + talkId + "&timestamp=" + timestamp;
    } else {
      sendURL = talkAppURL + "/talk/trigger?action=jump-to-chat&target=" + target + "&chat_id=" + talkId + "&timestamp=" + timestamp;
    }
    if (environment.isCordova) {
      if (device.platform === "iOS") {
        window.open(sendURL, "_system");
      } else if (device.platform === "Android") {
        navigator.app.loadUrl(sendURL, {
          openExternal: true
        });
      }
    } else if (environment.isElectron) {
      this.electronService.openExternalUrl(sendURL);
    } else {
      window.open(sendURL, "_blank");
    }
  }



  createTask(email): void {
    let taskAppURL: string = "";
    this.store.select(getFederatedApps).pipe(take(1)).subscribe(apps => {
      taskAppURL = apps.filter( ap => ap.name.toLowerCase() === "vnctask")[0]?.options?.url;
    });
    taskAppURL = this.getURL(taskAppURL);
    const sendURL = taskAppURL + "task/open?action=compose&assignee=" + email;
    if (environment.isCordova) {
      if (device.platform === "iOS") {
        window.open(sendURL, "_system");
      } else if (device.platform === "Android") {
        navigator.app.loadUrl(sendURL, {
          openExternal: true
        });
      }
    } else if (environment.isElectron) {
      this.electronService.openExternalUrl(sendURL);
    } else {
      window.open(sendURL, "_blank");
    }
  }
  loadTaskToDb(task, defaultFilter?): Observable<any> {
    const response = new BehaviorSubject<any>([]);
    let routingType = "";
    let taskFilter = "";
    this.store.select(getSelectedFilterOption).subscribe(res => {
      taskFilter = res;
    })
    this.store.select(getSelectedFilterType).pipe(take(1)).subscribe(o => routingType = o);
    if (defaultFilter) {
      if (defaultFilter.routingType === "status") taskFilter = defaultFilter.routingOption;
      if (defaultFilter.routingType === "list") {
        routingType = defaultFilter.routingType;
        taskFilter = defaultFilter.routingOption;
      }
      if (defaultFilter.routingType === "tags") {
        routingType = defaultFilter.routingType;
        taskFilter = defaultFilter.routingOption;
      }
      if (defaultFilter.routingType === "location") {
        routingType = defaultFilter.routingType;
        taskFilter = defaultFilter.routingOption;
      }
    }
    if (routingType === "status") {
      if (taskFilter === "all") {
        this.databaseService.addAllTasks(task);
        task.map(item => {
          CommonUtil.getBase6ImageFromUrl(item?.userAvatar).subscribe(res => {
            const avatar = {
              id: item.assigned_to.id,
              data: res
            }
            this.databaseService.addAvatar(avatar);
          })
          if (item?.comments?.length) {
            item.comments.map(item => {
              CommonUtil.getBase6ImageFromUrl(item?.userAvatar).subscribe(res => {
                const avatar = {
                  id: item.user.id,
                  data: res
                }
                this.databaseService.addAvatar(avatar);
              })
            })
          }
        })
        this.getContactInfo(task);
      }
      if (taskFilter === "open") this.databaseService.addOpenTasks(task);
      if (taskFilter === "new") this.databaseService.addNewTasks(task);
      if (taskFilter === "completed") this.databaseService.addCompletedTasks(task);
      if (taskFilter === "assigned") this.databaseService.addAssignedTasks(task);
      if (taskFilter === "created") this.databaseService.addCreatedTasks(task);
      if (taskFilter === "today") this.databaseService.addTodayTasks(task);
      if (taskFilter === "tomorrow") this.databaseService.addTomorrowTasks(task);
      if (taskFilter === "week") this.databaseService.addWeekTasks(task);
      if (taskFilter === "watch") this.databaseService.addWatchTasks(task);
      if (taskFilter === "overdue") this.databaseService.addOverdueTasks(task);
    }
    if (routingType === "tags") this.databaseService.addTagsTasks(taskFilter, task);
    if (routingType === "list") this.databaseService.addListTasks(taskFilter, task);
    if (routingType === "location") this.databaseService.addLocationTasks(taskFilter, task);
    response.next(task);
    return response.asObservable();
  }

  getDataFromDatabase(taskFilter) {
    const offset = 0
    const isMoreTasks = false;
    let routingType = "";
    this.store.select(getSelectedFilterType).pipe(take(1)).subscribe(o => routingType = o);
    if (routingType === "status") {
      switch (taskFilter) {
        case "all":
          this.databaseService.getAllTasks().subscribe(taskFromDB => {
            this.sortTaskFromDB(taskFromDB, offset, isMoreTasks);
          })
           break;
        case "open":
          this.databaseService.getOpenTasks().subscribe(taskFromDB => {
            this.sortTaskFromDB(taskFromDB, offset, isMoreTasks);
          })
          break;
        case "new":
          this.databaseService.getNewTasks().subscribe(taskFromDB => {
            this.sortTaskFromDB(taskFromDB, offset, isMoreTasks);
          })
          break;
        case "completed":
          this.databaseService.getCompletedTasks().subscribe(taskFromDB => {
            this.sortTaskFromDB(taskFromDB, offset, isMoreTasks);
          })
          break;
        case "watch":
          this.databaseService.getWatchTasks().subscribe(taskFromDB => {
            this.sortTaskFromDB(taskFromDB, offset, isMoreTasks);
          })
          break;
        case "overdue":
          this.databaseService.getOverdueTasks().subscribe(taskFromDB => {
            this.sortTaskFromDB(taskFromDB, offset, isMoreTasks);
          })
          break;
        case "week":
          this.databaseService.getWeekTasks().subscribe(taskFromDB => {
            this.sortTaskFromDB(taskFromDB, offset, isMoreTasks);
          })
          break;
        case "tomorrow":
          this.databaseService.getTomorrowTasks().subscribe(taskFromDB => {
            this.sortTaskFromDB(taskFromDB, offset, isMoreTasks);
          })
          break;
        case "assigned":
          this.databaseService.getAssignedTasks().subscribe(taskFromDB => {
            this.sortTaskFromDB(taskFromDB, offset, isMoreTasks);
          })
          break;
        case "today":
          this.databaseService.getTodayTasks().subscribe(taskFromDB => {
            this.sortTaskFromDB(taskFromDB, offset, isMoreTasks);
          })
          break;
        case "created":
          this.databaseService.getCreatedTasks().subscribe(taskFromDB => {
            this.sortTaskFromDB(taskFromDB, offset, isMoreTasks);
          })
          break;
      }
    }
    if (routingType === "tags") {
      this.databaseService.getTagsTasks(taskFilter).subscribe(taskFromDB => {
        this.sortTaskFromDB(taskFromDB, offset, isMoreTasks);
      })
    }
    if (routingType === "list") {
      this.databaseService.getListTasks(taskFilter).subscribe(taskFromDB => {
        this.sortTaskFromDB(taskFromDB, offset, isMoreTasks);
      })
    }
    if (routingType === "location") {
      this.databaseService.getLocationTasks(taskFilter).subscribe(taskFromDB => {
        this.sortTaskFromDB(taskFromDB, offset, isMoreTasks);
      })
    }
  }

  getMoreDataFromDatabase(taskFilter, currentOffset) {
    switch (taskFilter) {
      case "all":
        this.databaseService.getAllTasks().subscribe(tasks => {
          this.processLoadMoreTasksResult(tasks, currentOffset);
        }, err => {
          this.store.dispatch(new NextLoadTasksFail());
          this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
        })
        break;
      case "open":
        this.databaseService.getOpenTasks().subscribe(tasks => {
          this.processLoadMoreTasksResult(tasks, currentOffset);
        }, err => {
          this.store.dispatch(new NextLoadTasksFail());
          this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
        })
        break;
      case "new":
        this.databaseService.getNewTasks().subscribe(tasks => {
          this.processLoadMoreTasksResult(tasks, currentOffset);
        }, err => {
          this.store.dispatch(new NextLoadTasksFail());
          this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
        })
        break;
      case "completed":
        this.databaseService.getCompletedTasks().subscribe(tasks => {
          this.processLoadMoreTasksResult(tasks, currentOffset);
        }, err => {
          this.store.dispatch(new NextLoadTasksFail());
          this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
        })
        break;
      case "watch":
        this.databaseService.getWatchTasks().subscribe(tasks => {
          this.processLoadMoreTasksResult(tasks, currentOffset);
        }, err => {
          this.store.dispatch(new NextLoadTasksFail());
          this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
        })
        break;
      case "overdue":
        this.databaseService.getOverdueTasks().subscribe(tasks => {
          this.processLoadMoreTasksResult(tasks, currentOffset);
        }, err => {
          this.store.dispatch(new NextLoadTasksFail());
          this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
        })
        break;
      case "week":
        this.databaseService.getWeekTasks().subscribe(tasks => {
          this.processLoadMoreTasksResult(tasks, currentOffset);
        }, err => {
          this.store.dispatch(new NextLoadTasksFail());
          this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
        })
        break;
      case "tomorrow":
        this.databaseService.getTomorrowTasks().subscribe(tasks => {
          this.processLoadMoreTasksResult(tasks, currentOffset);
        }, err => {
          this.store.dispatch(new NextLoadTasksFail());
          this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
        })
        break;
      case "assigned":
        this.databaseService.getAssignedTasks().subscribe(tasks => {
          this.processLoadMoreTasksResult(tasks, currentOffset);
        }, err => {
          this.store.dispatch(new NextLoadTasksFail());
          this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
        })
        break;
      case "today":
        this.databaseService.getTodayTasks().subscribe(tasks => {
          this.processLoadMoreTasksResult(tasks, currentOffset);
        }, err => {
          this.store.dispatch(new NextLoadTasksFail());
          this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
        })
        break;
      case "created":
        this.databaseService.getCreatedTasks().subscribe(tasks => {
          this.processLoadMoreTasksResult(tasks, currentOffset);
        }, err => {
          this.store.dispatch(new NextLoadTasksFail());
          this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
        })
        break;
    }
  }

  sortTaskFromDB(tasks, offset, isMoreTasks) {
    let sortBy
    this.store.select(getSortBy).pipe(take(1)).subscribe(o => sortBy = o);
    switch (sortBy) {
      case "due_date":
        this.sortDataByDueDate(tasks);
        this.store.dispatch(new LoadTasksSuccess({ currOffset: offset, isMoreTasks: isMoreTasks, task: tasks }));
         break;
      case "priority":
        this.sortDataByPriority(tasks);
        this.store.dispatch(new LoadTasksSuccess({ currOffset: offset, isMoreTasks: isMoreTasks, task: tasks }));
        break;
      case "subject":
        this.sortDataByTaskName(tasks);
        this.store.dispatch(new LoadTasksSuccess({ currOffset: offset, isMoreTasks: isMoreTasks, task: tasks }));
        break;
      case "start_date":
        this.sortDataByStartDate(tasks);
        this.store.dispatch(new LoadTasksSuccess({ currOffset: offset, isMoreTasks: isMoreTasks, task: tasks }));
        break;
      case "status":
        this.sortDataByStatus(tasks);
        console.log(" => info:tasks ", tasks);
        this.store.dispatch(new LoadTasksSuccess({ currOffset: offset, isMoreTasks: isMoreTasks, task: tasks }));
        break;
      case "created_on":
        this.sortDataByCreatedOn(tasks);
        this.store.dispatch(new LoadTasksSuccess({ currOffset: offset, isMoreTasks: isMoreTasks, task: tasks }));
        break;
    }
  }

  sortDataByDueDate(tasks) {
    tasks.sort((a, b) => {
      if (!a.due_date) return 1;
      if (!b.due_date) return -1;
      const dateA = new Date(a.due_date);
      const dateB = new Date(b.due_date);
      const dateComparison = dateA.getTime() - dateB.getTime();
      if (dateComparison !== 0) {
        return dateComparison;
      }
      const timeA = dateA.getHours() * 60 + dateA.getMinutes();
      const timeB = dateB.getHours() * 60 + dateB.getMinutes();
      const minuteComparison = timeA - timeB;
      if (minuteComparison !== 0) {
        return minuteComparison;
      }
      return 0;
    });
  }

  sortDataByStartDate(tasks) {
    tasks.sort((a, b) => {
      if (!a.start_date) return 1;
      if (!b.start_date) return -1;
      const dateA = new Date(a.start_date);
      const dateB = new Date(b.start_date);
      const dateComparison = dateA.getTime() - dateB.getTime();
      if (dateComparison !== 0) {
        return dateComparison;
      }
      const timeA = dateA.getHours() * 60 + dateA.getMinutes();
      const timeB = dateB.getHours() * 60 + dateB.getMinutes();
      const minuteComparison = timeA - timeB;
      if (minuteComparison !== 0) {
        return minuteComparison;
      }
      return 0;
    });
  }

  sortDataByPriority(tasks) {
    return tasks.sort((a, b) => b.priority.id - a.priority.id);
  }

  sortDataByStatus(tasks) {
    return tasks.sort((a, b) => a.status.id - b.status.id);
  }

  sortDataByCreatedOn(tasks) {
    tasks.sort((a, b) => {
      const dateA = new Date(a.created_on);
      const dateB = new Date(b.created_on);
      const dateComparison = dateB.getTime() - dateA.getTime();
      if (dateComparison !== 0) {
        return dateComparison;
      }
      const timeA = dateA.getHours() * 60 + dateA.getMinutes();
      const timeB = dateB.getHours() * 60 + dateB.getMinutes();
      const minuteComparison = timeB - timeA;
      if (minuteComparison !== 0) {
        return minuteComparison;
      }
      return 0;
    });
  }

  sortDataByTaskName(tasks) {
    return tasks.sort((a, b) => a.subject.localeCompare(b.subject));
  }

  updatePendingBulkTasks(selectedTasks: Task[], fieldToUpdate: BulkUpdateIssueType, args: BulkUpdateArgs): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log("[TaskRepository] updateBulkTasks");

      const tasksIds: number[] = selectedTasks.map(t => t.id);

      let valueToUpdate;
      switch (fieldToUpdate) {
        case BulkUpdateIssueType.StartDate:
        case BulkUpdateIssueType.DueDate:
        case BulkUpdateIssueType.InviteTo:
          valueToUpdate = (args.value != null) ? args.value : "none";
          break;
        case BulkUpdateIssueType.Tags:
          valueToUpdate = [args.name];
          break;
        case BulkUpdateIssueType.Watchers:
          let watchers = JSON.parse(args.value);
          valueToUpdate = [];
          watchers.forEach(member => {
            valueToUpdate.push(member.id);
          });
          break;
        default:
          valueToUpdate = args.id;
      }

      console.log("[TaskRepository] updateBulkTasks. fieldToUpdate: " + fieldToUpdate + ". valuesToUpdate: " + valueToUpdate);

      this.store.dispatch(new UpdateTask());
      selectedTasks.forEach((i) => {
        i.isChecked = false
      });
      if (this.isOnline) {
        this.taskService.updateBulkTasks(tasksIds, fieldToUpdate, valueToUpdate).pipe(take(1)).subscribe(res => {
          this.updateBulkTasksOnDB(selectedTasks, fieldToUpdate, args, valueToUpdate);
          this.syncBulkUpdateToDB(selectedTasks, fieldToUpdate, args, valueToUpdate);
          this.FormattedDateForDB = null;
          this.store.dispatch(new SetEditTaskView(false));
          this.store.dispatch(new SetSelectAll(false));
          this.store.dispatch(new ResetSelectedTaskId());
          resolve("done");
        }, err => {
          this.store.dispatch(new SetEditTaskView(false));
          this.store.dispatch(new SetSelectAll(false));
          this.store.dispatch(new ResetSelectedTaskId());
          resolve("");
          console.error("[TaskRepository] updateBulkTasks, error from server: ", err);
          let msg = "";
          switch (fieldToUpdate) {
            case BulkUpdateIssueType.StartDate:
              msg = this.msgs.START_DATE_CHANGE_ERROR_MESSAGE;
              break;
            case BulkUpdateIssueType.DueDate:
              msg = this.msgs.TASK_DUE_DATE_UPDATE_FAIL;
              break;
            default:
              msg = err;
          }
          this.store.dispatch(new UpdateTaskFail());
          this.errorService.emit({ id: ErrorType.GenericMessage, messages: msg });
        });
      } else if (CommonUtil.isSQLSupported()) {
        this.updateBulkTasksOnDB(selectedTasks, fieldToUpdate, args, valueToUpdate);
      }
      else if (!this.isOnline) {
        const id = Math.floor(Math.random() * 1000);
        const task = {
          id: id,
          body: {
            tasks: selectedTasks,
            fieldToUpdate: fieldToUpdate,
            valueToUpdate: args,
            operationType: BulkUpdateIssueType[fieldToUpdate],
          }
        }
        this.databaseService.addPendingOperationTask([task]).subscribe(updatedTask => {
          this.updateBulkTasksOnDB(selectedTasks, fieldToUpdate, args, valueToUpdate);
          this.syncBulkUpdateToDB(selectedTasks, fieldToUpdate, args, valueToUpdate);
          resolve("done");
        }, err => {
          resolve(err);
          this.store.dispatch(new UpdateTaskFail());
          this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
        })
      }
    })
  }

  updatePendingTask(oldTask: Task, newTask: Task, fileUploads: FileUpload[], watcherMembers: User[]): Promise<any> {
    return new Promise((resolve, reject) => {
      this.store.dispatch(new UpdateTask());
      let tasksStore;
      this.store.select(getTasks).subscribe(tasksFromStore => {
        tasksStore = tasksFromStore;
      })
      let removedTagsIds: number[] = [];
      oldTask.tags.forEach(oldTag => {
        let tagRemoved = true;
        for (let newTag of newTask.tags) {
          if (oldTag.id === newTag.id) {
            tagRemoved = false;
            break;
          }
        }
        if (tagRemoved) {
          removedTagsIds.push(oldTag.id);
        }
      });

      if (this.isOnline) {
        this.taskService.updateTask(oldTask, newTask, fileUploads, watcherMembers).pipe(take(1)).subscribe(serverRes => {
          if (CommonUtil.isSQLSupported()) {
            if (fileUploads && fileUploads.length > 0) {
              this.taskService.getTaskDetails(oldTask.id).subscribe(updatedTaskFromServer => {
                this.databaseService.updateTask(updatedTaskFromServer, removedTagsIds).subscribe((dbRes) => {
                  this.updateTaskSuccess(oldTask, updatedTaskFromServer);
                });
              });
            } else {
              this.databaseService.updateTask(serverRes, removedTagsIds).subscribe((dbRes) => {
                this.updateTaskSuccess(oldTask, serverRes);
              });
            }
          } else {
            this.store.dispatch(new SetEditTaskView(false));
            this.store.dispatch(new SetSelectAll(false));
            this.store.dispatch(new ResetSelectedTaskId());
            resolve("done");
            this.updateTaskSuccess(oldTask, serverRes);
            for (let store of TaskRepository.taskStoreList) {
              this.databaseService.getTaskById(oldTask.id, store).subscribe(t => {
                if (t) {
                  newTask.isChecked = false;
                  this.databaseService.addBulkTasks([newTask], store);
                }
              })
            }
          }
        }, err => {
          this.store.dispatch(new SetEditTaskView(false));
          this.store.dispatch(new SetSelectAll(false));
          this.store.dispatch(new ResetSelectedTaskId());
          resolve("done");
          this.updateTaskError(oldTask, err);
        });

        // offline task update
      } else if (CommonUtil.isSQLSupported()) {
        // update on DB
        if (watcherMembers && watcherMembers.length > 0) {
          newTask.watchers = watcherMembers;
        }
        this.databaseService.updateTask(newTask, removedTagsIds).subscribe((dbRes) => {
          const fileUploadsValue = fileUploads && fileUploads.length > 0 ? fileUploads : null;
          const repeatValue = newTask.repeat !== "" ? newTask.repeat : null;
          const watcherMembersValue = watcherMembers && watcherMembers.length > 0 ? watcherMembers : null;
          this.databaseService.addPendingTaskUpdate(newTask.id, fileUploadsValue, repeatValue, watcherMembersValue).subscribe(res => {
            this.updateTaskSuccess(oldTask, newTask);
          });
        });
      }
      if (!this.isOnline) {
        newTask.isChecked = false;
        const task = [
          {
            id: oldTask.id,
            body: {
              oldTask: oldTask,
              newTask: newTask,
              fileUploads: fileUploads,
              watcherMembers: watcherMembers,
              operationType: "nonBulkUpdate"
            }
          }
        ]
        this.databaseService.addPendingOperationTask(task).subscribe(r => {
          resolve("done");
          const index = tasksStore.findIndex(item => item.id === oldTask.id);
          tasksStore[index] = r;
          this.databaseService.addAllTasks([newTask]).subscribe(res => {
            this.syncNonBulkToDB(oldTask, newTask, tasksStore, r);
          })
        })
      }
    })
  }

  removePendingTasksQuery(selectedIds: number[]): Promise<any> {
    let successEvent = SuccessType.TasksRemoved;
    console.log("[TaskRepository] removeTasksInternal: ", selectedIds);
    return new Promise((resolve,reject) => {
      this.store.dispatch(new RemoveTasks());

      // remove on server
      if (this.isOnline) {
        this.taskService.removeTasks(selectedIds).pipe(take(1)).subscribe(res => {
          this.store.dispatch(new SetEditTaskView(false));
          this.store.dispatch(new SetSelectAll(false));
          this.store.dispatch(new ResetSelectedTaskId());
          resolve("done");
          // remove on DB
          if (CommonUtil.isSQLSupported()) {
            this.databaseService.removeTasks(selectedIds).subscribe(res => {
              this.removeTasksSuccess(selectedIds, successEvent);
            });
          } else {
            this.removeTasksSuccess(selectedIds, successEvent);
          }
        }, err => {
          // 404
          if (err === "Resource Not Found" && CommonUtil.isSQLSupported()) {
            // remove on DB if not founfd on server
            this.databaseService.removeTasks(selectedIds).subscribe(res => {
              this.removeTasksSuccess(selectedIds, successEvent);
            });
          } else {
            resolve("done");
            this.removeTasksError(err);
          }
        });

        // offline remove
      } else if (CommonUtil.isSQLSupported()) {
        // delete on DB and create a pending operation
        this.databaseService.removeTasks(selectedIds).subscribe(res => {
          // add to pending queue
          selectedIds.forEach(taskId => {
            this.databaseService.addPendingTaskDelete(taskId).subscribe(res => {

            });
          });

          this.removeTasksSuccess(selectedIds, successEvent);
        });
      } else if (!this.isOnline) {
        let taskStats = [];
        this.store.select(getTaskStatisticsInfo).subscribe(info => {
          if (info) {
            taskStats = info;
          }
        });
        const id = Math.floor(Math.random() * 1000);
        const task = {
          id: id,
          body: {
            tasks: selectedIds,
            operationType: "deleteTask",
          }
        }
        this.databaseService.addPendingOperationTask([task]).subscribe(updatedTask => {
          resolve("done");
          selectedIds.forEach((elem) => {
            TaskRepository.taskStoreList.forEach((store) => {
              this.databaseService.getTaskById(elem, store).subscribe(t => {
                if (t) {
                  const currentStoreStat = TaskRepository.statWithStore.find(r => r.store === store);
                  this.removeTasksSuccess(selectedIds, successEvent);
                  this.databaseService.deleteBulkTasks(elem, store);
                  taskStats = taskStats.map(stat => {
                    if (stat.type === currentStoreStat.stat) {
                      stat.info.completed_count = stat.info.completed_count - 1;
                      stat.info.total_count = stat.info.total_count - 1;
                    }
                    return stat;
                  })
                  this.databaseService.addTaskStats(taskStats).subscribe(done => {
                    this.getTasksStats();
                    let routingOption;
                    this.store.select(getSelectedFilterOption).pipe(take(1)).subscribe(o => {
                      const currentStore = TaskRepository.statWithStore.find(r => r.store === store);
                      if (currentStore?.store == routingOption) this.getDataFromDatabase(routingOption);
                    });
                  })
                }
              })
            })
          });
        })
      }
    })
  }

  pendingDuplicateTasks(tasks: Task[]): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log("[TaskRepository] duplicateTasks. tasks: ", tasks);

      const tasksPayloadToCreate: any[] = tasks.map(t => this.getTaskCopy(t));

      this.store.dispatch(new CreateTask());

      console.log("[TaskRepository] duplicateTasks. tasksPayloadToCreate: ", tasksPayloadToCreate);

      if (this.isOnline) {
        // create on server
        this.taskService.bulkCreateTasks(tasksPayloadToCreate).pipe(take(1)).subscribe(createdTasks => {
          console.log(" => duplicateTasks: ", createdTasks, tasksPayloadToCreate);
          // create on DB
          if (CommonUtil.isSQLSupported()) {
            this.databaseService.saveOrUpdateTasks(createdTasks).pipe(take(1)).subscribe((res) => {
              this.duplicateTasksSuccess(createdTasks);
            });
          } else {
            this.duplicateTasksSuccess(createdTasks);
            this.databaseService.addNewTasks(createdTasks);
            this.databaseService.addAllTasks(createdTasks);
            this.store.dispatch(new SetEditTaskView(false));
            this.store.dispatch(new SetSelectAll(false));
            this.store.dispatch(new ResetSelectedTaskId());
            resolve(createdTasks);
          }
        }, err => {
          this.duplicateTasksError(err);
          resolve("done");
        });

      } else if (CommonUtil.isSQLSupported()) {
        let dupTasks = [];
        tasksPayloadToCreate.forEach(tp => {
          let copyTask: Task = Task.taskToCreateOffline(tp);
          dupTasks.push(copyTask);
        });

        dupTasks.forEach(taskToCreate => {
          this.databaseService.addNewTask(taskToCreate).subscribe(res => {
            this.databaseService.addPendingTaskCreate(taskToCreate.id).subscribe(res => {
              this.duplicateTasksSuccess(dupTasks);
            });
          });
        });
      }
      if (!this.isOnline) {
        let taskStats = [];
        this.store.select(getTaskStatisticsInfo).subscribe(info => {
          if (info) {
            taskStats = info;
          }
        });
        const id = Math.floor(Math.random() * 1000);
        const task = {
          id: id,
          body: {
            tasks: tasksPayloadToCreate,
            operationType: "duplicateTask",
          }
        }
        this.databaseService.addPendingOperationTask([task]).subscribe(res => {
          let required_statuses = this.getStatusItems();
          tasks.forEach((item) => {
            item.id = id;
            let newStatus;
            newStatus = required_statuses.find(s => {
              if (s.name.toLowerCase() === "new") {
                return { id: s.id, name: s.name }
              }
            });
            item.isChecked = false;
            item.status = {
              id: newStatus.id,
              name: newStatus.name
            }
            this.databaseService.addNewTasks([item]);
            this.databaseService.addAllTasks([item]);
            newStatus = required_statuses.find(s => {
              if (s.name.toLowerCase() === "in progress") {
                return { id: s.id, name: s.name }
              }
            });
            item.status = {
              id: newStatus.id,
              name: newStatus.name
            }
            this.databaseService.addOpenTasks([item]);

            taskStats = taskStats.map(stat => {
              if (stat.type == "new" || stat.type == "open" || stat.type == "all") {
                stat.info.completed_count = stat.info.completed_count + 1;
                stat.info.total_count = stat.info.total_count + 1;
              }
              return stat;
            })
          });
          this.duplicateTasksSuccess(tasks);
          resolve("done");
          this.databaseService.addTaskStats(taskStats).subscribe(done => {
            this.getTasksStats();
            let routingOption;
            this.store.select(getSelectedFilterOption).pipe(take(1)).subscribe(o => routingOption = o);
            if (routingOption) this.getDataFromDatabase(routingOption);
          })
        })
      }
    })
  }

  updateBulkTasksPendingRecurring(selectedTasks: Task[], type: BulkUpdateIssueType, item: BulkUpdateArgs): Promise<any>{
    return new Promise((resolve, reject) => {
      this.store.dispatch(new UpdateTask());
      const selectedIds: number[] = selectedTasks.map(t => t.id);
      if (this.isOnline) {
        this.taskService.updateBulkTasksRecurring(selectedIds, type, item.value).pipe(take(1)).subscribe(res => {
          this.updateBulkTasksRecurringOnDB(selectedTasks, type, item);
          resolve("done");
        }, err => {
          resolve("done");
          this.store.dispatch(new UpdateTaskFail());
          this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
        });
      } else if (CommonUtil.isSQLSupported()) {
        this.updateBulkTasksRecurringOnDB(selectedTasks, type, item);
      } else if (!this.isOnline) {
        const id = Math.floor(Math.random() * 1000);
        const task = {
          id: id,
          body: {
            tasks: selectedIds,
            fieldToUpdate: type,
            valueToUpdate: item,
            operationType: BulkUpdateIssueType[type],
          }
        }
        this.databaseService.addPendingOperationTask([task]).subscribe(updatedTask => {
          this.updateBulkTasksRecurringOnDB(selectedTasks, type, item);
          resolve("done");
          selectedTasks.forEach((elem) => {
            elem["repeat"] = item.value;
            elem.isChecked = false
            TaskRepository.taskStoreList.forEach((store) => {
              this.databaseService.getTaskById(elem.id, store).subscribe(t => {
                if (t) {
                  this.databaseService.addBulkTasks([elem], store);
                }
              })
            })
          });
        })
      }

    })
  }

  syncBulkUpdateToDB(selectedTasks, fieldToUpdate, args, valueToUpdate) {
    switch (fieldToUpdate) {
      case BulkUpdateIssueType.Priority:
        let priority = {
          id: args.id,
          name: args.name
        }
        selectedTasks.forEach((elem) => {
          elem["priority"] = priority;
          TaskRepository.taskStoreList.forEach((store) => {
            this.databaseService.getTaskById(elem.id, store).subscribe(t => {
              if (t) {
                this.databaseService.addBulkTasks([elem], store);
              }
            })
          })
        });
        break;
      case BulkUpdateIssueType.DueDate:
        selectedTasks.forEach((elem) => {
          elem["due_date"] = this.FormattedDateForDB;
          TaskRepository.taskStoreList.forEach((store) => {
            this.databaseService.getTaskById(elem.id, store).subscribe(t => {
              if (t) this.databaseService.addBulkTasks([elem], store);
            })
          })
        });
        break;
      case BulkUpdateIssueType.StartDate:
        selectedTasks.forEach((elem) => {
          elem["start_date"] = this.FormattedDateForDB;
          TaskRepository.taskStoreList.forEach((store) => {
            this.databaseService.getTaskById(elem.id, store).subscribe(t => {
              if (t) this.databaseService.addBulkTasks([elem], store);
            })
          })
        });
        break;
      case BulkUpdateIssueType.Location:
        let location = {
          id: args.id,
          name: args.name
        }
        selectedTasks.forEach((elem) => {
          elem["location"] = location;
          TaskRepository.taskStoreList.forEach((store) => {
            this.databaseService.getTaskById(elem.id, store).subscribe(t => {
              if (t) this.databaseService.addBulkTasks([elem], store);
            })
          })
        });
        this.databaseService.getLocationTasks(args.name).subscribe(res => {
          if (res) {
            for (let i of selectedTasks) {
              const index = res.findIndex(t => t.id === i.id);
              if (index > -1) res[index] = i;
              else res.push(i);
            }
            this.databaseService.addLocationTasks(args.name, res).subscribe(r => {
            })
          }
        })
        break;
      case BulkUpdateIssueType.List:
        let list = {
          id: args.id,
          name: args.name
        }
        selectedTasks.forEach((elem) => {
          elem["list"] = list;
          TaskRepository.taskStoreList.forEach((store) => {
            this.databaseService.getTaskById(elem.id, store).subscribe(t => {
              if (t) this.databaseService.addBulkTasks([elem], store);
            })
          })
        });
        this.databaseService.getListTasks(args.name).subscribe(res => {
          if (res) {
            for (let i of selectedTasks) {
              const index = res.findIndex(t => t.id === i.id);
              if (index > -1) res[index] = i;
              else res.push(i);
            }
            this.databaseService.addListTasks(args.name, res).subscribe(r => {
            })
          }
        })
        break;
      case BulkUpdateIssueType.Tags:
        let tags = {
          id: args.id,
          name: args.name
        }
        selectedTasks.forEach((elem) => {
          if (!Object.keys(elem).includes("tags")) {
            elem["tags"] = [];
          }
          const index = elem["tags"].findIndex(t => t.id == args.id);
          if (index > -1) elem["tags"][index] = tags;
          else elem["tags"].push(tags);
          TaskRepository.taskStoreList.forEach((store) => {
            this.databaseService.getTaskById(elem.id, store).subscribe(t => {
              if (t) this.databaseService.addBulkTasks([elem], store);
            })
          })
        });
        this.databaseService.getTagsTasks(args.name).subscribe(res => {
          if (res) {
            for (let i of selectedTasks) {
              const index = res.findIndex(t => t.id === i.id);
              if (index > -1) res[index] = i;
              else res.push(i);
            }
            this.databaseService.addTagsTasks(args.name, res).subscribe(r => {
            })
          }
        })
        break;
      case BulkUpdateIssueType.Watchers:
        selectedTasks.forEach((elem) => {
          elem["repeat"] = valueToUpdate;
          TaskRepository.taskStoreList.forEach((store) => {
            this.databaseService.getTaskById(elem.id, store).subscribe(t => {
              if (t) this.databaseService.addBulkTasks([elem], store);
            })
          })
        });
        break;
      case BulkUpdateIssueType.AssignedTo:
        let assigned_to = {
          id: args.id,
          name: args.name
        }
        selectedTasks.forEach((elem) => {
          elem["assigned_to"] = assigned_to;
          TaskRepository.taskStoreList.forEach((store) => {
            this.databaseService.getTaskById(elem.id, store).subscribe(t => {
              if (t) this.databaseService.addBulkTasks([elem], store);
            })
          })
        });
        break;
      case BulkUpdateIssueType.Project:
        let project = {
          id: args.id,
          name: args.name
        }
        selectedTasks.forEach((elem) => {
          elem["project"] = project;
          TaskRepository.taskStoreList.forEach((store) => {
            this.databaseService.getTaskById(elem.id, store).subscribe(t => {
              if (t) this.databaseService.addBulkTasks([elem], store);
            })
          })
        });
        break;
    }
  }

  syncNonBulkToDB(oldTask, newTask, tasksStore, r) {
    this.loadTaskToDb(tasksStore).subscribe(taskFromDB => {
      this.updateTaskSuccess(oldTask, r);
      if (oldTask?.tags?.length) {
        const tagList = oldTask?.tags.map(item => item.name);
        for (let tag of tagList) {
          this.databaseService.getTagsTasks(tag).subscribe(res => {
            if (res) {
              const taskIndex = res.findIndex(item => item.id === oldTask.id);
              res[taskIndex] = newTask;
              if (taskIndex > -1) {
                this.databaseService.addTagsTasks(tag, res).subscribe(r => {
                })
              }
            }
          })
        }
      }
      if (oldTask?.location) {
        const locationName = oldTask?.location?.name;
        if (locationName) {
          this.databaseService.getLocationTasks(locationName).subscribe(res => {
            if (res) {
              const taskIndex = res.findIndex(item => item.id === oldTask.id);
              res[taskIndex] = newTask;
              this.databaseService.addLocationTasks(locationName, res).subscribe(r => {
              })
            }
          })
        }
      }
      if (oldTask?.list) {
        const listName = oldTask?.list?.name;
        if (listName) {
          this.databaseService.getListTasks(listName).subscribe(res => {
            if (res) {
              const taskIndex = res.findIndex(item => item.id === oldTask.id);
              if (taskIndex > -1) {
                res[taskIndex] = newTask;
                this.databaseService.addListTasks(listName, res).subscribe(r => {
                })
              }
            }
          })
        }
      }
      let taskStats = [];
      this.store.select(getTaskStatisticsInfo).subscribe(info => {
        if (info) {
          taskStats = info;
        }
      });
      for (let store of TaskRepository.taskStoreList) {
        this.databaseService.getTaskById(oldTask.id, store).subscribe(t => {
          if (t) {
            this.databaseService.addBulkTasks([newTask], store).subscribe(done => {
              if (newTask.status.name !== oldTask.status.name) {
                if (newTask.status.name.toLowerCase() == "completed" && store === "OpenTaskStore") {
                  this.databaseService.deleteBulkTasks(oldTask.id, store);
                  this.databaseService.addBulkTasks([newTask], "CompletedTaskStore");
                  for (let item of taskStats) {
                    if (item.type == "completed") {
                      item.info.completed_count = item.info.completed_count + 1;
                      item.info.total_count = item.info.total_count + 1;
                    }
                    if (item.type == "open") {
                      item.info.total_count = item.info.total_count - 1;
                    }
                  }
                  this.databaseService.addTaskStats(taskStats).subscribe(done => {
                    this.getTasksStats();
                  })
                }
                if (newTask.status.name.toLowerCase() == "in progress" && store === "CompletedTaskStore") {
                  this.databaseService.deleteBulkTasks(oldTask.id, store);
                  this.databaseService.addBulkTasks([newTask], "OpenTaskStore");
                  for (let item of taskStats) {
                    if (item.type == "completed") {
                      item.info.completed_count = item.info.completed_count - 1;
                      item.info.total_count = item.info.total_count - 1;
                    }
                    if (item.type == "open") {
                      item.info.total_count = item.info.total_count + 1;
                    }
                  }
                  this.databaseService.addTaskStats(taskStats).subscribe(done => {
                    this.getTasksStats();
                  })
                }
              }
            })
          }
        });
      }
    })
  }

  refreshUpdateTask(id):Promise<any> {
    return new Promise((resolve, reject) => {
      this.taskService.getTaskDetails(id).subscribe(res => {
        resolve(res);
      }, err => {
        resolve("");
      });
    })
  }

  startCable() {
    console.log("[task.repo] startCable");
  }
}
