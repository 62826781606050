
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="task-filter">
  <div *ngIf="screen !== 'desktop' && selectedFilterType" id="task-filter-option-label-mobile" [matMenuTriggerFor]="getMenuTrigger()" class="filter-option-label disable-select">
    <span class="text-label">{{selectedFilterKey | translate}}</span>
    <svg width="24px" height="25px" viewBox="0 0 24 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Icon/product/keyboard-arrow-down-new</title>
      <g id="Icon/product/keyboard-arrow-down-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path d="M7.25735931,9.79289322 C7.6478836,9.40236893 8.28104858,9.40236893 8.67157288,9.79289322 L12.5,13.6213203 L16.3284271,9.79289322 C16.7189514,9.40236893 17.3521164,9.40236893 17.7426407,9.79289322 C18.133165,10.1834175 18.133165,10.8165825 17.7426407,11.2071068 L12.5,16.4497475 L7.25735931,11.2071068 C6.86683502,10.8165825 6.86683502,10.1834175 7.25735931,9.79289322 Z" id="Path" fill="#000000"></path>
      </g>
  </svg>

  </div>
  <div *ngIf="screen === 'desktop'" id="task-filter-option-label" class="filter-option-label disable-select">
    <span *ngIf="selectedFilterType === taskConstants.ROUTE_TYPE_STATUS">{{ 'TASKS' | translate }}: </span>
    <span *ngIf="selectedFilterType === taskConstants.ROUTE_TYPE_TAG">{{ 'TAGS' | translate }}: </span>
    <span *ngIf="selectedFilterType === taskConstants.ROUTE_TYPE_LIST">{{ 'LISTS' | translate }}: </span>
    <span *ngIf="selectedFilterType === taskConstants.ROUTE_TYPE_LOCATION">{{ 'LOCATIONS' | translate }}: </span>
    <span class="text-label">{{selectedFilterKey | translate}}</span>
    <span>{{ "(" + total_count + ")" }}</span>
  </div>
  <!-- <div *ngIf="screen === 'desktop'" class="filter-task-div">
    <label *ngIf="selectedFilterOption == taskConstants.ROUTE_ALL_TASK ||
    selectedFilterOption == taskConstants.ROUTE_TODAY_DUE ||
    selectedFilterOption == taskConstants.ROUTE_ASSIGNEDTOME ||
    selectedFilterOption == taskConstants.ROUTE_CREATEDBYME ||
    selectedFilterOption == taskConstants.ROUTE_TOMORROW_DUE ||
    selectedFilterOption == taskConstants.ROUTE_TASKS_I_WATCH ||
    selectedFilterOption == taskConstants.ROUTE_THIS_WEEK_DUE ||
    selectedFilterType === taskConstants.ROUTE_TYPE_LIST ||
    selectedFilterType === taskConstants.ROUTE_TYPE_TAG ||
    selectedFilterType === taskConstants.ROUTE_TYPE_LOCATION" class="mdl-checkbox open-tasks-checkbox" for="checkbox-2">
      <mdl-checkbox id="checkbox-2" [(ngModel)]="openTask" (click)="onOpenTaskCheck(openTask)" [disabled]="total_count === 0 || closeTask">{{ 'OPEN_TASKS' | translate }}</mdl-checkbox>
    </label>
    <label *ngIf="selectedFilterOption == taskConstants.ROUTE_ALL_TASK ||
    selectedFilterOption == taskConstants.ROUTE_TODAY_DUE ||
    selectedFilterOption == taskConstants.ROUTE_ASSIGNEDTOME ||
    selectedFilterOption == taskConstants.ROUTE_CREATEDBYME ||
    selectedFilterOption == taskConstants.ROUTE_TOMORROW_DUE ||
    selectedFilterOption == taskConstants.ROUTE_TASKS_I_WATCH ||
    selectedFilterOption == taskConstants.ROUTE_THIS_WEEK_DUE ||
    selectedFilterType === taskConstants.ROUTE_TYPE_LIST ||
    selectedFilterType === taskConstants.ROUTE_TYPE_TAG ||
    selectedFilterType === taskConstants.ROUTE_TYPE_LOCATION" class="mdl-checkbox open-tasks-checkbox my-tasks-checkbox" for="checkbox-2">
      <mdl-checkbox id="checkbox-2" [(ngModel)]="closeTask" (click)="onCloseTaskCheck(closeTask)" [disabled]="total_count === 0 || openTask">{{ 'CLOSE_TASKS' | translate }}</mdl-checkbox>
    </label>
    <label *ngIf="selectedFilterType === taskConstants.ROUTE_TYPE_TAG || selectedFilterOption == taskConstants.ROUTE_TASKS_I_WATCH" class="mdl-checkbox open-tasks-checkbox my-tasks-checkbox" for="checkbox-2">
      <mdl-checkbox id="checkbox-2" [(ngModel)]="showMyTask" (click)="onShowMyTaskCheck(showMyTask)" [disabled]="total_count===0">{{ 'MY_TASKS' | translate }}</mdl-checkbox>
    </label>
  </div>
  <div *ngIf="screen === 'desktop'" class="filter-sort-div" [class.align-popover]="!isLangEnglish">
    <span class="sort-by">
      {{ 'SORT_BY' | translate }}
    </span>
    <mdl-select id="task-filter-option-select" class="disable-select mdl-selection-option" [(ngModel)]="selectedSortValue" (change)="onSelectSortItem()" [disabled]="total_count===0 ">
        <mdl-option *ngFor="let sortOption of sortingOption" [value]="sortOption.value">{{sortOption.viewValue}}</mdl-option>
    </mdl-select>
  </div> -->
  <div class="filter-sort-div-mobile">
    <span [matMenuTriggerFor]="viewMoreOptions" #menuTrigger="matMenuTrigger">{{ 'ORDER' | translate }}/{{ 'FILTER' | translate }}</span>
    <mat-icon [matMenuTriggerFor]="viewMoreOptions" #menuTrigger="matMenuTrigger">arrow_drop_down</mat-icon>
  </div>
</div>
<mat-menu #statusMenu="matMenu" [overlapTrigger]="'false'" class="task-filter-menu">
  <button mat-menu-item (click)="navigateRoute(taskConstants.ROUTE_ALL_TASK)">
    <mat-icon>event_available</mat-icon>
    <span>{{ taskConstants.ALL_TASK | translate }}</span>
    <div class="rounded-rectangle">
      <span>{{ getTotalCountForStatus(taskConstants.ROUTE_ALL_TASK) }}</span>
    </div>
  </button>
  <button mat-menu-item (click)="navigateRoute(taskConstants.ROUTE_NEW)">
    <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Icon/product/panorama-fish-eye</title>
      <g id="Icon/product/panorama-fish-eye" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Icon-24px" transform="translate(2.000000, 2.000000)" fill="#8B96A0">
              <path d="M10,0 C4.47,0 0,4.47 0,10 C0,15.53 4.47,20 10,20 C15.53,20 20,15.53 20,10 C20,4.47 15.53,0 10,0 L10,0 Z M10,18 C5.59,18 2,14.41 2,10 C2,5.59 5.59,2 10,2 C14.41,2 18,5.59 18,10 C18,14.41 14.41,18 10,18 L10,18 Z" id="Shape"></path>
          </g>
      </g>
    </svg>
    <span>{{ taskConstants.NEW_TASK | translate }}</span>
    <div class="rounded-rectangle">
      <span>{{ getTotalCountForStatus(taskConstants.ROUTE_NEW) }}</span>
    </div>
  </button>
  <button mat-menu-item (click)="navigateRoute(taskConstants.ROUTE_OPEN)">
    <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Icon/product/timelapse</title>
      <g id="Icon/product/timelapse" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Icon-24px" transform="translate(2.000000, 2.000000)" fill="#8B96A0">
              <path d="M14.24,5.76 C13.07,4.59 11.54,4 10,4 L10,10 L5.76,14.24 C8.1,16.58 11.9,16.58 14.25,14.24 C16.59,11.9 16.59,8.1 14.24,5.76 L14.24,5.76 Z M10,0 C4.48,0 0,4.48 0,10 C0,15.52 4.48,20 10,20 C15.52,20 20,15.52 20,10 C20,4.48 15.52,0 10,0 L10,0 Z M10,18 C5.58,18 2,14.42 2,10 C2,5.58 5.58,2 10,2 C14.42,2 18,5.58 18,10 C18,14.42 14.42,18 10,18 L10,18 Z" id="Shape"></path>
          </g>
      </g>
    </svg>
    <span>{{ taskConstants.OPEN | translate }}</span>
    <div class="rounded-rectangle">
      <span>{{ getTotalCountForStatus(taskConstants.ROUTE_OPEN) }}</span>
    </div>
  </button>
  <button mat-menu-item (click)="navigateRoute(taskConstants.ROUTE_COMPLETED)">
    <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Icon/product/check-circle</title>
      <g id="Icon/product/check-circle" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Icon-24px" transform="translate(2.000000, 2.000000)" fill="#8B96A0">
              <path d="M10,0 C4.48,0 0,4.48 0,10 C0,15.52 4.48,20 10,20 C15.52,20 20,15.52 20,10 C20,4.48 15.52,0 10,0 L10,0 Z M8,15 L3,10 L4.41,8.59 L8,12.17 L15.59,4.58 L17,6 L8,15 L8,15 Z" id="Shape"></path>
          </g>
      </g>
    </svg>
    <span>{{ taskConstants.COMPLETED | translate }}</span>
    <div class="rounded-rectangle">
      <span>{{ getTotalCountForStatus(taskConstants.ROUTE_COMPLETED) }}</span>
    </div>
  </button>
  <button mat-menu-item (click)="navigateRoute(taskConstants.ROUTE_ASSIGNEDTOME)">
    <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Icon/product/assignment-ind</title>
      <g id="Icon/product/assignment-ind" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Icon-24px" transform="translate(3.000000, 1.000000)" fill="#8B96A0">
              <path d="M16,2 L11.82,2 C11.4,0.84 10.3,0 9,0 C7.7,0 6.6,0.84 6.18,2 L2,2 C0.9,2 0,2.9 0,4 L0,18 C0,19.1 0.9,20 2,20 L16,20 C17.1,20 18,19.1 18,18 L18,4 C18,2.9 17.1,2 16,2 L16,2 Z M9,2 C9.55,2 10,2.45 10,3 C10,3.55 9.55,4 9,4 C8.45,4 8,3.55 8,3 C8,2.45 8.45,2 9,2 L9,2 Z M9,6 C10.66,6 12,7.34 12,9 C12,10.66 10.66,12 9,12 C7.34,12 6,10.66 6,9 C6,7.34 7.34,6 9,6 L9,6 Z M15,18 L3,18 L3,16.6 C3,14.6 7,13.5 9,13.5 C11,13.5 15,14.6 15,16.6 L15,18 L15,18 Z" id="Shape"></path>
          </g>
      </g>
  </svg>
    <span>{{ taskConstants.ASSIGNEDTOME | translate }}</span>
    <div class="rounded-rectangle">
      <span>{{ getTotalCountForStatus(taskConstants.ROUTE_ASSIGNEDTOME) }}</span>
    </div>
  </button>
  <button mat-menu-item (click)="navigateRoute(taskConstants.ROUTE_CREATEDBYME)">
    <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Icon/product/assignment-24px</title>
      <g id="Icon/product/assignment-24px" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Icon-24px" transform="translate(2.000000, 1.000000)" fill="#8B96A0">
              <path d="M16,2 L11.82,2 C11.4,0.84 10.3,0 9,0 C7.7,0 6.6,0.84 6.18,2 L2,2 C0.9,2 0,2.9 0,4 L0,18 C0,19.1 0.9,20 2,20 L16,20 C17.1,20 18,19.1 18,18 L18,4 C18,2.9 17.1,2 16,2 L16,2 Z M9,2 C9.55,2 10,2.45 10,3 C10,3.55 9.55,4 9,4 C8.45,4 8,3.55 8,3 C8,2.45 8.45,2 9,2 L9,2 Z M11,16 L4,16 L4,14 L11,14 L11,16 L11,16 Z M14,12 L4,12 L4,10 L14,10 L14,12 L14,12 Z M14,8 L4,8 L4,6 L14,6 L14,8 L14,8 Z" id="Shape"></path>
          </g>
      </g>
    </svg>
    <span>{{ taskConstants.CREATEDBYME | translate }}</span>
    <div class="rounded-rectangle">
      <span>{{ getTotalCountForStatus(taskConstants.ROUTE_CREATEDBYME) }}</span>
    </div>
  </button>
  <button mat-menu-item (click)="navigateRoute(taskConstants.ROUTE_TODAY_DUE)">
    <mat-icon>access_time</mat-icon>
    <span>{{ taskConstants.TODAY_DUE | translate }}</span>
    <div class="rounded-rectangle">
      <span>{{ getTotalCountForStatus(taskConstants.ROUTE_TODAY_DUE) }}</span>
    </div>
  </button>
  <button mat-menu-item (click)="navigateRoute(taskConstants.ROUTE_TOMORROW_DUE)">
    <mat-icon>watch_later</mat-icon>
    <span>{{ taskConstants.TOMORROW_DUE | translate }}</span>
    <div class="rounded-rectangle">
      <span>{{ getTotalCountForStatus(taskConstants.ROUTE_TOMORROW_DUE) }}</span>
    </div>
  </button>
  <button mat-menu-item (click)="navigateRoute(taskConstants.ROUTE_THIS_WEEK_DUE)">
    <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Icon/product/date-new</title>
      <g id="Icon/product/date-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path d="M15.5,3 C16.3284271,3 17,3.67157288 17,4.5 L17,5 L18,5 C19.5976809,5 20.9036609,6.24891996 20.9949073,7.82372721 L21,8 L21,18 C21,19.5976809 19.75108,20.9036609 18.1762728,20.9949073 L6,21 C4.40231912,21 3.09633912,19.75108 3.00509269,18.1762728 L3,18 L3,8 C3,6.40231912 4.24891996,5.09633912 5.82372721,5.00509269 L7,5.005 L7,4.5 C7,3.67157288 7.67157288,3 8.5,3 C9.32842712,3 10,3.67157288 10,4.5 L10,5.003 L14,5.002 L14,4.5 C14,3.67157288 14.6715729,3 15.5,3 Z M18,7 L6,7 C5.48716416,7 5.06449284,7.38604019 5.00672773,7.88337887 L5,8 L5,18 C5,18.5128358 5.38604019,18.9355072 5.88337887,18.9932723 L6,19 L18,19 C18.5128358,19 18.9355072,18.6139598 18.9932723,18.1166211 L19,18 L19,8 C19,7.44771525 18.5522847,7 18,7 Z M16.9804916,14 C17.5370911,14 17.9883041,14.451213 17.9883041,15.0078125 C17.9883041,15.564412 17.5370911,16.015625 16.9804916,16.015625 L7.01950841,16.015625 C6.46290893,16.015625 6.01169591,15.564412 6.01169591,15.0078125 C6.01169591,14.451213 6.46290893,14 7.01950841,14 L16.9804916,14 Z M7.5,9 C8.32842712,9 9,9.67157288 9,10.5 C9,11.3284271 8.32842712,12 7.5,12 C6.67157288,12 6,11.3284271 6,10.5 C6,9.67157288 6.67157288,9 7.5,9 Z" id="Combined-Shape" fill="#8B96A0"></path>
      </g>
    </svg>
    <span>{{ taskConstants.THIS_WEEK_DUE | translate }}</span>
    <div class="rounded-rectangle">
      <span>{{ getTotalCountForStatus(taskConstants.ROUTE_THIS_WEEK_DUE) }}</span>
    </div>
  </button>
  <button mat-menu-item (click)="navigateRoute(taskConstants.ROUTE_TASKS_I_WATCH)">
    <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Icon/product/date-new</title>
      <g id="Icon/product/date-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path d="M15.5,3 C16.3284271,3 17,3.67157288 17,4.5 L17,5 L18,5 C19.5976809,5 20.9036609,6.24891996 20.9949073,7.82372721 L21,8 L21,18 C21,19.5976809 19.75108,20.9036609 18.1762728,20.9949073 L6,21 C4.40231912,21 3.09633912,19.75108 3.00509269,18.1762728 L3,18 L3,8 C3,6.40231912 4.24891996,5.09633912 5.82372721,5.00509269 L7,5.005 L7,4.5 C7,3.67157288 7.67157288,3 8.5,3 C9.32842712,3 10,3.67157288 10,4.5 L10,5.003 L14,5.002 L14,4.5 C14,3.67157288 14.6715729,3 15.5,3 Z M18,7 L6,7 C5.48716416,7 5.06449284,7.38604019 5.00672773,7.88337887 L5,8 L5,18 C5,18.5128358 5.38604019,18.9355072 5.88337887,18.9932723 L6,19 L18,19 C18.5128358,19 18.9355072,18.6139598 18.9932723,18.1166211 L19,18 L19,8 C19,7.44771525 18.5522847,7 18,7 Z M16.9804916,14 C17.5370911,14 17.9883041,14.451213 17.9883041,15.0078125 C17.9883041,15.564412 17.5370911,16.015625 16.9804916,16.015625 L7.01950841,16.015625 C6.46290893,16.015625 6.01169591,15.564412 6.01169591,15.0078125 C6.01169591,14.451213 6.46290893,14 7.01950841,14 L16.9804916,14 Z M7.5,9 C8.32842712,9 9,9.67157288 9,10.5 C9,11.3284271 8.32842712,12 7.5,12 C6.67157288,12 6,11.3284271 6,10.5 C6,9.67157288 6.67157288,9 7.5,9 Z" id="Combined-Shape" fill="#8B96A0"></path>
      </g>
    </svg>
    <span>{{ taskConstants.TASKS_I_WATCH | translate }}</span>
    <div class="rounded-rectangle">
      <span>{{ getTotalCountForStatus(taskConstants.ROUTE_TASKS_I_WATCH) }}</span>
    </div>
  </button>
  <button mat-menu-item (click)="navigateRoute(taskConstants.ROUTE_MY_OVERDUE_TASKS)">
    <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Icon/product/assignment-late</title>
      <g id="Icon/product/assignment-late" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Icon-24px" transform="translate(3.000000, 1.000000)" fill="#8B96A0">
              <path d="M16,2 L11.82,2 C11.4,0.84 10.3,0 9,0 C7.7,0 6.6,0.84 6.18,2 L2,2 C0.9,2 0,2.9 0,4 L0,18 C0,19.1 0.9,20 2,20 L16,20 C17.1,20 18,19.1 18,18 L18,4 C18,2.9 17.1,2 16,2 L16,2 Z M10,17 L8,17 L8,15 L10,15 L10,17 L10,17 Z M10,13 L8,13 L8,7 L10,7 L10,13 L10,13 Z M9,4 C8.45,4 8,3.55 8,3 C8,2.45 8.45,2 9,2 C9.55,2 10,2.45 10,3 C10,3.55 9.55,4 9,4 L9,4 Z" id="Shape"></path>
          </g>
      </g>
    </svg>
    <span>{{ taskConstants.MY_OVERDUE_TASKS | translate }}</span>
    <div class="rounded-rectangle">
      <span>{{ getTotalCountForStatus(taskConstants.ROUTE_MY_OVERDUE_TASKS) }}</span>
    </div>
  </button>
</mat-menu>
<mat-menu #tagMenu="matMenu" [overlapTrigger]="'false'" class="task-filter-menu">
  <button mat-menu-item *ngFor="let tag of tagList" (click)="navigateRoute(tag.name)">
    <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Icon/product/tag-new</title>
      <g id="Icon/product/tag-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path d="M11,2 C11.5,2 11.9259259,2.14814815 12.2777778,2.44444444 L12.40625,2.5625 L21.40625,11.5625 C21.78125,11.9375 21.96875,12.40625 21.96875,12.96875 C21.96875,13.46875 21.8206019,13.8946759 21.5243056,14.2465278 L21.40625,14.375 L14.375,21.40625 C14,21.78125 13.53125,21.96875 12.96875,21.96875 C12.46875,21.96875 12.0428241,21.8206019 11.6909722,21.5243056 L11.5625,21.40625 L2.5625,12.40625 C2.22916667,12.0729167 2.04398148,11.6655093 2.00694444,11.1840278 L2,11 L2,3.96875 C2,3.40625 2.1875,2.9375 2.5625,2.5625 C2.89583333,2.22916667 3.30324074,2.04398148 3.78472222,2.00694444 L3.96875,2 L11,2 Z M11.013,3.997 L11,4 L4,4 L4,11 L3.997,11.013 L12.954,19.97 L12.96875,19.96875 L12.982,19.97 L19.97,12.982 L19.96875,12.96875 L19.97,12.954 L11.013,3.997 Z M6.4921875,5.015625 C6.9140625,5.015625 7.265625,5.15625 7.546875,5.4375 C7.828125,5.71875 7.96875,6.0703125 7.96875,6.4921875 C7.96875,6.9140625 7.828125,7.265625 7.546875,7.546875 C7.265625,7.828125 6.90625,7.96875 6.46875,7.96875 C6.0625,7.96875 5.71875,7.828125 5.4375,7.546875 C5.15625,7.265625 5.015625,6.9140625 5.015625,6.4921875 C5.015625,6.0703125 5.15625,5.71875 5.4375,5.4375 C5.71875,5.15625 6.0703125,5.015625 6.4921875,5.015625 Z" id="Combined-Shape" fill="#8B96A0"></path>
      </g>
  </svg>
    <span>{{ tag.name }}</span>
    <div class="rounded-rectangle">
      <span>{{ tag.count }}</span>
    </div>
  </button>
</mat-menu>
<mat-menu #listMenu="matMenu" [overlapTrigger]="'false'" class="task-filter-menu">
  <button mat-menu-item *ngFor="let list of folderList" (click)="navigateRoute(list.name)">
    <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Icon/product/assignment-24px</title>
      <g id="Icon/product/assignment-24px" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Icon-24px" transform="translate(2.000000, 1.000000)" fill="#8B96A0">
              <path d="M16,2 L11.82,2 C11.4,0.84 10.3,0 9,0 C7.7,0 6.6,0.84 6.18,2 L2,2 C0.9,2 0,2.9 0,4 L0,18 C0,19.1 0.9,20 2,20 L16,20 C17.1,20 18,19.1 18,18 L18,4 C18,2.9 17.1,2 16,2 L16,2 Z M9,2 C9.55,2 10,2.45 10,3 C10,3.55 9.55,4 9,4 C8.45,4 8,3.55 8,3 C8,2.45 8.45,2 9,2 L9,2 Z M11,16 L4,16 L4,14 L11,14 L11,16 L11,16 Z M14,12 L4,12 L4,10 L14,10 L14,12 L14,12 Z M14,8 L4,8 L4,6 L14,6 L14,8 L14,8 Z" id="Shape"></path>
          </g>
      </g>
  </svg>
    <span>{{ list.name }}</span>
    <div class="rounded-rectangle">
      <span>{{ list.tasks_count }}</span>
    </div>
  </button>
</mat-menu>
<mat-menu #locationMenu="matMenu" [overlapTrigger]="'false'" class="task-filter-menu">
  <button mat-menu-item *ngFor="let location of locationList" (click)="navigateRoute(location.name)">
    <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Icon/product/assignment-24px</title>
      <g id="Icon/product/assignment-24px" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Icon-24px" transform="translate(2.000000, 1.000000)" fill="#8B96A0">
              <path d="M16,2 L11.82,2 C11.4,0.84 10.3,0 9,0 C7.7,0 6.6,0.84 6.18,2 L2,2 C0.9,2 0,2.9 0,4 L0,18 C0,19.1 0.9,20 2,20 L16,20 C17.1,20 18,19.1 18,18 L18,4 C18,2.9 17.1,2 16,2 L16,2 Z M9,2 C9.55,2 10,2.45 10,3 C10,3.55 9.55,4 9,4 C8.45,4 8,3.55 8,3 C8,2.45 8.45,2 9,2 L9,2 Z M11,16 L4,16 L4,14 L11,14 L11,16 L11,16 Z M14,12 L4,12 L4,10 L14,10 L14,12 L14,12 Z M14,8 L4,8 L4,6 L14,6 L14,8 L14,8 Z" id="Shape"></path>
          </g>
      </g>
  </svg>
    <span>{{ location.name }}</span>
    <div class="rounded-rectangle">
      <span>{{ location.tasks_count }}</span>
    </div>
  </button>
</mat-menu>
<mat-menu #viewMoreOptions="matMenu" class="viewMoreOptions" xPosition="before">
  <div class="title">{{ 'ORDER_BY' | translate }}</div>
  <button mat-menu-item (click)="setSortBy(taskConstants.DUE_DATE_SORTING_KEY)" [ngClass]="{'selected': selectedSortValue === taskConstants.DUE_DATE_SORTING_KEY }" >
    <span>{{ 'DUE_DATE' | translate }}</span>
    <mat-icon *ngIf="selectedSortValue === taskConstants.DUE_DATE_SORTING_KEY" class="active-view">check</mat-icon>
  </button>
  <button mat-menu-item (click)="setSortBy(taskConstants.PRIORITY_SORTING_KEY)" [ngClass]="{'selected': selectedSortValue === taskConstants.PRIORITY_SORTING_KEY }">
    <span>{{ 'PRIORITY' | translate }}</span>
    <mat-icon *ngIf="selectedSortValue === taskConstants.PRIORITY_SORTING_KEY" class="active-view">check</mat-icon>
  </button>
  <button mat-menu-item (click)="setSortBy(taskConstants.TASK_NAME_SORTING_KEY)" [ngClass]="{'selected': selectedSortValue === taskConstants.TASK_NAME_SORTING_KEY }">
    <span>{{ 'TASK_NAME' | translate }}</span>
    <mat-icon *ngIf="selectedSortValue === taskConstants.TASK_NAME_SORTING_KEY" class="active-view">check</mat-icon>
  </button>
  <button mat-menu-item (click)="setSortBy(taskConstants.START_DATE_SORTING_KEY)" [ngClass]="{'selected': selectedSortValue === taskConstants.START_DATE_SORTING_KEY }">
    <span>{{ 'START_DATE' | translate }}</span>
    <mat-icon *ngIf="selectedSortValue === taskConstants.START_DATE_SORTING_KEY" class="active-view">check</mat-icon>
  </button>
  <button mat-menu-item (click)="setSortBy(taskConstants.STATUS_SORTING_KEY)" [ngClass]="{'selected': selectedSortValue === taskConstants.STATUS_SORTING_KEY }">
    <span>{{ 'STATUS' | translate }}</span>
    <mat-icon *ngIf="selectedSortValue === taskConstants.STATUS_SORTING_KEY" class="active-view">check</mat-icon>
  </button>
  <button mat-menu-item (click)="setSortBy(taskConstants.CREATED_SORTING_KEY)" [ngClass]="{'selected': selectedSortValue === taskConstants.CREATED_SORTING_KEY }">
    <span>{{ 'CREATED' | translate }}</span>
    <mat-icon *ngIf="selectedSortValue === taskConstants.CREATED_SORTING_KEY" class="active-view">check</mat-icon>
  </button>
  <mat-divider></mat-divider>
  <div class="title">{{ 'FILTER' | translate }}</div>
  <button mat-menu-item (click)="setOpenTaskBy(openTask)" [ngClass]="{'selected': openTask === true }" [disabled]="(total_count === 0) || closeTask === true || (selectedFilterOption === taskConstants.ROUTE_NEW || selectedFilterOption === taskConstants.ROUTE_OPEN || selectedFilterOption === taskConstants.ROUTE_COMPLETED || selectedFilterOption === taskConstants.ROUTE_MY_OVERDUE_TASKS )">
      <span>{{ 'OPEN_TASKS' | translate }}</span>
      <mat-icon *ngIf="( openTask === true && ((total_count !== 0) && (selectedFilterOption !== taskConstants.ROUTE_NEW && selectedFilterOption !== taskConstants.ROUTE_OPEN && selectedFilterOption !== taskConstants.ROUTE_COMPLETED && selectedFilterOption !== taskConstants.ROUTE_MY_OVERDUE_TASKS)))" class="active-view">check</mat-icon>
  </button>
  <button mat-menu-item (click)="setCloseTaskBy(closeTask)" [ngClass]="{'selected': closeTask === true }" [disabled]="(total_count === 0) || openTask === true || (selectedFilterOption === taskConstants.ROUTE_NEW || selectedFilterOption === taskConstants.ROUTE_OPEN || selectedFilterOption === taskConstants.ROUTE_COMPLETED || selectedFilterOption === taskConstants.ROUTE_MY_OVERDUE_TASKS )">
    <span>{{ 'CLOSE_TASKS' | translate }}</span>
    <mat-icon *ngIf="( closeTask === true && ((total_count !== 0) && (selectedFilterOption !== taskConstants.ROUTE_NEW && selectedFilterOption !== taskConstants.ROUTE_OPEN && selectedFilterOption !== taskConstants.ROUTE_COMPLETED && selectedFilterOption !== taskConstants.ROUTE_MY_OVERDUE_TASKS)))" class="active-view">check</mat-icon>
  </button>
  <button mat-menu-item (click)="setMyTaskBy(showMyTask)" [ngClass]="{'selected': showMyTask === true }" [disabled]="(total_count === 0) || ( selectedFilterType !== taskConstants.ROUTE_TYPE_TAG && selectedFilterOption !== taskConstants.ROUTE_TASKS_I_WATCH)">
    <span>{{ 'MY_TASKS' | translate }}</span>
    <mat-icon *ngIf="( showMyTask === true && ((total_count !== 0) && ( selectedFilterType === taskConstants.ROUTE_TYPE_TAG || selectedFilterOption === taskConstants.ROUTE_TASKS_I_WATCH)))" class="active-view">check</mat-icon>
  </button>
</mat-menu>